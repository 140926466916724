<template>
  <div
    :class="containerClass"
  >
    <div class="mt-5 mb-2 text-center">
      <div v-show="!otpWasSent">
        <img
          class="responsiveImg"
          :src="imgURL"
          fluid
          alt="jpplbg"
        >
        <br>
        <div class="text-dark text-center mt-4 mb-4">
          <h6>
            We will send you an
            <strong>One Time Password</strong>
            on this mobile number.
          </h6>
        </div>
      </div>
      <div
        v-show="otpWasSent"
        class="mt-5 mb-5"
      >
        <div class="mt-5 mb-5">
          <b-icon
            icon="clock-fill"
            variant="primary"
            animation="throb"
            font-scale="3"
          />
        </div>
        <h5>Enter 4 digit verification code sent to your phone number</h5>
      </div>
      <b-form @submit="attemptLogin">
        <b-form-group
          id="ig-phone"
          label-for="phone"
        >
          <b-input-group
            size="md"
            prepend="+91"
          >
            <b-form-input
              id="phone"
              v-model="login.phone"
              type="number"
              placeholder="Enter 10 digit phone number"
              maxlength="10"
              minlength="10"
              required
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          v-show="otpWasSent"
          id="ig-otp"
          label-for="otp"
        >
          <b-form-input
            id="otp"
            v-model="login.otp"
            type="tel"
            placeholder="Enter otp"
            maxlength="4"
            minlength="4"
            :required="otpWasSent"
          />
        </b-form-group>
        <b-button
          block
          type="submit"
          variant="primary"
          testid-otp-action-button
        >
          {{ actionText }}
          <b-icon :icon="buttonIcon" />
        </b-button>
      </b-form>
      <div
        v-show="otpWasSent"
        class="mt-5"
      >
        <p v-show="countDown !== 0 && countDown > 0">
          Resend OTP in <span
            class="text-primary"
          >
            00:{{ countDown.toString().padStart(2,'0') }}
          </span>
        </p>
        <b-button
          v-show="countDown <= 0"
          :disabled="resendButtonDisabled"
          type="button"
          @click="resendOtp"
        >
          Resend now
        </b-button>
      </div>
      <div
        v-show="!otpWasSent"
        class="text-secondary text-center mt-2 mb-2"
      >
        <p>
          <small>
            By continuing, I agree to the
            <b-link
              :href="terms"
              target="_blank"
              class="text-primary"
            >terms and conditions.</b-link>
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/libs/axiosSetup'
import layoutSwitcher from '@/components/layoutSwitcher'
export default {
  mixins: [layoutSwitcher],
  data() {
    return {
      loginRequired: true,
      login: { phone: '', otp: ''},
      otpWasSent: false,
      imgURL:"https://smaacbucketpublic.s3.ap-south-1.amazonaws.com/scanbg.png",
      resendButtonDisabled : false,
      countDown:30,
      terms:"https://smaacbucketpublic.s3.ap-south-1.amazonaws.com/TnCJPPL.pdf"
    }
  },
  computed: {
    pageTitle: function(){
      let pk = new URL(window.location).searchParams.get('pk');
      if(pk === undefined || pk === null){ return 'Login' }
      return 'Login to claim rewards!'
    },
    actionText: function(){
      if(this.otpWasSent){ return 'Verify OTP' }
      return 'Get OTP'
    },
    buttonIcon: function(){
      if(this.otpWasSent){ return 'check2-all' }
      return 'arrow-right-circle-fill'
    },
    containerClass: function (){
      if(this.isMobile){ return 'container'}
      return 'container w-50'
    }
  },
  methods: {
    store: function(key, val){
      this.$store.commit('setUserInfo', {key: key, val: val})
    },
    resendOtp: function (){
      delete this.login['otp']
      this.countDown = 30
      this.attemptLogin()
    },
    attemptLogin: function (event) {
      if(event !== undefined){ event.preventDefault() }
      var el = this;
      el.$root.$emit('nav-spinner-on');
      el.resendButtonDisabled = true
      axios.post('/main/otplogin', el.login)
        .then(function (response){
          if(response.data.ok === true){
            el.loginRequired = false;
            el.store('loginRequired', 'false')
            el.store('apitoken', response.data.tok)
            el.store('singleModule', "false")
            let keys = [
              'id',
              'userName',
              'phone',
              'erpType',
              'email',
              'company',
              'isTestDb'
            ]
            for(var i=0; i < keys.length; ++i){
              el.store(keys[i], response.data[keys[i]])
            }
            if(response.data.flags){
              el.$store.commit( 'setFlags', response.data.flags)
            }
            let guid = new URL(window.location).searchParams.get('guid');
            let url = '/'
            if(guid !== null){ url += '?guid=' + guid }
            window.location.href = url
          } else if (response.data.otpSent === true) {
            el.otpWasSent = true
            el.countDownTimer()
          } else if (response.data.code === 429) {
            el.$root.$emit('alert', {
              msg: 'Please wait for 10 seconds for another try.',
              kind: 'danger'
            })
          } else {
            el.$root.$emit('alert', {
              msg: 'Login failed. Please re-check your otp.',
              kind: 'danger'
            })
          }
        }).catch(()=>{})
        .finally(function (){
          el.$root.$emit('nav-spinner-off')
          el.resendButtonDisabled = false
        })
    },
    countDownTimer:function() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    }
  }
}
</script>
<style scoped>
.responsiveImg {
  max-width: 100%;
  height: auto;
}
</style>
