<template>
  <b-container class="text-center">
    <MobileModal
      title="Account details"
      :show-modal="showModal"
      form-button-kind="save"
      :form-button-disabled="formButtonDisabled"
      :delete-enabled="false"
      :cancel-enabled="false"
      @click-formButton="formButtonClicked"
      @click-cancel="showModal = false"
    >
      <div
        v-for="fieldName in userModalFields"
        :key="fieldName"
      >
        <Field
          :key="fieldName + '-' +
            (fieldsAffectedByItemData[fieldName].key || 0)"
          :field-key="fieldName"
          :tag="fieldsAffectedByItemData[fieldName].tag"
          :label="fieldsAffectedByItemData[fieldName].label"
          :props="fieldsAffectedByItemData[fieldName].props || {}"
          :disabled="fieldsAffectedByItemData[fieldName].disabled"
          :group-props="fieldsAffectedByItemData[fieldName].groupProps || {}"
          :value="itemData[fieldName]"
          @change="valueChange($event, fieldName)"
          @cascade="fieldsCascade"
        />
      </div>
    </MobileModal>
    <b-button
      v-show="userDetailsAreAvailable && preScanAvailable"
      variant="primary"
      @click="$router.push({ name: 'rewardsscanner' })"
    >
      Claim rewards
    </b-button>
    <hr>
    <b-button
      :variant="userDetailsAreAvailable === false ? 'primary' : 'secondary'"
      @click="showModal = true, fieldsSetProps(populateProps())"
    >
      {{ showModalButtonMessage }}
    </b-button>
    <hr>
    <h4>
      User Code: <span style="color: blue; font-weight: bold;">
        {{ itemData["userCode"] }}</span>
    </h4>
    <table class="table w-100 table-sm table-striped">
      <tbody>
        <tr
          v-for="fieldName in displayUserDetailFields"
          :key="fieldName"
        >
          <td class="bg-dark text-white">
            <strong>{{ fields[fieldName].label }}</strong>
          </td>
          <td>{{ itemData[fieldName] }}</td>
        </tr>
      </tbody>
    </table>
  </b-container>
</template>
<script>
import axios from '@/libs/axiosSetup'
import MobileModal from '@/components/forms/mobileModal.vue'
import formMixin from "@/components/forms/mixin"
import Field from '@/components/forms/fields'

export default {
  components: { MobileModal, Field },
  mixins: [formMixin],
  data() {
    return {
      showModal: (
        this.$store.state.userName === ''
        || this.$store.state.userName === null
      ),
      fields: {
        'id': { label: '', tag: 'b-form-input', props: { 'type': 'hidden' } },
        'webapp_user_id': {
          label: '',
          tag: 'b-form-input',
          props: { 'type': 'hidden' }
        },
        'PhoneNo': {
          label: 'Phone number',
          tag: 'b-form-input', props: { 'disabled': true }
        },
        'Username': { label: 'Name', tag: 'b-form-input', props: {} },
        'accountType_id': { label: 'Account type', tag: 'dropdown', props: {} },
        'States_id': { label: 'State', tag: 'dropdown', props: {} },
        'accountType_label': {
          label: 'Account type',
          tag: 'b-form-input', props: {}
        },
        'States_label': { label: 'State', tag: 'b-form-input', props: {} },
        'City': { label: 'City', tag: 'b-form-input', props: {} },
        'Email': { label: 'Email', tag: 'b-form-input', props: {} },
        'CompanyName': {
          label: 'Company Name',
          tag: 'b-form-input', props: {}
        },
        'GSTNo': { label: 'GST No.', tag: 'b-form-input', props: {} },
        registeredThrough: {
          label: 'Referal Code',
          tag: 'b-form-input', props: {}
        },
        'registered_under': {
          label: 'No. of users registered by you',
          tag: 'b-form-input', props: {}
        },
        userCode: {
          label: 'User Code',
          tag: 'b-form-input', props: {}
        }
      }
    }
  },
  computed: {
    userModalFields: function () {
      return ['PhoneNo', 'Username',
        'Email', 'accountType_id', 'City',
        'States_id', 'CompanyName', 'GSTNo', 'registeredThrough']
    },
    displayUserDetailFields: function () {
      return ['userCode', 'PhoneNo', 'Username',
        'Email', 'accountType_label', 'City',
        'States_label', 'CompanyName', 'GSTNo', 'registeredThrough',
        'registered_under']
    },
    name: function () { return this.$router.currentRoute.name },
    slug: function () { return 'rewardsdashboard' },
    preScanAvailable: function () {
      let guid = (new URL(window.location)).searchParams.get('guid')
      if (guid !== undefined && guid !== null) {
        guid = guid.replace('/', '')
      }
      return guid !== undefined && guid !== null
    },
    userDetailsAreAvailable: function () {
      return (this.$store.state.userName !== '')
    },
    showModalButtonMessage: function () {
      if (this.userDetailsAreAvailable === false) {
        return 'Add account details to claim rewards.'
      }
      return 'Edit account details'
    },
    showviewButtons: function () {
      let buttons = [
        { label: 'Profile', icon: 'person', key: 'rewardsprofile' },
        { label: 'Scan', icon: 'upc-scan', key: 'rewardsscanner' },
        { label: 'Redeem', icon: 'wallet', key: 'rewardsredeem' }
      ]
      for (var i = 0; i < buttons.length; ++i) {
        buttons[i]['pressed'] = this.name === buttons[i].key
      }
      return buttons
    },
    fieldsAffectedByItemData: function () {
      const el = this;
      let fields = { ...el.fields };
      // Company name
      let compname = fields["CompanyName"].props
      // GST No
      let Gstno = fields["GSTNo"].props
      // registeredThrough
      let registeredThrough = fields["registeredThrough"].props
      // state
      let state = fields["States_id"].props
      // city
      let city = fields["City"].props
      if (el.itemData.accountType_id === 2) {
        fields["registeredThrough"].props =
          { ...registeredThrough, required: true }
      }
      else {
        fields["registeredThrough"].props =
          { ...registeredThrough, required: false },
        fields["States_id"].props =
          { ...state, required: true },
        fields["City"].props =
          { ...city, required: true }
      }
      if (el.itemData.accountType_label === "Retailer"
        ||
        el.itemData.accountType_label === "Distributor") {
        fields["CompanyName"].props = { ...compname, required: true },
        fields["GSTNo"].props = { ...Gstno, required: true }
      } else {
        fields["CompanyName"].props = { ...compname, required: false },
        fields["GSTNo"].props = { ...Gstno, required: false }
      }
      return fields
    }
  },
  created() {
    this.fetchUserDetails()
  },
  methods: {
    back: function () {
      this.backPrep()
      this.showModal = false
      this.fetchUserDetails()
    },
    fetchUserDetails: function () {
      const el = this;
      let url = el.getOpUrl('list')
      axios.get(url)
        .then(function (response) {
          let resp = response.data.get_list.result[0]
          let keys = Object.keys(resp);
          for (var i = 0; i < keys.length; ++i) {
            el.$set(el.itemData, keys[i], resp[keys[i]])
          }
        }).catch(() => { }).finally(function () {
          el.$root.$emit('nav-spinner-off');
        })
    },
    getOpUrl: function (op) {
      return '/main/s/' + this.slug + '/userdetails_' + op
    },
    formButtonClicked: function () {
      let disable = (
        this.userDetailsAreAvailable === false
        && this.preScanAvailable === false
      )
      this.formButtonClickedPrep()
      if (disable === true) {
        this.$router.push({ name: 'home' })
        this.showModal = false
      }
    },
    postSuccessOnDataForm: function (data) {
      if (data.code === 429) {
        this.$root.$emit('alert', {
          msg: 'You have entered invalid referal code.'
          , kind: 'warning'
        });
      }
    }
  }
}
</script>
