<template>
  <div>
    <strong>{{ title }}</strong>
    <hr>
    <div v-if="enabled !== undefined && enabled.search !== false">
      <b-form-input
        id="searchTerm"
        v-model="form.search"
        type="text"
        placeholder="Search"
      />
    </div>
    <div v-if="enabled !== undefined && enabled.dates">
      <hr>
      <DateFilter
        :start-date="form.startDate"
        :end-date="form.endDate"
        :date-range="form.dateRange"
      />
    </div>
    <div v-if="enabled !== undefined && enabled.stockoptions">
      <hr>
      <StockReportOptionGrid
        :loc-name="locName"
        :filter-main-group="form.mainGroup"
        :filter-group="form.group"
        :filter-category="form.category"
        :filter-item="form.item"
        :filter-godown="form.godown"
      />
    </div>
    <hr>
    <b-button-group
      class="w-100"
      variant="outline-primary"
    >
      <b-button
        class="mr-5 ml-1 actionBtn"
        variant="danger"
        @click="clearForm"
      >
        Clear
      </b-button>
      <b-button
        class="mr-2 ml-5 actionBtn"
        @click="cancelSearch"
      >
        Cancel
      </b-button>
      <b-button
        class="mr-0 actionBtn"
        variant="info"
        @click="triggerSearch"
      >
        Search
      </b-button>
    </b-button-group>
  </div>
</template>
<script>
import StockReportOptionGrid from "./StockReportOptionGrid.vue";
import DateFilter from "./DateFilter.vue";

export default {
  components: { StockReportOptionGrid, DateFilter },
  props: {
    locName: { type: String, default: "" },
    filters: { type: Object, default: () => {} },
    enabled: { type: Object, default: () => {} }
  },
  data: function () {
    // Hack to clone item to make sure that no changes happen to filters
    const f = JSON.parse(JSON.stringify(this.filters || {}));
    return {
      frozenFilters: f,
      form: {
        search: f.search || "",
        dateRange: f.dateRange || null,
        startDate: f.startDate || "",
        endDate: f.endDate || "",
        mainGroup: f.mainGroup || null,
        group: f.group || null,
        category: f.category || null,
        item: f.item || null,
        godown: f.godown || ""
      }
    };
  },
  computed: {
    title: function () {
      return this.$router.app.titleMap[this.locName];
    }
  },
  created() {
    const el = this;
    el.$root.$emit("nav-spinner-off");
    el.$root.$emit("nav-filters-off");
    el.$root.$on("update:dateRange", function (val) {
      el.form.dateRange = val;
    });
    el.$root.$on("update:startDate", function (val) {
      el.form.startDate = val;
    });
    el.$root.$on("update:endDate", function (val) {
      el.form.endDate = val;
    });
    el.$root.$on("update:filterMainGroup", function (val) {
      el.form.mainGroup = val;
    });
    el.$root.$on("update:filterGroup", function (val) {
      el.form.group = val;
    });
    el.$root.$on("update:filterCategory", function (val) {
      el.form.category = val;
    });
    el.$root.$on("update:filterItem", function (val) {
      el.form.item = val;
    });
    el.$root.$on("update:filterGodown", function (val) {
      el.form.godown = val;
    });
    if(this.locName==="" || undefined){
      this.$router.back()}
  },
  methods: {
    cancelSearch: function () {
      this.$router.push({
        name: this.locName,
        params: { filters: this.frozenFilters }
      });
    },
    clearForm: function () {
      this.form = {};
    },
    triggerSearch: function () {
      this.$router.push({ name: this.locName, params: { filters: this.form } });
    }
  }
};
</script>
<style scoped>
.actionBtn {
  max-width: 23%;
}
</style>
