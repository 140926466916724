<script>
import axios from '@/libs/axiosSetup'
import fieldsMixin from '@/components/forms/fields/mixin'
import * as Sentry from "@sentry/vue"
export default {
  mixins: [fieldsMixin],
  data(){
    return {
      showView: 'list',
      formKind: 'add',
      formButtonKind: 'add',
      formButtonDisabled: false,
      itemData: {}
    }
  },
  computed: {
    keyField: function (){ return 'id' },
    titleKey: function (){ return 'slug' },
    formTitle: function (){
      if(this.itemData[this.keyField] !== undefined){
        let ttl = this.itemData[this.titleKey]
        ttl = (ttl === undefined || ttl === null) ? '': ttl
        ttl = ('' + ttl).trim()
        if(ttl !== ''){ return '# ' + ttl}
      }
      return ''
    },
    getURLParams: function(){
      // NOTE: pathAndQuery returns /enquiry?a=10&b=12
      // This is a hack since vue router seems to append query after the
      // fragment, making the query an illegal query.
      // In order to work with the rest of the system we have to reconstruct
      // the url from scratch
      const pathAndQuery = window.location.href.split('#')[1];
      const url = `${this.$siteUrl}${pathAndQuery}`;
      return Object.fromEntries( new URLSearchParams( new URL(url).search))
    }
  },
  created(){
    const el = this;
    // emit events
    el.fieldsSetProps( el.populateProps())
  },
  methods: {
    switchToActiveTab: function(){
      let num = parseInt(this.getURLParams.activeTab);
      if(!Number.isNaN(num)){
        this.activeTab = num
      }
    },
    getModuleProps:function(data){
      data['dummy'] = 1
    },
    postSuccess: function(data){
      data['dummy'] = 1
      // Placeholder to run any code AFTER successful submitForm
    },
    postSuccessOnDataForm: function(data){
      data['dummy'] = 1
      // Placeholder to run any code AFTER successful submitForm on dataform
    },
    configureSlug: function(){
      const el = this
      el.$root.$emit(
        'slugconfig-modal-on',
        el.name,
        function (autogen, place){
          let data = {}
          data.plaintext = autogen === true
          data.placeholder = place
          el.fieldsSetProps({'slug': data})
          el.$emit('populate-props')
        }
      )
    },
    setNewGridList: function(items){
      if(this.$refs.grid){
        this.$refs.grid.setNewList(items)
      }
    },
    getOpUrl: function(op){
      return '/main/s/' + this.name + '/' + op
    },
    getPostParams: function(){
      return {params: {}}
    },
    getPostData: function () {
      const el = this
      let data = {}
      for(let key of Object.keys(el.fields)){ data[key] = el.itemData[key] }
      return data
    },
    submitForm: function (isUpdate=false){
      const el = this
      const postUrl = el.getOpUrl('item')
      let postParams = el.getPostParams();
      el.$root.$emit('nav-spinner-on');
      if(isUpdate === true){
        postParams.params[el.keyField] = el.itemData[el.keyField]
      }
      const data = this.getPostData();
      axios.post(postUrl, data, postParams).then(function (response){
        el.postSuccessOnDataForm(response.data)
        if(response.data.post_list !== undefined){
          el.setNewGridList(response.data.post_list.result)
          let msg = 'New entry successfully created.'
          if(isUpdate === true){ msg = 'Successfully updated entry.' }
          el.$emit('post-success', response.data.add || response.data.save)
          el.$root.$emit('alert', {msg: msg, kind: 'success'});
          if(response.data.get_props){
            const oldStr = localStorage['formProps.' + el.name]
            const str =  JSON.stringify(response.data.get_props)
            if(oldStr !== str){
              localStorage['formProps.'+el.name] = str
              el.fieldsSetProps( response.data.get_props)
              el.$emit('populate-props')
            }
          }
          el.postSuccess(response.data)
          el.back()
        } else {
          el.$emit('post-failure')
          const keys = Object.keys(response.data.post_item)
          let requiredAbsentfields = []
          let absentFields = {}
          let absentFieldsPresent = false
          for(var i=0; i < keys.length; ++i){
            if(!(keys[i] in el.fields)){
              absentFields[keys[i]] = response.data.post_item[keys[i]]
              absentFieldsPresent = true
            }
          }
          let itemKeys = Object.keys(response.data.post_item).sort()
          for (let j = 0; j < itemKeys.length;++j) {
            if (response.data.post_item[itemKeys[j]].value === null
              || response.data.post_item[itemKeys[j]].value === undefined) {
              requiredAbsentfields.push(itemKeys[j])
            }
          }
          console.log('absent fields', absentFields)
          if(absentFieldsPresent === true){
            try {
              throw Error('Asking user to fix invisible fields')
            } catch (err) {
              Sentry.setContext("absentFields", absentFields);
              Sentry.captureException(err);
              console.error(err)
            }
          }
          el.fieldsSetProps(response.data.post_item)
          el.$root.$emit('alert',
            {msg: 'Please fix the marked fields.', kind: 'danger'}
          );
          console.log('#' + requiredAbsentfields[0])
          document.getElementById('#'
          + requiredAbsentfields[0]).scrollIntoView();
        }
      }).catch(() => {}).finally(function (){
        el.formButtonDisabled = false
        el.$root.$emit('nav-spinner-off')
      })
    },
    viewDetailsPrep: function (details){
      const el = this;
      el.clearForm()
      el.itemData = details
      el.$store.commit('cascadeValuesOnSet', false)
      el.fieldsSetData(details)
      el.formKind = 'display'
      el.formButtonKind = 'edit'
      el.fieldsResetValidation()
      el.$nextTick(function (){
        el.fieldsDisable()
        el.$store.commit('cascadeValuesOnSet', true)
      })
    },
    viewDetails: function (details){
      this.viewDetailsPrep(details)
    },
    printPage: function (){ window.print(); },
    backPrep: function (){
      this.fieldsResetValidation()
      this.clearForm()
    },
    back: function (){ this.backPrep() },
    createPrep: function (){
      this.clearForm()
      this.itemData = {}
      this.formKind = 'display'
      this.formButtonKind = 'add'
      this.fieldsResetValidation()
      this.fieldsSetProps( this.populateProps())
    },
    create: function (){ this.createPrep() },
    formButtonClickedPrep: function (){
      const el = this;
      el.formButtonDisabled = true
      if(el.formButtonKind === 'add'){
        el.submitForm()
        return
      }
      if(el.formButtonKind === 'edit'){
        el.formKind = 'edit'
        el.formButtonKind = 'save'
        el.fieldsEnable()
        el.formButtonDisabled = false
        return
      }
      if(el.formButtonKind === 'save'){
        el.submitForm(true)
        el.formKind = 'display'
        return
      }
    },
    formButtonClicked: function (){ this.formButtonClickedPrep() },
    deleteItem: function (confirmed=false){
      const el = this;
      if(confirmed === false){
        el.$root.$emit('app-action-confirm',
          function () { el.deleteItem(true) },
          'Confirm delete',
          'Deleting is not a reversible action. Do you want to continue?',
          'danger'
        )
        return
      }
      el.formButtonDisabled = true
      const postUrl = el.getOpUrl('deleteitem')
      const params = {params: {}}
      el.$root.$emit('nav-spinner-on');
      let postData = {}
      postData[el.keyField] = el.itemData[el.keyField]
      axios.post(postUrl, postData, params).then(function (response){
        if(response.data.post_list !== undefined){
          el.setNewGridList( response.data.post_list.result)
          el.$root.$emit('alert',
            {msg: 'Delete successful.', kind: 'success'}
          );
          el.back()
        }
      }).catch(() => {}).finally(function (){
        el.formButtonDisabled = false
        el.$root.$emit('nav-spinner-off');
      })
    },
    clearFormPrep: function (){
      const el = this;
      el.itemData = {}
      el.formKind = 'add'
      el.formButtonKind = 'add'
      el.fieldsEnable()
    },
    clearForm: function (){ this.clearFormPrep() },
    populateProps: function (){
      const el = this;
      var opts = localStorage['formProps.'+el.name]
      if(opts !== undefined && opts !== 'undefined'){
        opts = JSON.parse(opts)
      } else {
        opts = {}
      }
      const url = el.getOpUrl('props')
      axios.get(url, {params: {}}).then(function (response){
        el.getModuleProps(response.data.get_props)
        const oldStr = localStorage['formProps.' + el.name]
        const str =  JSON.stringify(response.data.get_props)
        if(oldStr !== str){
          localStorage['formProps.'+el.name] = str
          el.fieldsSetProps( response.data.get_props)
          el.$emit('populate-props')
        }
      }).catch(() => {}
      ).finally(function (){ el.$root.$emit('nav-spinner-off') })
      return opts
    }
  }
}
</script>
