<template>
  <div id="dashboardView">
    <b-container
      v-if="$store.state.erpType === 'rewards'"
      class="text-center p-1 mt-2"
    >
      <b-container style="font-size:44px; height:150px;padding:20px;">
        <b-icon
          icon="person"
          aria-hidden="true"
          class="rounded-circle bg-dark p-2"
          variant="light"
        />
        <h4>Hi, {{ userName }} 👋</h4>
        <div style="margin-top: 20px;">
          <b-row
            class="justify-content-center"
          >
            <a
              style="font-size: 15px;"
              @click="openAccountDetails"
            >Click here to know your user code.</a>
          </b-row>
        </div>
      </b-container>
      <hr>
      <b-row
        v-for="rowindex in 5"
        v-show="gridRows.length == 0"
        :key="'skeleton.' + rowindex"
        class="mt-3"
      >
        <b-col>
          <b-skeleton-img
            no-aspect
            height="100px"
          />
        </b-col><b-col>
          <b-skeleton-img
            no-aspect
            height="100px"
          />
        </b-col>
      </b-row>
      <DashboardRow
        v-for="row in gridRows"
        :key="row.name"
        :row="row"
      />
    </b-container>
    <v-tabs
      v-if="$store.state.erpType !== 'rewards'"
      v-model="getSelectedTab" 
    >
      <v-tab :disabled="disabledDashboard">
        Dashboard
        <v-icon class="ml-2 text-primary">
          mdi-monitor-dashboard
        </v-icon>
      </v-tab>
      <v-tab v-if="!erpLoggedIn">
        Forms
        <v-icon class="ml-2 text-success">
          mdi-navigation-variant-outline
        </v-icon>
      </v-tab>
      <v-tabs-items
        v-model="getSelectedTab"
        :touchless="disabledDashboard"
      >
        <v-tab-item>
          <div class="container-fluid m-1 p-1 mt-2 dashboard">
            <div class="container ml-0">
              <div class="row">
                <h6>Hey, {{ userName }}</h6>
              </div>
              <div class="row">
                <p>
                  Welcome to <strong>Smaac</strong> dashboard 👋🏻
                </p>
              </div>
            </div>
            <div v-if="$store.state.erpType === 'steel'">
              <SteelDashboard />
            </div>
            <div v-if="$store.state.erpType === 'tex'">
              <TextileDashboard />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <b-container class="text-center p-1 mt-2">
            <b-row
              v-for="rowindex in 5"
              v-show="gridRows.length == 0"
              :key="'skeleton.' + rowindex"
              class="mt-3"
            >
              <b-col>
                <b-skeleton-img
                  no-aspect
                  height="100px"
                />
              </b-col><b-col>
                <b-skeleton-img
                  no-aspect
                  height="100px"
                />
              </b-col>
            </b-row>
            <DashboardRow
              v-for="row in gridRows"
              :key="row.name"
              :row="row"
            />
          </b-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import DashboardRow from './DashboardRow.vue';
import SteelDashboard from './steelDashboard.vue'
import TextileDashboard from './textileDashboard.vue'
export default {
  components: { DashboardRow,SteelDashboard,TextileDashboard },
  data: function () {
    return {
      loginRequired: true,
      userName: '',
      erpLoggedIn: false,
      getSelectedTab: 0
    }
  },
  computed: {
    moduleList: function () {
      return this.$router.app.enabledModules;
    },
    disabledDashboard: function () {
      var flagIsEnabled = undefined
      if(this.$store.state.erpType === 'steel'){
        flagIsEnabled = this.$store.getters.flagIsEnabled('module_dashboard');
      }
      if(this.$store.state.erpType === 'tex'){
        flagIsEnabled = 
        this.$store.getters.flagIsEnabled('module_dashboardtex');
      }
      if (typeof flagIsEnabled !== 'undefined') {
        return !flagIsEnabled;
      }
      return undefined;
    },
    gridRows: function () {
      var rows = [];
      var row = [];
      for (var i = 0; i < this.moduleList.length; ++i) {
        var mod = this.moduleList[i];
        if (mod.onlySidebar === undefined) {
          row.push(mod);
          if (row.length === 2) {
            rows.push(row);
            row = [];
          }
        }
      }
      if (row.length > 0) {
        rows.push(row);
      }
      return rows;
    }
  },
  watch: {
    disabledDashboard: function (val) {
      if (val === true) {
        this.getSelectedTab = 1
        if (this.$store.state.erpType === 'steel') {
          this.$root.$emit('alert', {
            msg: 'You do not have access to the SMAAC dashboard.',
            kind: 'warning'
          })
        }
      }
    },
    getSelectedTab: function (val) {
      if (val === 0) {
        window.htmx.process("#dashboardView")
      }
    }
  },
  created: function () {
    var el = this;
    let erpSecret = (new URL(window.location)).searchParams.get('erpsecret')
    if (erpSecret !== undefined && erpSecret !== null) {
      this.erpLoggedIn = true
    }
    el.$root.$emit('nav-filters-off')
    if (el.$store.state.loginRequired === 'false') {
      el.loginRequired = false;
      el.userName = el.$store.state.userName;
    }
    else {
      el.loginRequired = true;
    }
  },
  methods: {
    openAccountDetails: function () {
      this.$router.push({ name: 'rewardsprofile' })
    }
  }
}
</script>
<style>
.dashboard {
  background-color: rgb(243, 246, 251);
}
</style>
