import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as Sentry from "@sentry/vue"
import './registerServiceWorker'
import vuexstore from '@/libs/vuexstore'
import axios from '@/libs/axiosSetup'
import axiosErrorHandler from '@/libs/axiosErrorHandler'
import vuescroll from 'vue-scroll'
import App from './Rewards.vue'
import VueQrcodeReader from "vue-qrcode-reader";
import { Grid } from 'ag-grid-community';
import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

window.Grid = Grid
window.logBuffer = []
window.log = function (ev) {
  ev = ([new Date().toISOString()]).concat(ev)
  window.logBuffer.push(ev)
}
function flushLogs() {
  if (window.logBuffer.length > 0) {
    axios.post(
      '/main/a',
      { events: window.logBuffer },
      {
        params: {
          apitoken: vuexstore.state.apitoken,
          uiversion: vuexstore.state.gitversion
        }
      }
    ).catch(function () { });
    window.logBuffer = []
  }
}
window.addEventListener('beforeunload', function () {
  flushLogs()
})
setInterval(flushLogs, 5000)

Vue.prototype.$siteFevicon = 'https://smaac.in/wp-content/' +
  'uploads/2021/01/cropped-logo-square-S-150x150.png';
Vue.prototype.$apiUrl = 'http://localhost:5000'
Vue.prototype.$siteUrl = 'http://localhost:8080'
Vue.prototype.$axios = axios
// flags
Vue.prototype.$flags = {}
let useSentry = false
vuexstore.commit('setEnv', { key: 'env', val: process.env.VUE_APP_ENV })
vuexstore.commit(
  'setEnv',
  { key: 'gitversion', val: process.env.VUE_APP_GITVERSION }
)
var d = ""
if (process.env.VUE_APP_ENV === 'prod') {
  Vue.prototype.$apiUrl = 'https://api-prod.smaac.in'
  Vue.prototype.$siteUrl = 'https://rewards.smaac.in'
  d = "acf6c4845c8d4e19b45580804c7934cc@glt.smaac.in/5"
  useSentry = true
}
if (process.env.VUE_APP_ENV === 'test') {
  Vue.prototype.$apiUrl = 'https://api-test.smaac.in'
  Vue.prototype.$siteUrl = 'https://rewardstest.smaac.in'
  d = "dccdc1597c23419f8fabbc196ffd200a@glt.smaac.in/6"
  useSentry = true
}
let version = '' + (process.env.VUE_APP_GITVERSION || '')
if (version.length > 0 && version.indexOf('.') === -1) {
  version = version.slice(0, 8)
} else {
  version = version.slice(0, version.indexOf('-'))
}
vuexstore.commit('setEnv', { key: 'gitversion', val: '' + version })
console.log(process.env.VUE_APP_ENV, process.env.VUE_APP_GITVERSION)
if (useSentry) {
  Sentry.init({
    Vue: Vue,
    dsn: 'https://' + d,
    release: process.env.VUE_APP_GITVERSION || 'unknown',
    environment: process.env.VUE_APP_ENV || 'local',
    ignoreErrors: [],
    logErrors: true,
    sampleRate: 1,
    tracesSampleRate: 0
  });
}

Vue.use(require('vue-shortkey'))
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(vuescroll)
Vue.use(VueQrcodeReader)
Vue.config.productionTip = false

// set filter arguments on each route
Vue.mixin({
  beforeRouteEnter(to, from, next) {
    const params = new URLSearchParams(window.location.search);
    const apitoken = params.get('apitoken')
    console.log('Forcing token', apitoken)
    if (
      apitoken !== null
      && apitoken !== vuexstore.state.apitoken
    ) {
      // We should use commit('clearState')
      vuexstore.commit('setUserInfo', { key: 'apitoken', val: apitoken })
      vuexstore.commit('setUserInfo', { key: 'loginRequired', val: "false" });
      vuexstore.commit('setUserInfo', { key: 'singleModule', val: "true" });
      axios.get('/main/v', { params: { apitoken: apitoken } }
      ).then(function (response) {
        if (response.data.loginRequired === false) {
          vuexstore.commit('setUserInfo',
            { key: 'loginRequired', val: "true" });
          vuexstore.commit(
            'setUserInfo',
            { key: 'userName', val: response.data.userName }
          );
        }
        if (response.data.flags) {
          vuexstore.commit('setFlags', response.data.flags)
        }
      }).catch(() => { }).finally(function () {
        next(ve => {
          ve.$root.$emit('circle-button-off')
          ve.$router.app.locName = to.name
          ve.$router.app.filters = to.params['filters']
        });
      })
    } else {
      next(ve => {
        ve.$root.$emit('circle-button-off')
        ve.$router.app.locName = to.name
        ve.$router.app.filters = to.params['filters']
        console.log(
          "UserInfo",
          vuexstore.state.id,
          vuexstore.state.userName,
          vuexstore.state.env,
          vuexstore.state.gitversion
        )
      });
    }
  }
})
// ------------- set up app
let vm = new Vue({ render: h => h(App), store: vuexstore })
Vue.prototype.$mainVm = vm
vm.$mount('#app')
// ------------- set up axios interceptors
axios.interceptors.response.use(
  x => { // normal response handler
    if (x.config.url === '/main/a') { return x }
    var ms = new Date().getTime() - x.config.meta.requestStartedAt;
    window.log(['network', x.config.url, ms])
    return x;
  },
  axiosErrorHandler(vm),
  { synchronous: true }
)
