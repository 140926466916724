<template>
  <b-modal
    id="filtermodal"
    v-model="actualFilterModalVisible"
    title="Filter"
    size="md"
    no-fade
    static
    centered
    scrollable
  >
    <div>
      <strong>{{ title }}</strong>
      <hr>
      <div v-if="enableSearch">
        <b-form-input
          id="searchTerm"
          v-model="form.search"
          type="text"
          placeholder="Search"
        />
      </div>
      <br>
      <slot name="customFilters" />
      <div v-if="enableDate">
        <hr>
        <DateFilter
          :start-date="form.startDate"
          :end-date="form.endDate"
          :date-range="form.dateRange"
        />
      </div>
      <div v-if="enabledStockReportOptions">
        <hr>
        <StockReportOption
          :loc-name="locName"
          :filter-main-group="form.mainGroup"
          :filter-group="form.group"
          :filter-category="form.category"
          :filter-item="form.item"
          :filter-godown="form.godown"
        />
      </div>
      <hr>
    </div>
    <template #modal-footer="{ ok, clearFilter}">
      <b-button
        class="ml-auto"
        @click="click('clearFilter')"
      >
        Clear filter
        <span class="d-none">{{ clearFilter }}</span>
      </b-button>
      <b-button
        class="ml-2 text-capitalize"
        variant="primary"
        @click.stop="emitFilters"
      >
        <span class="d-none">{{ ok }}</span>
        Search
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import StockReportOption from "@/components/Filters/StockReportOptionGrid.vue";
import DateFilter from "@/components/Filters/DateFilter.vue";

export default {
  components: { StockReportOption, DateFilter },
  props: {
    enableDate:{type:Boolean,default:false},
    enableSearch:{type:Boolean,default:false},
    enabledStockReportOptions:{type:Boolean,default:false},
    locName: { type: String, default: "" }
  },
  data: function () {
    // Hack to clone item to make sure that no changes happen to filters
    const f = JSON.parse(JSON.stringify(this.filters || {}));
    return {
      filters:{},
      actualFilterModalVisible:false,
      frozenFilters: f,
      form: {
        search: f.search || "",
        dateRange: f.dateRange || null,
        startDate: f.startDate || "",
        endDate: f.endDate || "",
        mainGroup: f.mainGroup || null,
        group: f.group || null,
        category: f.category || null,
        item: f.item || null,
        godown: f.godown || ""
      }
    };
  },
  computed: {
    title: function () {
      return this.$router.app.titleMap[this.locName];
    }
  },
  created() {
    const el = this;
    el.$root.$on("update:dateRange", function (val) {
      el.form.dateRange = val;
    });
    el.$root.$on("update:startDate", function (val) {
      el.form.startDate = val;
    });
    el.$root.$on("update:endDate", function (val) {
      el.form.endDate = val;
    });
    el.$root.$on("update:filterMainGroup", function (val) {
      el.form.mainGroup = val;
    });
    el.$root.$on("update:filterGroup", function (val) {
      el.form.group = val;
    });
    el.$root.$on("update:filterCategory", function (val) {
      el.form.category = val;
    });
    el.$root.$on("update:filterItem", function (val) {
      el.form.item = val;
    });
    el.$root.$on("update:filterGodown", function (val) {
      el.form.godown = val;
    });
    el.$root.$on('emit-filter-modal',
      function(){
        el.actualFilterModalVisible = true
      })
    el.$root.$on('close-filter-modal',
      function(){
        el.actualFilterModalVisible = false
      })
    el.$root.$on('clear-filters',
      function(){
        el.form = {}
      })
  },
  methods: {
    emitFilters: function(){
      this.$emit('click-save', this.form)
    },
    click: function (kind, fn=null){
      this.$emit('click-'+kind)
      if(fn !== null){ fn() }
    }
  }
};
</script>
<style scoped>
.actionBtn {
  max-width: 23%;
}
</style>
