<template>
  <div>
    <b-input-group size="sm">
      <b-form-select
        v-model="godownSelected"
        :options="godownOptions"
        class="mt-2"
        size="sm"
        placeholder="Select Godown"
      />
    </b-input-group>
    <br>
    <b-input-group size="sm">
      <b-form-select
        v-model="mainGroupSelected"
        :options="mainGroupOptions"
        class="mt-2"
        size="sm"
        placeholder="Select Main Group"
      />
    </b-input-group>
    <b-input-group size="sm">
      <b-form-select
        v-if="mainGroupSelected !== null"
        v-model="groupSelected"
        :options="groupOptions"
        class="mt-2"
        size="sm"
        placeholder="Select Group"
      />
    </b-input-group>
    <b-input-group size="sm">
      <b-form-select
        v-if="mainGroupSelected !== null && groupSelected !== null"
        v-model="catSelected"
        :options="catOptions"
        class="mt-2"
        size="sm"
        placeholder="Select Category"
      />
    </b-input-group>
    <b-input-group size="sm">
      <b-form-select
        v-if="mainGroupSelected !== null &&
          groupSelected !== null && catSelected !== null"
        v-model="itemSelected"
        :options="itemOptions"
        class="mt-2"
        size="sm"
        placeholder="Select Item Name"
      />
    </b-input-group>
  </div>
</template>
<script>
import axios from "@/libs/axiosSetup";

export default {
  props: {
    locName: { type: [String, null], default: null },
    filterGodown: { type: [String, null], default: null },
    filterMainGroup: { type: [String, null], default: null },
    filterGroup: { type: [String, null], default: null },
    filterCategory: { type: [String, null], default: null },
    filterItem: { type: [String, null], default: null }
  },
  data() {
    var groups = {};
    if (localStorage["stockOptionGrid." + this.locName] !== undefined) {
      groups = JSON.parse(localStorage["stockOptionGrid." + this.locName]);
    }
    var godown = {};
    if (localStorage["stockOptionGridGodowns." + this.locName] !== undefined) {
      godown = JSON.parse(
        localStorage["stockOptionGridGodowns." + this.locName]
      );
    }
    var godownSelected = this.filterGodown
    if (godownSelected === undefined || godownSelected === '') {
      for (var i = 0; i < godown.length; ++i) {
        if (godown[i].selected === true) {
          godownSelected = godown[i].value
          break
        }
      }
    }
    return {
      godown: godown,
      groups: groups,
      godownSelected: godownSelected,
      mainGroupSelected: this.filterMainGroup,
      groupSelected: this.filterGroup,
      catSelected: this.filterCategory,
      itemSelected: this.filterItem
    };
  },
  computed: {
    godownOptions: function () {
      var opts = [{ value: null, text: "Select Godown" }];
      var keys = Object.values(this.godown) || [];
      for (var i = 0; i < keys.length; ++i) {
        opts.push({ value: keys[i].value, text: keys[i].text });
      }
      return opts;
    },
    mainGroupOptions: function () {
      var opts = [{ value: null, text: "Select Main Group" }];
      var keys = Object.keys(this.groups) || [];
      for (var i = 0; i < keys.length; ++i) {
        opts.push({ value: keys[i], text: keys[i] });
      }
      console.log(opts);
      return opts;
    },
    groupOptions: function () {
      var opts = [{ value: null, text: "Select Group" }];
      if (this.mainGroupSelected === null) {
        return opts;
      }
      var keys = Object.keys(this.groups[this.mainGroupSelected] || {}) || [];
      for (var i = 0; i < keys.length; ++i) {
        opts.push({ value: keys[i], text: keys[i] });
      }
      console.log(opts);
      return opts;
    },
    catOptions: function () {
      var opts = [{ value: null, text: "Select Category" }];
      if (this.groupSelected === null || this.mainGroupSelected === null) {
        return opts;
      }
      var keys = Object.keys(
        this.groups[this.mainGroupSelected][this.groupSelected] || {}) || [];
      for (var i = 0; i < keys.length; ++i) {
        opts.push({ value: keys[i], text: keys[i] });
      }
      console.log(opts);
      return opts;
    },
    itemOptions: function () {
      var opts = [{ value: null, text: "Select Item" }];
      if (this.catSelected === null || this.mainGroupSelected === null ||
        this.groupSelected === null) {
        return opts;
      }
      var keys = this.groups[this.mainGroupSelected];
      keys = keys[this.groupSelected][this.catSelected] || [];
      for (var i = 0; i < keys.length; ++i) {
        opts.push({ value: keys[i], text: keys[i] });
      }
      console.log(opts);
      return opts;
    }
  },
  watch: {
    filterGodown: function (latest) {
      this.godownSelected = latest;
    },
    filterMainGroup: function (latest) {
      this.mainGroupSelected = latest;
    },
    filterGroup: function (latest) {
      this.groupSelected = latest;
    },
    filterCategory: function (latest) {
      this.catSelected = latest;
    },
    filterItem: function (latest) {
      this.itemSelected = latest;
    },
    mainGroupSelected: function (latest) {
      this.$root.$emit("update:filterMainGroup", latest);
      this.$root.$emit("update:filterGroup", null);
      this.$root.$emit("update:filterCategory", null);
      this.$root.$emit("update:filterItem", null);
    },
    groupSelected: function (latest) {
      this.$root.$emit("update:filterGroup", latest);
      this.$root.$emit("update:filterCategory", null);
      this.$root.$emit("update:filterItem", null);
    },
    catSelected: function (latest) {
      this.$root.$emit("update:filterCategory", latest);
      this.$root.$emit("update:filterItem", null);
    },
    itemSelected: function (latest) {
      this.$root.$emit("update:filterItem", latest);
    },
    godownSelected: function (latest) {
      this.$root.$emit("update:filterGodown", latest);
    }
  },
  created() {
    var el = this;
    var params = {
      params: {
        apitoken: el.$store.state.apitoken
      }
    };
    el.$root.$emit("nav-spinner-on");
    axios
      .get("/main/s/" + el.locName + "/stockgrid", params)
      .then(function (response) {
        const jstr = JSON.stringify(response.data.get_stockgrid.groups);
        localStorage["stockOptionGrid." + el.locName] = jstr;
        const godown = JSON.stringify(response.data.get_stockgrid.godown);
        localStorage["stockOptionGridGodowns." + el.locName] = godown;
        el.groups = response.data.get_stockgrid.groups;
        el.godown = response.data.get_stockgrid.godown;
      })
      .catch(() => { })
      .finally(function () {
        el.$root.$emit("nav-spinner-off");
      });
  }
};
</script>
