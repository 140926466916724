<script>
import isRequired from '@/libs/codeSafety'
export default {
  methods: {
    fieldsResetValidation: function(keys=null){
      this.fieldsResetValidationPrep(keys)
    },
    fieldsResetValidationPrep: function(keys=null){
      const el = this
      keys = keys || Object.keys(el.fields)
      for(let key of keys){
        el.$set(el.fields[key].props, 'state', null)
        el.$set(el.fields[key].props, 'invalid-feedback', '')
        el.$set(el.fields[key].props, 'valid-feedback', '')
        el.$delete(el.fields[key].props, 'value')
      }
    },
    fieldsCascade: function(cascade=isRequired()){
      return this.fieldsCascadePrep(cascade)
    },
    fieldsCascadePrep: function(cascade=isRequired()){
      for(let fieldKey of Object.keys(cascade)){
        if('value' in cascade[fieldKey]){
          if(this.$store.state.cascadeValuesOn === false){
            // retain the value that itemData has right now
            cascade[fieldKey].value = this.itemData[fieldKey]
          }
          this.$set(this.itemData, fieldKey, cascade[fieldKey].value)
          delete cascade[fieldKey].value
        }
        if(fieldKey in this.fields){
          this.$set(this.fields[fieldKey], 'props' , cascade[fieldKey])
        }
      }
    },
    fieldsEnable: function(keys=null){ return this.fieldsEnablePrep(keys)},
    fieldsEnablePrep: function(keys=null){
      const el = this
      keys = keys || Object.keys(el.fields)
      for(let key of keys){
        let props = {
          ...(el.fields[key].props || {}),
          disabled: false
        }
        el.$set(el.fields[key], 'props', props)
      }
    },
    fieldsDisable: function(keys=null){ return this.fieldsDisablePrep(keys)},
    fieldsDisablePrep: function(keys=null){
      const el = this
      keys = keys || Object.keys(el.fields)
      for(let key of keys){
        let props = {
          ...(el.fields[key].props || {}),
          disabled: true
        }
        el.$set(el.fields[key], 'props', props)
      }
    },
    fieldsSetProps: function(props=isRequired()){
      return this.fieldsSetPropsPrep(props)
    },
    fieldsSetPropsPrep: function(props=isRequired()){
      const el = this
      for (let key of Object.keys(props)) {
        if(key in el.fields){
          const oldProps = el.fields[key].props || {}
          const newProps = { ...oldProps, ...props[key] }
          el.$set(el.fields[key], 'props', newProps)
        }
      }
    },
    fieldsSetData: function(data=isRequired()){
      return this.fieldsSetDataPrep(data)
    },
    fieldsSetDataPrep: function (data=isRequired()){
      this.itemData = JSON.parse(JSON.stringify(data))
    },
    valueChange: function(value, key=isRequired()){
      this.valueChangePrep(value, key) 
    },
    valueChangePrep: function(value, key=isRequired()){
      this.$set(this.itemData, key, value)
    }
  }
}
</script>
