<template>
  <div>
    <b-row class="pt-2">
      <b-col :needs-right-vr="row.length == 2">
        <b-link
          :to="{ name: row[0].name, params: {} }"
        >
          <b-icon
            :icon="row[0].icon"
            aria-hidden="true"
            class="h2"
          />
          <h6>{{ row[0].text }}</h6>
        </b-link>
      </b-col>
      <b-col v-if="row.length == 2">
        <b-link
          :to="{ name: row[1].name, params: {} }"
        >
          <b-icon
            :icon="row[1].icon"
            aria-hidden="true"
            class="h2"
          />
          <h6>{{ row[1].text }}</h6>
        </b-link>
      </b-col>
    </b-row>
    <hr v-if="row.length == 2">
  </div>
</template>
<script>
export default {
  props : {
    row: {type: Array, default: () => []}
  }
}
</script>
<style scoped>
a,
a:hover {
  color: #49877A;
}

[needs-right-vr] {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
