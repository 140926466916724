<template>
  <div>
    <div>
      <b-modal
        v-model="scratchModal"
        centered
      >
        <template #modal-footer>
          <div>
            <b-button
              v-if="showClaimMoreButton"
              class="mr-2"
              variant="success"
              @click="scanCouponAgain"
            >
              Claim More
            </b-button>
            <b-button
              class="mr-2"
              variant="primary"
              @click="claimDetails"
            >
              Claim 💰
            </b-button>
            <b-button
              v-if="showRedeemCashButton"
              variant="dark"
              @click="routeToRedeem"
            >
              Redeem 💵
            </b-button>
          </div>
        </template>
        <div
          v-for="fieldName in ClaimScratchCode"
          :key="fieldName"
        >
          <Field
            :key="fieldName + '-' + (fields[fieldName].key || 0)"
            :field-key="fieldName"
            :tag="fields[fieldName].tag"
            :label="fields[fieldName].label"
            :props="fields[fieldName].props || {}"
            :disabled="fields[fieldName].disabled"
            :group-props="fields[fieldName].groupProps || {}"
            :value="itemData[fieldName]"
            @change="valueChange($event, fieldName)"
            @cascade="fieldsCascade"
          />
        </div>
      </b-modal>
      <b-modal
        v-model="showModal"
        centered
        @ok="claimDetails"
      >
        <div
          v-for="fieldName in ClaimFieldsForElectrician"
          :key="fieldName"
        >
          <Field
            :key="fieldName + '-' + (fields[fieldName].key || 0)"
            :field-key="fieldName"
            :tag="fields[fieldName].tag"
            :label="fields[fieldName].label"
            :props="fields[fieldName].props || {}"
            :disabled="fields[fieldName].disabled"
            :group-props="fields[fieldName].groupProps || {}"
            :value="itemData[fieldName]"
            @change="valueChange($event, fieldName)"
            @cascade="fieldsCascade"
          />
        </div>
      </b-modal>
    </div>
    <div class="text-center">
      <div
        v-if="scannerActive & !showScanner"
        class="mt-5 pt-5"
      >
        <img
          src="https://smaacbucketpublic.s3.ap-south-1.amazonaws.com/scanbg.png"
          width="300px"
          fluid
          alt="jpplbg"
        >
        <br>
        <b-button-group class="footer fixed-bottom text-center pt-2 pd-2">
          <b-button
            block
            class="mt-2"
            size="sm"
            variant="primary"
            @click="showScanner = true"
          >
            <b-icon-upc-scan />
            Scan QR code
          </b-button>
          <b-button
            block
            size="sm"
            variant="dark"
            @click="scratchModal = true"
          >
            <b-icon-dash-square />
            Enter scratch code
          </b-button>
        </b-button-group>
      </div>
      <div v-if="showScanner">
        <p> Scan a new code </p>
        <hr>
        <qrcode-stream
          :track="paintOutline"
          @decode="onDecode"
        />
      </div>
    </div>
    <hr>
    <p v-show="alreadyScanned">
      Oops, this QR code is already scanned !
    </p>
    <b-card
      v-if="!scannerActive"
      class="mt-2 mb-5"
    >
      <template #header>
        <h5 class="mb-0">
          {{ itemData.Name || 'Item name' }}
        </h5>
      </template>
      <b-row>
        <b-col>
          <p><u>Product details</u></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              v-for="file in itemData.filename"
              :key="file.id"
              :img-src="file"
            />
          </b-carousel>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <p>Product rating: </p>
        </b-col>
        <b-col>
          <span
            v-for="star in 5"
            :key="star"
            style="color:#FFA500"
          >
            <b-icon-star-fill />
          </span> 5/5
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Type</p>
        </b-col>
        <b-col>:</b-col>
        <b-col>
          <p> {{ itemData.ItemType }} </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Unit</p>
        </b-col>
        <b-col>:</b-col>
        <b-col>
          <p> {{ itemData.Unit }} </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Points</p>
        </b-col>
        <b-col>:</b-col>
        <b-col>
          <p> {{ itemData.Points }} </p>
        </b-col>
      </b-row>
      <template #footer>
        <b-row>
          <b-col>
            <p class="text-success">
              <small><strong> Genuine Product &#10004; </strong></small>
            </p>
          </b-col>
          <b-col>
            <span v-if="itemData.Accounttype !== 'Electrician'">
              <b-button
                variant="primary"
                :disabled="Object.keys(itemData).length === 0"
                @click="claimDetails"
              >
                Claim &#x1F4B0;
              </b-button>
            </span>
            <span v-else>
              <b-button
                variant="primary"
                :disabled="Object.keys(itemData).length === 0"
                @click="openClaimModal"
              >
                Enter details to claim &#x1F4B0;
              </b-button>
            </span>
          </b-col>
        </b-row>
      </template>
    </b-card>
    <b-button
      v-if="!scannerActive"
      class="footer fixed-bottom text-center pt-3 pd-3"
      block
      variant="dark"
      @click="showScanner = true, scannerActive = true"
    >
      <b-icon-upc-scan />
      Scan again
    </b-button>
  </div>
</template>
<script>
import axios from '@/libs/axiosSetup'
import Field from '@/components/forms/fields'
import formMixin from "@/components/forms/mixin"


export default {
  components: { Field },
  mixins: [formMixin],
  data() {
    return {
      showModal: false,
      scratchModal: true,
      scannerActive: false,
      showScanner: false,
      alreadyScanned: false,
      showClaimMoreButton: false,
      showRedeemCashButton: false,
      itemData: {},
      slide: 0,
      sliding: null,
      fields: {
        'RetailerNameDropdown': {
          label: 'Select old retailer',
          tag: 'dropdown', props: {}
        },
        'RetailerNameInput': {
          label: 'Name of retailer',
          tag: 'b-form-input', props: {}
        },
        'RetailerPhoneNo': {
          label: 'Contact number of retailer',
          tag: 'b-form-input', props: { maxlength: "10", minlength: "10" }
        },
        'RetailerNameCheck': {
          label: 'Create new retailer',
          tag: 'b-form-checkbox', props: {}
        },
        'ScratchCode': {
          label: 'Enter scratch code',
          tag: 'b-form-input', props: {}
        }
      }
    }
  },
  computed: {
    name: function () { return 'rewardsdashboard/qrdetails_' },
    slug: function () { return 'rewardsdashboard' },
    ClaimFieldsForElectrician: function () {
      return ['RetailerNameCheck', 'RetailerNameDropdown',
        'RetailerNameInput', 'RetailerPhoneNo']
    },
    ClaimScratchCode: function () {
      return ['ScratchCode']
    }
  },
  // We are using watchers here, since, we are not able to get expected 
  // behavior with computed fields. After entering the name, 
  // while entering the phone number, 
  // the name gets disappeared automatically which is very strange
  watch: {
    "itemData.RetailerNameCheck": function (val) {
      const el = this;
      if (val === true) {
        el.fieldsSetProps({
          RetailerNameDropdown:
            { disabled: true, value: null }
        })
        el.fieldsSetProps({ RetailerPhoneNo: { disabled: false } })
        el.fieldsSetProps({ RetailerNameInput: { disabled: false } })
      }
      else if (val === false) {
        el.fieldsSetProps({
          RetailerNameInput:
            { disabled: true, value: null }
        })
        el.fieldsSetProps({ RetailerPhoneNo: { disabled: true, value: null } })
        el.fieldsSetProps({ RetailerNameDropdown: { disabled: false } })
      }
    }
  },
  created() {
    let currentUrl = new URL(window.location)
    let guid = currentUrl.searchParams.get('guid')
    if (guid !== undefined && guid !== null) {
      guid = guid.replace('/', '')
      currentUrl.search = ''
      window.history.replaceState({}, document.title, currentUrl.toString());
      this.fetchDetailsOfQrCode(guid)
      return
    }
    this.scannerActive = true
    this.fieldsSetProps({ RetailerPhoneNo: { disabled: true } })
    this.fieldsSetProps({ RetailerNameInput: { disabled: true } })
  },
  methods: {
    getOpUrl: function (op) {
      return '/main/s/' + this.name + op
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints
        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    fetchDetailsOfQrCode: function (guid) {
      const el = this;
      axios.get(
        '/main/s/' + el.slug + '/qrdetails_list',
        { params: { 'guid': guid } })
        .then(function (response) {
          if (response.data.code === 403) {
            el.$root.$emit(
              'alert',
              { msg: 'You are not authorized for this QR.', kind: 'warning' }
            ); el.scannerActive = true; return
          }
          let resp = response.data.get_list.result[0]
          if (resp === undefined || resp === null) {
            el.$root.$emit(
              'alert',
              { msg: 'No such QR code found.', kind: 'danger' }
            ); el.scannerActive = true
          }
          if (resp.scannedby_userprofile_id !== null) {
            el.$root.$emit(
              'alert',
              { msg: 'This QR code is already scanned.', kind: 'warning' }
            )
            el.alreadyScanned = true
          } else {
            el.itemData = resp
            el.scannerActive = false
            el.showScanner = false
            el.alreadyScanned = false
          }
        })
    },
    routeToRedeem: function () {
      this.$router.push({ name: 'rewardsredeem' })
    },
    scanCouponAgain: function () {
      this.itemData = {}
    },
    claimDetails: function () {
      const el = this;
      let params = {
        guid: el.itemData.GUid,
        ScratchCode: el.itemData.ScratchCode
      }
      if (el.itemData.ScratchCode === undefined 
      || el.itemData.ScratchCode === null){
        el.$root.$emit('alert', {
          msg: 'Please enter scratch code to redeem',
          kind: 'warning'
        });
        return;
      }
      if (el.itemData.ScratchCode === undefined
        && el.itemData.ScratchCode === null) {
        if (el.itemData.Accounttype === "Electrician") {
          params["RetailerNameDropdown"] = this.itemData.RetailerNameDropdown
          params["RetailerNameInput"] = this.itemData.RetailerNameInput
          params["RetailerPhoneNo"] = this.itemData.RetailerPhoneNo
        }
        if ((el.itemData.RetailerNameDropdown === null
          || el.itemData.RetailerNameDropdown === undefined)
          && (el.itemData.RetailerNameInput === null
            || el.itemData.RetailerNameInput === undefined)) {
          let msg = 'Retailer Name cannot be empty.'
          el.$root.$emit('alert', { msg: msg, kind: 'warning' })
          return
        }
      }
      axios.post(
        '/main/s/' + el.slug + '/qrdetails_item',
        params)
        .then(function (response) {
          if (response.data.code === 429) {
            el.$root.$emit('alert', {
              msg: 'The scratch code is invalid!',
              kind: 'warning'
            })
          }
          if (response.data.ok === true) {
            el.$root.$emit('alert', {
              msg: 'The scratch code has been claimed successfully',
              kind: 'success'
            })
            el.showClaimMoreButton = true
            el.showRedeemCashButton = true
          }
        }).catch((err) => { console.error(err) }
        ).finally(function () {
          el.$root.$emit('nav-spinner-off');
        })
    },
    onDecode: function (code) {
      console.log(code)
      if (code.startsWith('http') && code.indexOf('guid=') !== -1) {
        this.fetchDetailsOfQrCode((new URL(code)).searchParams.get('guid'))
        return
      }
      this.$root.$emit('alert',
        { msg: 'Invalid code: ' + code, kind: 'danger' });
    },
    openClaimModal: function () {
      this.showModal = true
    }
  }
}
</script>
