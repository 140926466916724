<template>
  <div class="mw50">
    <b-button-toolbar
      class="w-100 mb-1"
    >
      <b-dropdown
        size="sm"
        :text="op"
        class="mr-auto mt-n2 mb-n1"
        :disabled="disabled"
        variant="outline"
      >
        <b-dropdown-item @click="op = '='">
          =  Equals
        </b-dropdown-item>
        <b-dropdown-item @click="op = '⊃'">
          ⊃ Contains
        </b-dropdown-item>
      </b-dropdown>
    </b-button-toolbar>
    <b-input-group
      size="sm"
      :disabled="disabled"
      class="mt-n1 mb-0"
    >
      <b-form-input
        v-if="op == '⊃'"
        v-model="text"
        size="sm"
        :disabled="disabled"
      />
      <dropdown
        v-if="op == '='"
        v-model="text"
        :options="options"
        size="sm"
        :disabled="disabled"
      />
    </b-input-group>
  </div>
</template>

<script>
import Filter from './Filter'
import dropdown from '@/components/forms/fields/dropdown'
export default {
  components: {dropdown},
  mixins: [Filter],
  props: {
    options: { type: Array, default: () => [] }
  },
  data(){ return { op: '⊃' }},
  getSelected: function (idx, op, val){
    if(op === '='){
      return idx.exact[val] || (new Set())
    }
    return (
      idx.exact[val]
      || idx.subMatch[val.slice(0, 10).toLowerCase()]
      || (new Set())
    )
  }
}
</script>
