<template>
  <div>
    <div v-show="showView == 'list'">
      <b-modal
        id="detailsModal"
        :title="detailData.fetchStatus"
        scrollable
        no-close-on-backdrop
      >
        <Details
          :config="detailConfig"
          :details-init="detailData"
        />
        <template #modal-footer="{ ok, cancel, hide }">
          <span class="d-none">{{ ok }} {{ cancel }} {{ hide }}</span>
        </template>
      </b-modal>
      <b-modal
        id="commentModal"
        v-model="commentModalVisible"
        title="Comments"
        ok-title="Add comment"
        no-fade
        centered
        scrollable
        no-close-on-backdrop
        @ok="commentModalOk"
      >
        <b-form-group
          invalid-feedback="Cannot add an empty comment."
          :state="commentValid"
        >
          <b-form-input
            v-model="commentConfig.content"
            :state="commentValid"
            placeholder="Enter comment"
          />
        </b-form-group>
        <hr>
        <strong>History</strong>
        <b-table-lite
          small
          striped
          :items="commentConfig.history"
        />
        {{ commentConfig.historyFetchMessage }}
      </b-modal>
      <b-table-lite
        v-if="itemTotals"
        outlined
        small
        :items="itemTotals"
        responsive
      />
      <table class="table table-small table-striped">
        <thead v-if="enableTitle">
          <tr>
            <th>
              <strong v-if="!enableTitleLink">{{ title }}</strong>
              <b-link
                v-else
                :to="{ name: $router.currentRoute.name }"
                @click="$router.go($router.currentRoute)"
              >
                {{ listTitle }}
              </b-link>
              ({{ items.length }}<span v-if="!paginationListComplete">+</span>)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item[sendKey]"
            :class="rowClickClass"
            @click="rowClicked(item)"
          >
            <td>
              <b-container>
                <b-row>
                  <!-- title row -->
                  <b-col>
                    <Check
                      v-if="enableApprovalProcess"
                      :index="index"
                      :value="approvalCheckBoxBehaviour"
                      @change="itemUpdated"
                    >
                      <b>{{ item[titleKey] }}</b>
                    </Check>
                    <div v-if="!enableApprovalProcess">
                      <b>{{ item[titleKey] }}</b>
                    </div>
                  </b-col>
                  <b-col
                    v-if="enableAdditionalInfo"
                    cols="1"
                    class="mr-4 mt-n3 text-right"
                  >
                    <div>
                      <b-dropdown
                        class=""
                        variant="outline-light"
                        size="sm"
                        right
                        no-caret
                      >
                        <template #button-content>
                          <b-icon-three-dots
                            rotate="90"
                            font-scale="1.5"
                            variant="dark"
                          /> 
                        </template>
                        <!-- Additional attachments -->
                        <b-dropdown-item-button
                          v-if="enableAdditionalAttachments"
                          @click.stop="showAdditionalAttachments(item)"
                        >
                          <b-icon-file-text-fill variant="info" /> 
                          {{ additionalAttachmentText }}
                        </b-dropdown-item-button>
                      </b-dropdown>
                    </div>
                  </b-col>
                </b-row> <!-- title row -->
                <b-row>
                  <!-- data & banner row -->
                  <template v-if="!item.showApprovalMessage">
                    <b-col>
                      <!-- left col -->
                      <Row
                        v-for="row in leftCol"
                        :key="row.key"
                        :row="row"
                        :item="item"
                      />
                    </b-col>
                    <b-col>
                      <!-- right col -->
                      <Row
                        v-for="row in rightCol"
                        :key="row.key"
                        :row="row"
                        :item="item"
                      />
                    </b-col>
                  </template>
                  <template v-if="item.showApprovalMessage">
                    <b-alert
                      v-model="item.showApprovalMessage"
                      dismissible
                    >
                      {{ item.approvalMessage }}
                    </b-alert>
                  </template>
                </b-row> <!-- data & banner row -->
                <b-row
                  v-if="showActionBar(item)"
                  class="mt-2"
                >
                  <!-- action row -->
                  <b-col class="pl-0">
                    <b-button
                      :class="actionClasses"
                      variant="outline-secondary"
                      :hidden="!(showDetailsLink && item.OrderNo)"
                      @click.stop="viewDetails(item.OrderNo)"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        class="text-purple"
                      />
                    </b-button>
                    <b-button
                      :class="actionClasses"
                      variant="outline-secondary"
                      :hidden="!(pdfLinkKey && item[pdfLinkKey])"
                      @click.stop="viewPdf"
                    >
                      <div class="actionbutton">
                        <fileViewer
                          :href="item[pdfLinkKey] || ''"
                          :enable-download="item.allowPDFdownload === 1
                            || enableDownload"
                        >
                          <b-icon
                            icon="file-text-fill"
                            variant="danger"
                          />
                        </fileViewer>
                      </div>
                    </b-button>
                    <b-button
                      v-if="enableIframeLink"
                      :class="actionClasses"
                      variant="outline-secondary"
                      :hidden="!(iframeLinkKey && item[iframeLinkKey])"
                      @click.stop="showIframe(item[iframeLinkKey])"
                    >
                      <div class="actionbutton">
                        <small> {{ iframeButtonText }} </small>
                        <b-icon
                          :icon="iframeIcon"
                          :variant="iframeVariant"
                        />
                      </div>
                    </b-button>
                    <b-button
                      v-if="enableComments"
                      :class="actionClasses"
                      variant="outline-secondary"
                      @click.stop="showCommentModal(item)"
                    >
                      <b-icon
                        icon="chat-left-dots-fill"
                        variant="primary"
                      />
                    </b-button>
                    <b-button
                      v-if="enableAttachments"
                      :class="actionClasses"
                      variant="outline-secondary"
                      @click.stop="showAttachment(item)"
                    >
                      <b-icon
                        icon="paperclip"
                        variant="primary"
                      />{{ item.awsfile_count || 0 }}
                    </b-button>
                    <b-button
                      v-if="enableGraph"
                      :class="actionClasses"
                      variant="outline-secondary"
                      @click.stop="showGraph(item)"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        variant="danger"
                      />
                    </b-button>
                  </b-col>
                </b-row> <!-- action row -->
              </b-container>
            </td>
          </tr>
        </tbody>
      </table>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-container
            v-for="index in 10"
            :key="index"
          >
            <br>
            <b-row>
              <b-col> <b-skeleton width="90.25%" /> </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-skeleton width="80%" />
                <b-skeleton width="80%" />
                <b-skeleton width="80%" />
              </b-col>
              <b-col>
                <b-skeleton width="80%" />
                <b-skeleton width="80%" />
                <b-skeleton width="80%" />
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-skeleton-wrapper>
      <div class="text-center">
        <hr>
        <span v-if="enablePaginationEndMessage">
          {{ paginationPageEndMessage }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/libs/axiosSetup'
import axiosLib from 'axios'
import Row from './Row.vue'
import Check from './Check.vue'
import Details from './Details.vue'
import fileViewer from '@/components/fileViewer'
import { debounce } from "lodash";

export default {
  components: { Details, Check, Row, fileViewer },
  props: {
    filters: { type: Object, default: () => { } },
    sendKey: { type: String, default: '' },
    titleKey: { type: String, default: '' },
    additionalAttachmentText: { type: String, default: '' },
    leftCol: { type: Array, default: () => [] },
    rightCol: { type: Array, default: () => [] },
    pdfLinkKey: { type: String, default: '' },
    iframeLinkKey: { type: String, default: '' },
    iframeIcon: { type: String, default: '' },
    iframeButtonText: { type: String, default: '' },
    iframeVariant: { type: String, default: 'primary' },
    pageUrl: { type: String, default: undefined },
    pageParams: { type: Object, default: () => { return {} } },
    enableApprovalProcess: { type: Boolean, default: true },
    enableAdditionalInfo: { type: Boolean, default: false },
    enableComments: { type: Boolean, default: false },
    enableAttachments: { type: Boolean, default: false },
    enableAdditionalAttachments: { type: Boolean, default: false },
    enableGraph: { type: Boolean, default: false },
    enableRowClick: { type: Boolean, default: false },
    showDetailsLink: { type: Boolean, default: false },
    enableIframeLink: { type: Boolean, default: false },
    enableDownload: { type: Boolean, default: true },
    detailConfig: { type: Object, default: () => { } },
    enableTitleLink: { type: Boolean, default: false },
    enablePaginationEndMessage: { type: Boolean, default: true },
    enableTitle: { type: Boolean, default: true },
    listTitle: { type: String, default: '' },
    customFnThatReturnsRowData: { type: Boolean, default: false }

  },
  data() {
    return {
      loading: true,
      apiCallId: null,
      pages: {},
      pageFetchRequstedFor: {},
      paginationListComplete: false,
      approvalCheckBoxBehaviour: [],
      itemsChecked: {},
      itemTotals: [],
      tableFilter: '',
      requestInFlight: true,
      showView: 'list',
      commentModalVisible: false,
      commentValid: null,
      commentConfig: {},
      detailData: {},
      itemLength: '...',
      indexRowData: []
    }
  },
  computed: {
    items: function () {
      let items = []
      let pageNos = Object.keys(this.pages).map(
        function (n) { return Number(n) }
      );
      pageNos.sort()
      for (let i = 0; i < pageNos.length; ++i) {
        items = items.concat(this.pages[pageNos[i]])
      }
      return items
    },
    largestPageFetched: function () {
      let nos = Object.keys(this.pages);
      let n = -1;
      for (let i = 0; i < nos.length; ++i) {
        let k = Number(nos[i])
        if (k > n) { n = k }
      }
      return n
    },
    actionClasses: function () {
      return 'mt-0 mb-0 mr-2 pt-1 pb-0'
    },
    rowClickClass: function () {
      if (this.enableRowClick === true) {
        return 'pointerCursor'
      }
      return ''
    },
    dataUrl: function () {
      return '/main/' + this.locName;
    },
    locName: function () {
      return this.$router.currentRoute.name
    },
    title: function () {
      const name = this.$router.currentRoute.name;
      return this.$router.app.titleMap[name]
    },
    paginationPageEndMessage: function () {
      if (this.paginationListComplete === true) {
        return 'End of list'
      } else {
        if (this.items.length === 0) {
          return 'Fetching items ...'
        } else {
          return 'Loading more items ...'
        }
      }
    }
  },
  watch: {
    pageUrl: function () { this.reloadGrid() },
    pageParams: function (current, old) {
      if (typeof (current) !== typeof (old)) {
        this.reloadGrid()
        return
      }
      let keys = Object.keys(current).concat(Object.keys(old))
      for (let i = 0; i < keys.length; ++i) {
        let va = current[keys[i]]
        let vb = old[keys[i]]
        let neq = va !== vb
        if (neq) {
          this.reloadGrid()
          return
        }
      }
    }
  },
  created() {
    // Increment local view count number.
    const el = this;
    el.$root.$emit('nav-filters-on');
    el.$root.$off('click-back')
    el.$root.$on('click-back', this.closeDetails);
    el.$root.$emit('nav-spinner-on');
    el.reloadGrid = debounce(el.reloadGrid_, 200)
    el.fetchPage = debounce(el.fetchPage_, 200)
    document.addEventListener('scroll', el.onScroll)
    el.fetchPage(0)
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll: function () {
      var D = document;
      var totalHeight = Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
      );
      let current = D.documentElement.scrollTop + window.innerHeight;
      if ((totalHeight - current) < 500) {
        if (this.pageFetchRequstedFor[this.largestPageFetched + 1] !== true) {
          this.pageFetchRequstedFor[this.largestPageFetched + 1] = true
          if (this.items.length >= 50) {
            this.fetchPage(this.largestPageFetched + 1)
          }
        }
      }
    },
    getListApiCallParams: function (pageno, uuid) {
      const el = this
      var gridFiltersEncoded = {}
      var params = {
        params: {
          page: pageno,
          uuid: uuid,
          'page_by': el.pageBy,
          ...gridFiltersEncoded,
          ...(el.pageParams || {})
        }
      };
      return params
    },
    setNewList: function (items) {
      this.pages = { '0': items }
      this.itemLength = items.length
    },
    showIframe: function (item) {
      this.$emit('show-iframe', item)
    },
    showGraph: function (item) {
      this.$emit('show-graph', item)
    },
    showAttachment: function (item) {
      this.$emit('show-attachment', item)
    },
    showAdditionalAttachments:function(item){
      this.$emit('show-additional-attachment', item)
    },
    submitApproval: function () {
      const el = this
      const checkedIdxes = Object.keys(el.itemsChecked)
      var checkedValues = [], itemIdx = null
      for (let key of checkedIdxes) {
        itemIdx = Number(key)
        if (el.itemsChecked[key]) {
          checkedValues.push(el.items[itemIdx][el.sendKey]);
        }
      }
      el.$root.$emit('nav-spinner-on');
      const url = '/main/s/' + el.$router.currentRoute.name + '/approval'
      axios.post(url,
        { 'selected': checkedValues },
        { params: {...el.pageParams || {}} }
      ).then(function (response) {
        el.pages = { '0': response.data.post_list.result }
        el.showToast(response.data.approval_list)
        el.itemsChecked = {}
        el.itemLength = el.items.length
        el.approvalCheckBoxBehaviour = []
      }).catch(() => { }).finally(function () {
        el.$root.$emit('nav-spinner-off')
        el.$root.$emit('circle-button-off')
      })
    },
    showToast: function (data) {
      for (var i = 0; i < data.length; ++i ) {
        console.log(i)
        this.$bvToast.toast(data[i].msg, {
          title: `Your order #${data[i].suppliedKey}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          variant: 'info',
          appendToast: true
        })
      }
    },
    getIndexRowData: function () {
      const checkedIdxes = Object.keys(this.itemsChecked)
      var checkedRowData = [], itemIdx = null
      for (let key of checkedIdxes) {
        itemIdx = Number(key)
        if (this.itemsChecked[key]) {
          checkedRowData.push(this.items[itemIdx])
        }
      }
      this.indexRowData = checkedRowData
      this.$emit('index-row-data', this.indexRowData);
    },
    itemUpdated: function (index, val) {
      let i = '' + index
      this.itemsChecked[i] = val
      this.approvalCheckBoxBehaviour = Object.entries(this.itemsChecked)
      if (val === true) {
        if (this.customFnThatReturnsRowData === true) {
          this.getIndexRowData(index, val)
        }
        this.$root.$emit(
          'circle-button-on',
          [{ fn: this.submitApproval, icon: 'check' }]
        )
      } else {
        let total = 0
        // we need to delete the key if val is false
        // for the particular index,No logic to remain
        // stored in the data structures
        for (let idx in Object.keys(this.itemsChecked)) {
          console.log(idx)
          if (this.itemsChecked[index] === false) {
            delete this.itemsChecked[index];
          }
          total = Object.keys(this.itemsChecked).length
        }
        if (total === 0) {
          this.$root.$emit('circle-button-off')
          this.approvalCheckBoxBehaviour = []
        }
      }
    },
    rowClicked: function (row) {
      this.$emit('click-row', row)
    },
    showCommentModal: function (item) {
      const el = this;
      const key = item[el.sendKey]
      el.commentConfig = {
        key: key,
        historyFetchMessage: 'Fetching comment history...',
        history: []
      }
      el.$root.$emit('nav-spinner-on');
      const url = '/main/s/' + el.locName + '/comment'
      axios
        .get(
          url,
          { params: { key: key } }
        ).then(function (response) {
          el.commentConfig.history = response.data.get_comment
          el.commentConfig.historyFetchMessage = 'End of comments.'
        }).catch(() => { }
        ).finally(function () { el.$root.$emit('nav-spinner-off') })
      this.commentConfig.content = ''
      this.commentValid = null
      el.commentModalVisible = true
    },
    commentModalOk: function (bvModalEvt) {
      const el = this;
      if (
        el.commentConfig.content === null
        || el.commentConfig.content === undefined
        || el.commentConfig.content === ''
      ) {
        bvModalEvt.preventDefault()
        el.commentValid = false
        return
      }
      const data = { comment: el.commentConfig.content }
      el.$root.$emit('nav-spinner-off');
      const url = '/main/s/' + el.locName + '/comment'
      axios.post(
        url,
        data,
        { params: { key: el.commentConfig.key } }
      ).then(
        function (response) {
          console.log(response)
          el.$root.$emit('nav-spinner-off');
        }).catch(() => { }
      ).finally(function () { el.$root.$emit('nav-spinner-off') })
      // Reset comment info
      el.commentConfig = {};
    },
    visibleBadges: function (cols, item) {
      var badges = [];
      for (var i = 0; i < cols.length; ++i) {
        if (item[cols[i].key]) {
          badges.push(cols[i])
        }
      }
      return badges;
    },
    showActionBar: function (item) {
      var el = this;
      return (
        el.showDetailsLink
        || (el.pdfLinkKey && item[el.pdfLinkKey])
        || (el.enableIframeLink && el.iframeLinkKey && item[el.iframeLinkKey])
        || el.enableComments
        || el.enableAttachments
        || el.enableGraph
      )
    },
    reloadGrid: function () {
      // We initialize this function in `created`
      // since we require a per-instance debounce instead of
      // a per component debounce.
      // See https://gitlab.smaac.in/smaac/webapp
      // /-/merge_requests/503#note_649590055
      throw Error("This function should be overridden")
    },
    reloadGrid_: function () {
      const el = this
      el.loading = false
      el.pages = {}
      el.fetchPage(0)
    },
    fetchPage: function () {
      // We initialize this function in `created`
      // since we require a per-instance debounce instead of
      // a per component debounce.
      // See https://gitlab.smaac.in/smaac/webapp
      // /-/merge_requests/503#note_649590055
      throw Error("This function should be overridden")
    },
    fetchPage_: function (pageno, uuid = undefined) {
      if (pageno === undefined) {
        throw Error('Fetching undefined page number')
      }
      const el = this;
      if (el.apiCallId !== null) {
        el.apiCallId.cancel();
      }
      el.apiCallId = axiosLib.CancelToken.source()
      el.loading = true;
      el.$root.$emit('nav-spinner-on')
      // Generate page query params
      let params = el.getListApiCallParams(pageno, uuid)
      params['cancelToken'] = el.apiCallId.token
      let url = el.pageUrl
      if (el.pageUrl === undefined) { url = '/main/s/' + el.locName + '/list' }
      // Fetch the data
      axios
        .get(url, params)
        .then(function (response) {
          if (
            response.data.ok === false
            && response.data.reason === "unauthorized"
          ) {
            el.$root.$emit('nav-spinner-off');
            el.loading = false
            el.$root.$emit('nav-trigger-logout');
            el.$router.push({ name: 'login' });
            return
          }
          if (response.data.retry === true) {
            setTimeout(function () {
              el.fetchPage(pageno, response.data.uuid);
            }, 1000);
          } else {
            if (
              params.params.page === 0
              && response.data.get_totals !== undefined
            ) {
              el.$emit('get-totals-changed', response.data.get_totals.result)
              let keys = Object.keys(response.data.get_totals.result);
              let obj = {};
              for (var i = 0; i < keys.length; ++i) {
                obj[keys[i]] = response.data.get_totals.result[keys[i]]
              }
              el.itemTotals = [obj];
            }
            el.$set(el.pages, pageno, response.data.get_list.result)
            el.pageFetchRequstedFor[pageno] = false
            el.paginationListComplete = response.data.get_list.isComplete
            if (response.data.get_totals !== undefined) {
              el.footerValues = response.data.get_totals.result
              el.$emit('footer-values-changed', el.footerValues);
            }
            el.loading = false
          }
        }).catch((err) => {
          if (!axiosLib.isCancel(err)) { console.error(err) }
        }
        ).finally(function () {
          el.apiCallId = null; el.$root.$emit('nav-spinner-off')
        })
    },
    viewDetails: function (OrderNo) {
      window.log(['page', 'detail-' + this.dataUrl]);
      const el = this;
      var cfg = el.detailConfig
      el.detailData = {}
      el.detailData.fetchStatus = 'Fetching data...'
      cfg.key = OrderNo
      el.$bvModal.show('detailsModal')
      axios.get('/main/s/' + el.locName + '/details',
        { params: { key: OrderNo } }
      ).then(
        function (response) {
          el.detailData = response.data.get_details[0];
          el.detailData.fetchStatus = 'Details'
        }
      ).catch(() => { }).finally(function () {
        el.$root.$emit('nav-spinner-off');
      })
    },
    closeDetails: function () {
      this.$root.$emit('nav-filters-on', this.tableFilter);
    },
    viewPdf: function () {
      window.log(['page', 'pdf-' + this.dataUrl]);
    }
  }
}
</script>
<style>
span {
  -ms-word-break: break-word;
  word-break: break-word;
  word-break: break-word;
  max-width: 100%;
}

.pointerCursor {
  cursor: pointer;
}

.actionbutton a {
  padding: 5px 14px 0 14px;
  margin: -13px;
}
</style>

