<template>
  <div />
</template>
<script>
export default {
  computed: {
    name : function (){return this.$router.currentRoute.name}
  },
  created: function (){
    var el = this;
    el.$root.$emit('nav-filters-off')
    if(el.$store.state.loginRequired === 'true'){
      el.$router.push({name: 'login'})
      return
    }
    if(el.$store.state.userName === '' || el.$store.state.userName === null ){
      el.$router.push({name: 'rewardsprofile'})
      return
    }
    let qrGuid = (new URL(window.location)).searchParams.get('guid')
    if(qrGuid === null || qrGuid === undefined){
      el.$router.push({name: 'home'})
      return
    }
    if((el.$store.state.userName !== '' 
    || el.$store.state.userName !== null ) 
    && (qrGuid !== undefined || qrGuid !== null)){
      console.log("scanner",qrGuid)
      el.$router.push({name: 'rewardsscanner'})
      return
    }
    el.$router.push({name: 'home'})
  }
}
</script>

