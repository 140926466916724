<template>
  <div class="mw110">
    <b-button-toolbar
      class="w-100 mb-1"
    >
      <b-dropdown
        size="sm"
        :text="op"
        class="mr-auto mt-n2 mb-0"
        variant="outline"
        :disabled="disabled"
      >
        <b-dropdown-item @click="op = '='">
          = Equals
        </b-dropdown-item>
        <b-dropdown-item @click="op = 'Range'">
          Range
        </b-dropdown-item>
      </b-dropdown>
    </b-button-toolbar>
    <div
      v-if="op == 'Range'"
      class="date-range"
    >
      <b-input-group
        size="sm"
        prepend=">="
        :disabled="disabled"
      >
        <b-form-datepicker
          v-model="afterDate"
          v-bind="dateprops"
          :max="beforeDate"
          :disabled="disabled"
        />
      </b-input-group>
      <b-input-group
        size="sm"
        prepend="<="
        :disabled="disabled"
      >
        <b-form-datepicker
          v-model="beforeDate"
          v-bind="dateprops"
          :min="afterDate"
          :disabled="disabled"
        />
      </b-input-group>
    </div>
    <b-input-group
      v-if="op == '='"
      size="sm"
      :disabled="disabled"
      class="mt-n1 mb-0"
    >
      <b-form-datepicker
        v-model="text"
        v-bind="dateprops"
        :disabled="disabled"
      />
    </b-input-group>
  </div>
</template>

<script>
import Filter from './Filter'
export default {
  mixins: [Filter],
  data(){
    return {
      afterDate: null,
      beforeDate: null
    }
  },
  computed: {
    isDisabled: function (){
      return (
        this.disabled
        || (
          this.text === null
          && this.beforeDate === null
          && this.afterDate === null
        )
      )
    },
    dateprops: function (){
      return {
        locale:'en-IN',
        size:'sm',
        'show-decade-nav': true,
        'today-button': true,
        'reset-button': true,
        'hide-header': true,
        placeholder: '',
        'date-format-options': { 
          year: 'numeric', 
          month: 'numeric', 
          day: 'numeric' 
        }
      }
    }
  },
  watch: {
    'beforeDate': function (val){
      this.$emit('change', this.fieldKey, this.op, [this.afterDate, val])
    },
    'afterDate': function (val){
      this.$emit('change', this.fieldKey, this.op, [val, this.beforeDate])
    }
  },
  getSelected: function (idx, op, val){
    let valStd
    if(op === '='){
      if(!(val in idx.parseMap)){ return new Set() }
      valStd = idx.parseMap[val].toISOString()
      return idx.eq[valStd] || (new Set())
    } else {
      let after = val[0], before = val[1]
      let afterDate = null, beforeDate = null
      if(after !== null){
        afterDate = new Date(after)
      }
      if(before !== null){
        beforeDate = new Date(before)
      }
      let selected = new Set()
      for(let i=0; i< idx.itemDates.length; ++i){
        let dt = idx.parseMap[idx.itemDates[i]]
        if(afterDate !== null && dt <= afterDate){ continue }
        if(beforeDate !== null && dt >= beforeDate){ continue }
        selected.add(i)
      }
      return selected
    }
  }
}
</script>
<style scoped>
.fw{
  min-width: 60px;
}
</style>
