<template>
  <b-form-checkbox
    v-model="val"
    @change="emitChange"
  >
    <slot />
  </b-form-checkbox>
</template>
<script>
export default {
  props: {
    index: {type: Number, default: 0},
    value: {type: Array, default: () => []}
  },
  data() { return {val: false} },
  watch:{
    value:function(newValue){
      if(newValue.length === 0 ){
        this.val = false
      }
    }
  },
  created(){
    this.setValue()
  },
  methods: {
    emitChange: function (val){
      this.$emit('change', this.index, val)
    },
    setValue(){
      if(this.value.length > 0){
        if(Number(this.value[0][0]) === this.index){
          this.val = true
        }
      }
    }
  }
}
</script>
