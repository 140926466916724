<template>
  <tr
    @mouseover="currentHover = true"
    @mouseleave="currentHover = false"
    @click="rowClicked(row)"
  >
    <td v-show="hasActions">
      <b-dropdown
        size="sm"
        variant="outline"
        no-caret
      >
        <template #button-content>
          <b-icon-three-dots />
        </template>
        <b-dropdown-item-button
          v-for="field in getBadges(row)"
          :key="field.key"
          :disabled="badgeDropdownDisabled(row)"
          @click.stop="badgeClicked(row, field.key)"
        >
          <b-icon
            :icon="field.icon"
            :variant="field.variant"
          /> {{ field.text }}
        </b-dropdown-item-button>
        <b-dropdown-divider />
        <b-dropdown-item-button v-show="hasHistory">
          <b-button
            variant="light"
            @click.stop="$emit('comment', row)"
          >
            <b-icon
              icon="chat-left-dots-fill"
              variant="primary"
            />
            History
          </b-button>
        </b-dropdown-item-button>
      </b-dropdown>
    </td>
    <td>
      <b-button
        v-for="field in getButtons(row)"
        v-show="field.showButton"
        :key="field.key"
        v-b-tooltip
        :variant="field.variant"
        :title="field.buttonTooltip"
        class="border clickable"
        size="sm"
        @click.stop="buttonClicked(row)"
      >
        {{ field.label }}
      </b-button>
      <b-badge
        v-for="field in getBadges(row)"
        v-show="field.showBadge && field.showDropdownBtnAndListBtn"
        :key="field.key"
        :variant="badgeVariant(row)"
        class="border clickable"
        size="sm"
        pill
        @click.stop="badgeClicked(row)"
      >
        <b-icon
          :icon="field.icon"
          :variant="field.variant"
        /> {{ row[field.key] }}
      </b-badge>
    </td>
    <td
      v-for="field in getColumnFields(row)"
      :key="field.key"
      :class="getCssClasses(row,field)"
      :colspan="getColspan(row, field)"
    >
      <component
        :is="getTag(field, row)"
        :variant="getVariant(row, field)"
        :class="getTdCss(row, field)"
        v-bind="getProps(row, field)"
        size="sm"
        :value="row[field.key]"
        @change="cellChange($event, field.key)"
      >
        {{ row[field.key] }}
      </component>
    </td>
  </tr>
</template>
<script>
import fileViewer from '@/components/fileViewer'
import counter from '@/components/counter.vue'
export default {
  components: {counter,fileViewer},
  props: {
    hasActions: {type: Boolean, default: true},
    hasHistory: {type: Boolean, default: true},
    row: { type: Object, default: () => {return {}} },
    fields: { type: Array, default: () => [] },
    index: { type: Number, default: -1 },
    columnFields: { type: Array, default: () => [] },
    hoverExpandOn: { type: Boolean, default: false},
    fullyExpandedCells: { type: Boolean, default: false },
    disabledAllBadges:{ type: Boolean, default: false }
  },
  data() { return { currentHover: false, cellChanged: {} } },
  methods: {
    getColumnFields: function (row){
      const cols = []
      let skip = 0;
      for(var i=0; i < this.columnFields.length; ++i){
        const field = this.columnFields[i]
        if(skip === 0){
          cols.push(field)
        } else {
          skip -= 1
        }
        const colspan = this.getColspan(row, field)
        if(colspan !== ''){
          // TODO: assert skip == 0, "Cannot skip when previous span is running"
          skip = Number(colspan) - 1
        }
      }
      return cols
    },
    cellChange: function(value, key){
      this.cellChanged[key] = true
      this.$emit('change', this.row.id, key, value, this.index)
    },
    getButtons: function(row){
      let bFields = []
      for(let field of this.fields){
        if ((field.showButton === undefined || field.showButton === null)
          && field.showBadge === false) {
          field.showButton = row[field.key] !== 0
        }
        if(
          field.type === 'actionMenu'
          && row[field.key] !== '' && field.showButton === true
        ){ bFields.push(field) }
      }
      return bFields
    },
    getBadges: function(row){
      let bFields = []
      for(let field of this.fields){
        if ((field.showBadge === undefined || field.showBadge === null)
          && field.showButton === false) {
          field.showBadge = row[field.key] !== 0
        }
        if(
          field.type === 'actionMenu'
          && row[field.key] !== '' && field.showBadge === true
        ){ bFields.push(field) }
      }
      return bFields
    },
    badgeVariant: function (row) {
      let color = 'light'
      if (row["awsfile_count.disabled"] === true) {
        color  = 'dark'
        return color
      }
      return color
    },
    badgeDropdownDisabled: function (row) {
      if (this.disabledAllBadges === true) {
        return
      }
      if (row["awsfile_count.disabled"] === true) {
        return true
      }
      return false
    },
    buttonClicked: function (row, fieldKey) {
      this.$emit('button-clicked', row, fieldKey)
    },
    badgeClicked: function (row, fieldKey) {
      if (this.disabledAllBadges === true) {
        return
      }
      if (row["awsfile_count.disabled"] === true) {
        return
      }
      this.$emit('badge-clicked', row, fieldKey)
    },
    getTag: function (field, row){
      const rowTag = row[field.key+'.tag']
      if(rowTag !== undefined && rowTag !== null){ return rowTag }
      if(
        field.editable === false
        || row[field.key + '.editable'] === false
      ){ return 'span'}
      if(field.tag !== undefined){ return field.tag}
      if(field.filter === 'BooleanFilter'){ return 'b-badge'}
      return 'span'
    },
    getVariant: function (row, field){
      if(field.variantKey){
        return row[field.variantKey]
      }
      return row[field.key + '.class']
    },
    getColspan: function(row, field){
      const rowColspan = row[field.key + '.colspan']
      if(rowColspan !== undefined && rowColspan !== null){
        return rowColspan
      }
      const fieldColspan = field.colspan
      if(fieldColspan !== undefined && fieldColspan !== null){
        return fieldColspan
      }
      return ''
    },
    getCssClasses: function (row, field) {
      const rowCssClasses = row[field.key + '.cssClasses']
      if(rowCssClasses !== undefined && rowCssClasses !== null){
        return rowCssClasses
      }
      const fieldCssClasses = field.cssClasses
      if(fieldCssClasses !== undefined && fieldCssClasses !== null){
        return fieldCssClasses
      }
      return ''
    },
    getProps: function(row, field){
      let available = Object.keys(row)
      let props = field.props || {}
      for(var i=0; i < available.length; ++i){
        let key = available[i]
        if(key === field.key+'.class'){ continue }
        if(key.startsWith(field.key +  '.')){
          props[key.split('.')[1]] = row[key]
        }
      }
      return props
    },
    getTdCss: function(row, field){
      let css = '' + (row[field.key + '.class'] || '')
      if(field.key in this.cellChanged){ css += ' border border-warning '}
      if(this.fullyExpandedCells === true){ return css}
      if(this.hoverExpandOn === true && this.currentHover === true){
        return css
      }
      if(field.editable === true){ return css }
      return css + ' d-inline-block text-truncate mw200 table-span'
    },
    rowClicked: function (row){
      this.$emit('click', row)
    }
  }
}
</script>
<style>
.table-span{
  margin: 3px 0px 2px 0px
}
.mw200{ max-width: 200px; }
.table-sm td{
  padding: 0 0.3rem 0 0.3rem;
}
.table td{
  vertical-align: inherit;
}
.width-10{
  width: 10% !important;
}
</style>
