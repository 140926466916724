<template>
  <div>
    <FilterModal
      :enable-date="true"
      :enable-search="true"
      :loc-name="$router.currentRoute.name"
      @click-save="saveFilters"
      @click-clearFilter="clearFilter"
    >
      <template #customFilters>
        <h6>Status</h6>
        <b-form-select
          v-model="selectedOption"
          :options="statusFilterOptions"
        />
      </template>
    </FilterModal>
    <ListView
      send-key="id"
      title-key="Username"
      :left-col="leftCol"
      :right-col="rightCol"
      :page-params="filters"
    />
  </div>
</template>
<script>
import ListView from '@/components/ListView'
import FilterModal from '@/components/FilterModal/index.vue'
export default {
  components: {ListView,FilterModal},
  data: function () {
    return {
      filterModalData : {},
      selectedOption:null,
      leftCol: [
        { label: 'PhoneNo:', key: 'PhoneNo'},
        { label: 'Email:', key: 'Email'},
        { badge: true, key: 'AccountType_label', 
          variantKey: 'AccountType_labelClass'}
      ],
      rightCol: [
        { label: 'City:', key: 'City'},
        { label: 'State:', key: 'label'},
        { badge: true, key: 'Approved', variantKey: 'ApprovedClass'}
      ]
    }
  },
  computed: {
    filters: function(){
      if(this.filterModalData === undefined){return {}}
      var gridFiltersEncoded = {}
      let initKeys = Object.keys(this.filterModalData)
      for(var i =0; i < initKeys.length; ++i){
        gridFiltersEncoded[initKeys[i]] = this.filterModalData[initKeys[i]]
      }
      if(this.selectedOption !== null){ 
        gridFiltersEncoded["Approved"] = this.selectedOption
      }
      return gridFiltersEncoded
    },
    statusFilterOptions: function(){
      const options = [{"text":"Approved","value":1},
        {"text":"Not Approved","value":0}]
      return options
    }
  },
  created (){
    this.$root.$emit(
      'nav-filters-enabled',
      {
        search: true, dates: true
      })
  },
  methods :{
    clearFilter: function(){
      this.filterModalData = {}
      this.selectedOption = null
      this.$root.$emit('close-filter-modal')
      this.$root.$emit('clear-filters')

    },
    saveFilters:function(data){
      this.filterModalData = data
      this.$root.$emit('close-filter-modal')
    }
  }
}
</script>
