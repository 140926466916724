<template>
  <div>
    <actionBar
      :title="formTitle"
      :slug="name"
      :show-view="showView"
      :form-button-kind="formButtonKind"
      :form-button-disabled="formButtonDisabled"
      :delete-enabled="false"
      @click-new="create"
      @click-form-button="formButtonClicked"
      @click-back="back"
      @click-print-page="printPage"
    />
    <b-container
      v-show="showView == 'list'"
      fluid
    >
      <b-tabs content-class="mt-3">
        <b-tab
          title="Generated QR"
          active
        >
          <Grid
            ref="grid"
            :fields="gridFields"
            @rowClicked="listenRowClicked"
          />
        </b-tab>
        <b-tab title="Detailed Report">
          <Grid
            :fields="detailedgridFields"
            :page-url="detailedUrl"
            title="Detailed report"
            @rowClicked="listenRowClicked"
          />
        </b-tab>
        <b-tab title="Claimed report for electrician">
          <Grid
            :fields="electricianclaimedgridFields"
            :page-url="electricianclaimedUrl"
            title="Detailed report"
            @rowClicked="listenRowClicked"
          />
        </b-tab>
      </b-tabs>
    </b-container>
    <b-container v-show="showView == 'dataform'">
      <b-row>
        <b-col>
          <b-card>
            <b-card-title />
            <b-container fluid="sm">
              <b-row>
                <b-col
                  v-for="section in sections"
                  :key="section.title"
                >
                  <h2>{{ section.title }}</h2>

                  <b-row
                    v-for="row in section.rows"
                    :key="row.index"
                  >
                    <b-col
                      v-for="fieldName in row"
                      :key="fieldName"
                    >
                      <Field
                        :key="fieldName + '-' + (fieldsRendered[fieldName].key
                          || 0)"
                        :field-key="fieldName"
                        :tag="fieldsRendered[fieldName].tag"
                        :label="fieldsRendered[fieldName].label"
                        :props="fieldsRendered[fieldName].props || {}"
                        :disabled="fieldsRendered[fieldName].disabled"
                        :group-props="fieldsRendered[fieldName].groupProps
                          || {}"
                        :value="itemData[fieldName]"
                        @change="valueChange($event, fieldName)"
                        @cascade="fieldsCascade"
                      />
                    </b-col>
                  </b-row>
                  <b-button
                    :disabled="itemData.split_from_itemmaster_id === undefined
                      || itemData.split_from_itemmaster_id === null"
                    @click="deleteDetails"
                  >
                    Delete All
                  </b-button>
                </b-col>
              </b-row>
              <Grid
                :fields="addItemsFields"
                :page-url="addItemsURL"
                :page-params="addItemsGridParams"
                title="Added Items"
                @items-changed="itemsChanged"
              />
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <div id="sectionToPrint">
      <printable
        :fields="gridFields"
        :loaded-items="loadedItems"
      />
    </div>
  </div>
</template>
<script>
import Common from './common'
import actionBar from '@/components/actionBar'
import Field from '@/components/forms/fields'
import Grid from '@/components/Grid'
import printable from './printable'

export default {
  components: { Grid, Field, actionBar, printable },
  mixins: [Common],
  props: {
    filters: { type: Object, default: () => { } },
    name: { type: String, default: '' }
  },
  data() {
    return {
      items: [],
      loadedItems: [],
      itemFields: [
        ['ItemMaster_id', 'shouldBeScannedBy_account_type_id'],
        ['VendorName', 'RefNo'],
        ['Rate', 'Qty'],
        ['Points', 'split_from_itemmaster_id'],
        ['Split', 'CustomPoints'],
        ['ScratchCode', 'RetailerPoints']
      ],
      detailedUrl: '/main/s/' + this.name + '/qrdetailedreport_list',
      electricianclaimedUrl: '/main/s/' +
        this.name + '/qrelectricianclaimedreport_list'
    }
  },
  computed: {
    sections: function () {
      return [
        { rows: this.itemFields, title: 'Rewards QR' }
      ]
    },
    gridFields: function () {
      let fields = [
        { label: '#', key: 'id' },
        { label: 'Item Name', key: 'Name' },
        { label: 'Qty', key: 'TotalQty' },
        { label: 'Points', key: 'Points' },
        { label: 'Vendor', key: 'VendorName' },
        { label: 'RefNo', key: 'RefNo' },
        { label: 'Item type', key: 'ItemType' },
        {
          label: 'Voucher No.', 
          key: 'split_from_itemmaster_id'
          
        },
        {
          label: 'Account type', key: 'AccountType',
          cellRenderer: ({ value }) => {
            if (!value) {
              return '';
            }
            let badgeClass = "badge badge-warning";
            if (value === "Electrician") {
              badgeClass = "badge badge-primary";
            } else if (value === "Retailer") {
              badgeClass = "badge badge-dark";
            }
            return `<h6><span class="${badgeClass}">${value}</span></h6>`;
          }
        },
        {
          label: 'Created at', key: 'CreatedAt'
        }
      ]
      return fields
    },
    detailedgridFields: function () {
      let fields = [
        { label: '#', key: 'id' },
        { label: 'Item Name', key: 'Name' },
        { label: 'Qty', key: 'Qty' },
        { label: 'Points', key: 'Points' },
        { label: 'Scratch Code', key: 'scratchcode' },
        { label: 'Item type', key: 'ItemType' },
        {
          label: 'Voucher No.', 
          key: 'split_from_itemmaster_id'
        },
        {
          label: 'Account type', key: 'AccountType',
          cellRenderer: ({ value }) => {
            if (!value) {
              return '';
            }
            let badgeClass = "badge badge-warning";
            if (value === "Electrician") {
              badgeClass = "badge badge-primary";
            } else if (value === "Retailer") {
              badgeClass = "badge badge-dark";
            }
            return `<h6><span class="${badgeClass}">${value}</span></h6>`;
          }
        },
        { label: 'Vendor', key: 'VendorName' },
        { label: 'RefNo', key: 'RefNo' },
        { label: 'Claimed by', key: 'ClaimedBy' },
        {
          label: 'Claimed at', key: 'ScannedAt'
        },
        {
          label: 'Created at', key: 'CreatedAt'
        },
        {
          label: 'Claimed', key: 'Status'
        }
      ]
      return fields
    },
    electricianclaimedgridFields: function () {
      let fields = [
        { label: 'Electrician', key: 'Username' },
        { label: 'Phone No', key: 'PhoneNo' },
        { label: 'City', key: 'City' },
        { label: 'State', key: 'Label' },
        { label: 'Claimed Points', key: 'TotalClaimedPoints' },
        {
          label: 'Redeemed Points',
          key: 'TotalRedeemedPoints'
        },
        {
          label: 'Balance (Claimed + Joining Bonus - Redeemed)',
          key: 'Balance'
        }
      ]
      return fields
    }
  },
  methods: {
    listenRowClicked: function (data) {
      this.viewDetails(data)
    },
    postSuccess: function (data) {
      if (('post_list' in data) && ('add' in data)) {
        let postList = data['post_list'].result
        for (var i = 0; i < postList.length; ++i) {
          if (postList[i].id === data['add'].id) {
            this.viewDetails(postList[i])
            return
          }
        }
      }
    },
    itemsChanged: function (payload) {
      this.loadedItems = payload['items']
    },
    viewDetails: function (details) {
      this.viewDetailsPrepCommon(details)
      this.showView = 'dataform'
    },
    back: function () {
      this.backPrep()
      this.showView = 'list'
      this.itemData = {}
      window.location.reload();
    },
    create: function () {
      this.createPrepCommon()
      this.showView = 'dataform'
    }
  }
}
</script>
