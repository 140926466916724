<template>
  <div>
    <b-form-group
      :id="'#' + fieldKey"
      class="mb-2"
      :disabled="cfg.disabled"
      :state="cfg.state"
      v-bind="groupProps"
      size="sm"
    >
      <template slot="label">
        {{ label }} <span
          v-show="props.required"
          class="text-danger"
        >*</span>
      </template>
      <b-input-group
        :prepend="groupProps.prepend"
        :append="groupProps.append"
        size="sm"
      >
        <component
          :is="tag"
          ref="field"
          :testid="fieldKey"
          v-bind="cfg"
          size="sm"
          @change="change"
          @input="change"
        >
          {{ innerText }}
        </component>
        <b-input-group-append v-if="groupProps.appendButton">
          <b-button
            variant="outline-secondary"
            :disabled="groupProps.appendButton.disabled === true"
            data-toggle="tooltip"
            :title="groupProps.appendButton.tooltip"
            @click.stop="groupProps.appendButton.onclick()"
          >
            <b-icon :icon="groupProps.appendButton.icon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback :state="cfg.state">
        {{ cfg['invalid-feedback'] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script>
import dropdown from './dropdown'
export default {
  components: { dropdown },
  props: {
    fieldKey: { type: String, required: true },
    tag: { type: String, default: 'b-form-input' },
    label: { type: String, default: '' },
    props: { type: Object, required: true },
    groupProps: { type: Object, required: true },
    value: { validator: () => true, default: null }
  },
  data() {
    let cfg = { ...this.props }
    if (this.tag === 'v-select') {
      cfg['items'] = this.props["options"]
    }
    if (this.tag === 'b-form-datepicker') {
      cfg['date-format-options'] = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
      cfg['locale'] = 'en-IN'
      cfg['reset-button'] = true
    }
    delete cfg.value
    if ('state' in cfg && 'required' in cfg) { delete cfg.required }
    return { cfg: cfg }
  },
  computed: {
    innerText: function () {
      if (this.tag.indexOf('radio') !== -1) { return '' }
      if (this.tag.indexOf('checkbox') !== -1) {
        return this.cfg.innerText || ''
      }
      return this.cfg.value
    }
  },
  watch: {
    'value': function (value) { this.setValue(value) },
    'props': function (props) {
      for (let key of Object.keys(props)) {
        this.$set(this.cfg, key, props[key])
      }
      if (props.state === true || props.state === false) {
        this.$set(this.cfg, 'required', false)
      }
    }
  },
  created() {
    this.setValue(this.value)
  },
  methods: {
    setValue: function (value) {
      let key = 'value'
      if (this.tag === 'b-form-checkbox') { key = 'checked' }
      if (this.tag === 'b-form-checkbox-group') { key = 'checked' }
      if (this.tag === 'b-form-radio') { key = 'checked' }
      if (this.tag === 'b-form-radio-group') { key = 'checked' }
      this.$set(this.cfg, key, value)
      this.triggerCascade(value)
    },
    change: function (val) {
      this.$emit('change', val)
      this.triggerCascade(val)
    },
    triggerCascade: function (val) {
      const el = this
      // Be very careful with this since it can lead to infinite loops
      if (
        !('fieldCascade' in el.cfg)
        || el.cfg.fieldCascade === undefined
      ) { return }
      let data = null
      // version style 0 cascade
      if (el.cfg.fieldCascade.v === undefined) {
        const fc = el.cfg.fieldCascade
        if (val in fc) { data = { ...fc[val] } }
      }
      // version style 1 cascade
      if (el.cfg.fieldCascade.v === 1) {
        const fc = el.cfg.fieldCascade
        let cascade = {}
        for (let rule of fc.rules) {
          let value, otherField, prop, assigned
          [value, otherField, prop, assigned] = rule
          if (value === val) {
            if (!(otherField in cascade)) {
              cascade[otherField] = {}
            }
            cascade[otherField][prop] = JSON.parse(fc.values[assigned])
          }
        }
        data = { ...cascade }
      }
      // emit the data
      if (data !== null) {
        this.$emit('cascade', data)
      }
    }
  }
}
</script>

<style scoped>
[required],
[aria-required] {
  border-bottom: 2px solid pink;
  border-radius: 5px;
}
</style>
