<template>
  <div>
    <Navbar
      :module-list="enabledModules"
      :loc-name="locName"
      :filters="filters"
      :update-exists="updateExists"
      @refresh="refreshApp"
    />
    <div class="nav-spacer" />
    <CircleButton />
    <awsfileModal />
    <ActionModal
      :title="actionTitle"
      :text="actionText"
      :variant="actionVariant"
      @confirm="actionConfirmedFn"
    />
    <div class="container-fluid mt-4">
      <b-alert
        v-model="showAlert"
        class="toptoast ztop sticky-alert"
        :variant="alertKind"
        dismissible
      >
        {{ showAlert }} : {{ alertMessage }}
      </b-alert>
      <router-view />
    </div>
  </div>
</template>

<script>
import VueRouter from 'vue-router'

import ActionModal from '@/components/actionModal'
import Navbar from '@/components/Navbar'
import Filters from '@/components/Filters'
import Dashboard from '@/views/Dashboard'
import CircleButton from '@/components/ListView/CircleButton'
import awsfileModal from '@/components/awsfileModal'
import Landing from '@/views/rewards/Landing'
import Profile from '@/views/rewards/Profile'
import Scanner from '@/views/rewards/Scanner'
import Redeem from '@/views/rewards/Redeem'
import Approval from '@/views/rewards/Approval'
import Accounttypereports from '@/views/rewards/Accounttypereports'
import RewardsItemMaster from '@/views/RewardsItemMaster'
import RewardsQR from '@/views/RewardsQR'
import OtpLogin from '@/views/OtpLogin'

import vMixin from '@/components/vMixin'

const routes = [
  {
    name: 'login',
    path: '/login',
    component: OtpLogin,
    isModule: false
  },
  {
    name: 'home',
    path: '/home',
    component: Dashboard,
    isModule: false
  },
  {
    name: 'landing',
    path: '/',
    component: Landing,
    isModule: false
  },
  {
    name: 'rewardsscanner',
    path: '/rewardsscanner',
    text: "Redeem coupon",
    props:true,
    controlledByModule: 'rewardsdashboard',
    component: Scanner,
    icon: 'upc-scan',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'rewardsredeem',
    path: '/rewardsredeem',
    text: "Rewards",
    props: true,
    component: Redeem,
    controlledByModule: 'rewardsdashboard',
    icon: 'cash-stack',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'rewardsitemmaster',
    path: '/rewardsitemmaster',
    component: RewardsItemMaster,
    controlledByModule: 'rewardsitemmaster',
    text: "Item Master",
    icon: 'box-seam',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'rewardsqr',
    path: '/rewardsqr',
    component: RewardsQR,
    controlledByModule: 'rewardsqr',
    text: "QR code generation",
    icon: 'credit-card2-front',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'rewardsprofile',
    path: '/rewardsprofile',
    component: Profile,
    controlledByModule: 'rewardsdashboard',
    text: "Account details",
    icon: 'person-lines-fill',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'rewardsapproval',
    path: '/rewardsapproval',
    component: Approval,
    controlledByModule: 'rewardsapproval',
    text: "Rewards Approval",
    icon: 'person-lines-fill',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'rewardsaccounttypereports',
    path: '/rewardsaccounttypereports',
    component: Accounttypereports,
    controlledByModule: 'rewardsaccounttypereports',
    text: "Registered Users",
    icon: 'person-lines-fill',
    isModule: true,
    platform: 'tex',
    section: 'rewards'
  },
  {
    name: 'Filters',
    path: '/Filters',
    component: Filters ,
    props: true,
    isModule: false
  }
]

const router = new VueRouter({routes:routes})
router.beforeEach((to, from, next) => {
  localStorage.vc = JSON.parse(localStorage.vc || '0') + 1
  window.log(['page', to.path]);
  next();
})

export default {
  name: 'Rewards',
  router:router,
  components: { Navbar, CircleButton, ActionModal ,awsfileModal},
  mixins: [vMixin],
  data: function (){
    var mods = []
    var titleMap = {}
    for(var i=0; i< routes.length; ++i){
      if(routes[i].isModule === true){
        mods.push(routes[i])
        titleMap[routes[i].name] = routes[i].text
      }
    }
    return {
      actionTitle: '',
      actionConfirmedFn: {},
      actionText: '',
      actionVariant: '',
      locName: null,
      filters: null,
      titleMap: titleMap,
      moduleList: mods,
      alertKind: 'info',
      alertMessage: '',
      showAlert: false,
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  computed: {
    enabledModules: function (){
      var enabled = [];
      for(var i=0; i< this.moduleList.length;++i){
        let modFlag = 'module_' + this.moduleList[i].controlledByModule
        if(this.$store.getters.flagIsEnabled(modFlag) === true){
          enabled.push(this.moduleList[i]);
        }
      }
      return enabled;
    }
  },
  created() {
    const el = this;
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    document.title = 'Rewards'
    el.$root.$on('app-action-confirm',
      function ( fn, title, text, variant='primary'){
        el.actionVariant = variant
        el.actionText = text
        el.actionTitle = title
        el.actionConfirmedFn = function (ok){
          ok()
          fn()
        }
        el.$bvModal.show('actionConfirmModal')
      });
    el.$root.$on('alert', function (data){
      el.alertKind = data.kind;
      el.alertMessage = data.msg;
      el.showAlert = 5;
    });
    if(typeof navigator.serviceWorker !== 'undefined'){
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  },
  methods:{
    updateAvailable(event) {
      console.log('Update available')
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.clickable,
.clickable input,
.clickable label {
  cursor: pointer;
}

.no-user-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.nav-spacer {
  height: 50px;
}

.text-purple {
  color: #7952b3;
}

.ztop {
  z-index: 4020;
}

.toptoast {
  position: absolute !important;
  top: auto;
  left: 0;
  width: 100%;
  text-align: center;
}

#sectionToPrint {
  visibility: hidden;
  display: none;
}

@media print {
  body * {
    visibility: hidden;
  }

  #sectionToPrint,
  #sectionToPrint * {
    visibility: visible;
  }

  #sectionToPrint {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  #noPrint {
    display: none !important;
  }
}

.fixed-bottom {
  z-index: calc(1030 - 1);
}

@supports (-webkit-touch-callout: none) {

  /* CSS specific to iOS devices */
  select,
  input,
  textarea {
    font-size: 16px !important;
  }

  .input-group,
  .form-control {
    font-size: 16px !important;
  }
}

.sticky-alert {
  position: fixed !important;
}</style>
