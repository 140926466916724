<script>
import formMixin from "@/components/forms/mixin"
import axios from '@/libs/axiosSetup'
export default {
  mixins: [formMixin],
  data() {
    return {
      fields: {
        id: { label: '', tag: 'input', props: { type: 'hidden' } },
        Rate: { label: 'Rate', tag: 'b-form-input', props: {} },
        'Redeem_id': { label: '', tag: 'input', props: { type: 'hidden' } },
        'ItemMaster_id': { label: 'Item Name', tag: 'dropdown', props: {} },
        GUid: { label: 'GUid', tag: 'b-form-input', props: {} },
        'shouldBeScannedBy_account_type_id': {
          label: 'Type', tag: 'dropdown', props: {}
        },
        Qty: { label: 'Qty', tag: 'b-form-input', props: {} },
        Points: { label: 'Points', tag: 'b-form-input', props: {} },
        'split_from_itemmaster_id':
          { label: 'Voucher id', tag: 'dropdown', props: {} },
        Split: { label: 'Split', tag: 'b-form-checkbox', props: {} },
        VendorName: { label: 'Vendor Name', tag: 'b-form-input', props: {} },
        RefNo: { label: 'Ref No.', tag: 'b-form-input', props: {} },
        CustomPointsCheck: {
          label: 'Custom Points Check',
          tag: 'b-form-checkbox', props: {}
        },
        CustomPoints: {
          label: 'Custom Points',
          tag: 'b-form-input', props: {}
        },
        RetailerPoints: {
          label: 'Retailer Points',
          tag: 'b-form-input', props: {}
        },
        ScratchCode: {
          label: 'Generate scratch code',
          tag: 'b-form-checkbox', props: {}
        }
      },
      leftCol: [],
      rightCol: []
    }
  },
  computed: {
    fieldsRendered: function () {
      const el = this;
      let fields = { ...el.fields };
      // NOTE: The value 2 comes from the database.  Since it's difficulto to
      // use multiple candidate keys in dropdown options during cascades, we are
      // hardcoding it here.
      const ELECTRICIAN = 2
      if (el.itemData.shouldBeScannedBy_account_type_id === ELECTRICIAN) {
        el.$set(el.itemData, "Split", true)
      }
      else {
        el.$set(el.itemData, "Split", false)
      }
      fields["Points"].props = { ...fields["Points"].props, disabled: true }
      fields["Rate"].props = { ...fields["Rate"].props, disabled: true }
      fields["GUid"].props = { ...fields["GUid"].props, disabled: true }
      if (el.itemData.id === null || el.itemData.id === undefined) {
        fields["split_from_itemmaster_id"].props = {
          ...fields["split_from_itemmaster_id"].props, disabled: true
        }
      }
      return fields
    },
    titleKey: function () {
      return this.keyField
    },
    addItemsGridParams: function () {
      if (
        this.itemData.split_from_itemmaster_id === undefined
        || this.itemData.split_from_itemmaster_id === null
      ) { return {} }
      return {
        "split_from_itemmaster_id": this.itemData.split_from_itemmaster_id
      }
    },
    addItemsURL: function () {
      if (
        this.itemData.split_from_itemmaster_id === undefined
        || this.itemData.split_from_itemmaster_id === null
      ) { return {} }
      return '/main/s/' + this.$router.currentRoute.name + '/rewarddetails_list'
    },
    addItemsFields: function () {
      let fields = [
        {
          label: '#', key: 'id'
        },
        {
          label: 'Item Name', key: 'Name'
        },
        {
          label: 'Qty', key: 'Qty'
        },
        {
          label: 'Points', key: 'Points'
        },
        {
          label: 'Created By', key: 'Username'
        },
        {
          label: 'Date', key: 'CreatedAt'
        },
        { label: 'Scratch Code', key: 'scratchcode'  },
        { label: 'Account Type', key: 'Accounttype'  }
      ]
      return fields
    }
  },
  created() {
    this.$root.$emit(
      'nav-filters-enabled',
      { search: true, dates: true }
    )
    this.$root.$emit('nav-filters-on')
  },
  methods: {
    deleteDetails: function (voucherId, confirmed = false) {
      const el = this
      if (confirmed === false) {
        el.$root.$emit('app-action-confirm',
          function () { el.deleteDetails(voucherId, true) },
          'Confirm delete',
          'Do you want to continue deleting these parts? ' +
          'Users will not able to scan these parts once deleted.',
          'danger'
        )
        return
      }
      const postUrl = '/main/s/' + el.name + '/deleteitem'
      let postParams = { params: {} }
      el.$root.$emit('nav-spinner-on');
      let data = {
        'split_from_itemmaster_id':
          el.itemData.split_from_itemmaster_id
      }
      axios.post(postUrl, data, postParams).then(function () {
        let msg = 'Parts deleted successfully.'
        el.$root.$emit('alert', { msg: msg, kind: 'danger' });
      }).catch(() => { }).finally(function () {
        el.formButtonDisabled = false
        el.$root.$emit('nav-spinner-off')
      })
    },
    formButtonClicked: function () {
      this.formButtonClickedPrep()
      // we don't want to enable the save button when id !== null
      if (this.itemData.id !== undefined && this.itemData.id !== null) {
        this.formButtonDisabled = true
      }

    },
    createPrepCommon: function () {
      this.createPrep()
      this.formButtonDisabled = false
    },
    viewDetailsPrepCommon: function (details) {
      this.viewDetailsPrep(details)
    },
    clearForm: function () {
      this.clearFormPrep()
    }
  }
}
</script>
