<template>
  <div>
    <b-modal
      v-model="dispatchproductModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="dispatchproductModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Client-wise Product Dispatch
          </h5>
        </div>
      </template>
      <Grid
        :fields="dispatchproductFields"
        :page-url="dispatchproductUrl"
        :page-params="dispatchproductParams"
        :hide-title-header="true"
        :has-actions="true"
        :has-history="false"
        :max-rows="10"
      />
    </b-modal>
    <b-modal
      v-model="salesproductModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="salesproductModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Recent Product Orders
          </h5>
        </div>
      </template>
      <Grid
        :fields="salesproductFields"
        :page-url="salesproductUrl"
        :page-params="salesproductParams"
        :hide-title-header="true"
        :has-actions="true"
        :has-history="false"
        :max-rows="10"
      />
    </b-modal>
    <b-modal
      v-model="dispatchModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="dispatchModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Client-wise Dispatch
          </h5>
        </div>
      </template>
      <Grid
        :fields="dispatchFields"
        :page-url="dispatchUrl"
        :page-params="dispatchParams"
        :hide-title-header="true"
        :has-actions="true"
        :has-history="false"
        :max-rows="10"
      />
    </b-modal>
    <b-modal
      v-model="templateModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="templateModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Finished Stock
          </h5>
        </div>
      </template>
      <Grid
        :fields="finishedFields"
        :page-url="finishedUrl"
        :hide-title-header="true"
        :has-actions="true"
        :has-history="false"
        :max-rows="10"
      />
    </b-modal>
    <b-modal
      v-model="salesModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="salesModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Recent Orders
          </h5>
        </div>
      </template>
      <Grid
        :fields="orderFields"
        :page-url="orderUrl"
        :page-params="orderParams"
        :hide-title-header="true"
        :has-actions="true"
        :has-history="false"
        :max-rows="10"
      />
    </b-modal>
    <b-container
      fluid
      class="mb-2"
    >
      <b-row>
        <b-col sm>
          <b-button
            id="dispatch-modal"
            class="d-none"
            @click="showDispatchModal"
          />
          <div
            id="dispatchcard_panel"
            :hx-get="dispatch"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col
          v-if="showDispatchProduct"
          sm
        >
          <b-button
            id="dispatchproduct-modal"
            class="d-none"
            @click="showDispatchproductModal"
          />
          <div
            id="dispatchproduct_panel"
            :hx-get="dispatchproduct"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col sm>
          <b-button
            id="orders-modal"
            class="d-none"
            @click="showOrdersModal"
          />
          <div
            id="salescard_panel"
            :hx-get="sales"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col
          v-if="showSalesProduct"
          sm
        >
          <b-button
            id="salesproduct-modal"
            class="d-none"
            @click="showSalesproductModal"
          />
          <div
            id="salesproduct_panel"
            :hx-get="salesproduct"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col sm>
          <div
            id="productioncard_panel"
            :hx-get="production"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col sm>
          <div
            id="finishedcard_panel"
            :hx-get="finished"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
          <b-button
            id="finished-stock-modal"
            class="d-none"
            size="sm"
            @click="showFinishedStockModal"
          />
        </b-col>
        <b-col
          sm
        >
          <div
            id="packinglistsupply_panel"
            :hx-get="packinglistsupply"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col sm>
          <div
            id="purchase_panel"
            :hx-get="purchase"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col>
          <b-button
            id="enquiry-tab"
            class="d-none"
            @click="showEnquiryTab"
          />
          <div
            id="enquiry_panel"
            :hx-get="enquiry"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
              
          md="6"
        >
          <div
            id="dispatchbar_panel"
            :hx-get="dispatchyearly"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col
          sm
        >
          <div
            id="receivepiechart_panel"
            :hx-get="receipt"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            id="receivelinegraph_panel"
            :hx-get="receivelinegraph"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Grid from '@/components/Grid'
import axios from 'axios';
export default {
  components: {  Grid },
  props: {
    rows: { type: Array, default: () => [] }
  },
  data: function () {
    return {
      salesproductModalVisible: false,
      dispatchproductModalVisible: false,
      templateModalVisible: false,
      salesModalVisible: false,
      dispatchModalVisible: false,
      dispatch: `${this.$apiUrl}/ssr/dashboard/dispatch`,
      dispatchproduct: `${this.$apiUrl}/ssr/dashboard/dispatchproduct`,
      dispatchyearly: `${this.$apiUrl}/ssr/dashboard/dispatchyearly`,
      sales: `${this.$apiUrl}/ssr/dashboard/sales`,
      salesproduct: `${this.$apiUrl}/ssr/dashboard/salesproduct`,
      production: `${this.$apiUrl}/ssr/dashboard/production`,
      finished: `${this.$apiUrl}/ssr/dashboard/finished`,
      receipt: `${this.$apiUrl}/ssr/dashboard/receipt`,
      packinglistsupply: `${this.$apiUrl}/ssr/dashboard/packinglistsupply`,
      purchase: `${this.$apiUrl}/ssr/dashboard/purchase`,
      receivelinegraph: `${this.$apiUrl}/ssr/dashboard/receivelinegraph`,
      enquiry: `${this.$apiUrl}/ssr/dashboard/enquiry`,
      finishedStockFilters: "thismonth",
      dispatchFilters: "thismonth",
      dispatchproductFilters: "thismonth",
      orderFilters: "thismonth",
      salesproductFilters: "thismonth",
      triggerGridReloadCounterD: 0,
      triggerGridReloadCounterO: 0,
      getSelectedTab: 0,
      showSalesProduct:true,
      showDispatchProduct:true
    }
  },
  computed: {
    finishedFields: function () {
      let fields = [
        { label: 'Job ID', key: 'jobid', sortable: true },
        { label: 'PartyName', key: 'PartyName', sortable: true },
        {
          label: 'Finished Stock (Kgs)', key: 'FinishedStock',
          sortable: true, totals: true
        }
      ]
      for (var i = 0; i < fields.length; ++i) {
        fields[i].filterEnable = true
        fields[i].sortable = false
      }
      return fields
    },
    dispatchFields: function () {
      let fields = [
        { label: 'Client Name', key: 'PartyName', sortable: true },
        {
          label: 'Dispatch Qty (Kgs)', key: 'KantaWeightGross'
          , sortable: true, totals: true
        },
        {
          label: 'Bill Amount', key: 'InvoiceAmount',
          sortable: true, totals: true
        }
      ]
      for (var i = 0; i < fields.length; ++i) {
        fields[i].filterEnable = true
        fields[i].sortable = false
      }
      return fields
    },
    dispatchproductFields: function () {
      let fields = [
        { label: 'Client Name', key: 'PartyName', sortable: true },
        {
          label: 'Dispatch Qty (Kgs)', key: 'qty'
          , sortable: true, totals: true
        },
        {
          label: 'Bill Amount', key: 'Amount',
          sortable: true, totals: true
        }
      ]
      for (var i = 0; i < fields.length; ++i) {
        fields[i].filterEnable = true
        fields[i].sortable = false
      }
      return fields
    },
    orderFields: function () {
      let fields = [
        { label: 'Order No.', key: 'OrderNo', sortable: true },
        { label: 'Order Date', key: 'orderdate', sortable: true },
        { label: 'Party Name', key: 'PartyName', sortable: true },
        {
          label: 'Weight (Kgs)', key: 'TotalKgs'
          , sortable: true, totals: true
        },
        {
          label: 'Total Amount', key: 'TotalAmount',
          sortable: true, totals: true
        },
        {
          label: 'Sales Person', key: 'SalesEngineer',
          sortable: true, totals: true
        }
      ]
      for (var i = 0; i < fields.length; ++i) {
        fields[i].filterEnable = true
        fields[i].sortable = false
      }
      return fields
    },
    salesproductFields: function () {
      let fields = [
        { label: 'Order No.', key: 'OrderNo', sortable: true },
        { label: 'Order Date', key: 'orderdate', sortable: true },
        { label: 'Party Name', key: 'PartyName', sortable: true },
        {
          label: 'Weight (Kgs)', key: 'qty'
          , sortable: true, totals: true
        },
        {
          label: 'Total Amount', key: 'total',
          sortable: true, totals: true
        }
      ]
      for (var i = 0; i < fields.length; ++i) {
        fields[i].filterEnable = true
        fields[i].sortable = false
      }
      return fields
    },
    salesproductParams: function () {
      return {
        "filter": this.salesproductFilters,
        "trigger": this.triggerGridReloadCounterO
      }
    },
    orderParams: function () {
      return {
        "filter": this.orderFilters,
        "trigger": this.triggerGridReloadCounterO
      }
    },
    dispatchParams: function () {
      return {
        "filter": this.dispatchFilters,
        "trigger": this.triggerGridReloadCounterD
      }
    },
    dispatchproductParams: function () {
      return {
        "filter": this.dispatchproductFilters,
        "trigger": this.triggerGridReloadCounterD
      }
    },
    finishedUrl: function () {
      if (this.finishedStockFilters === undefined) {
        return null
      }
      const name = this.$router.currentRoute.name;
      return '/main/s/' + name + '/list'
    },
    orderUrl: function () {
      if (this.orderFilters === undefined) {
        return null
      }
      const name = this.$router.currentRoute.name;
      return '/main/s/' + name + '/orders_list'
    },
    salesproductUrl: function () {
      if (this.salesproductFilters === undefined) {
        return null
      }
      const name = this.$router.currentRoute.name;
      return '/main/s/' + name + '/salesproduct_list'
    },
    dispatchUrl: function () {
      if (this.dispatchFilters === undefined) {
        return null
      }
      const name = this.$router.currentRoute.name;
      return '/main/s/' + name + '/dispatch_list'
    },
    dispatchproductUrl: function () {
      if (this.dispatchproductFilters === undefined) {
        return null
      }
      const name = this.$router.currentRoute.name;
      return '/main/s/' + name + '/dispatchproduct_list'
    }
  },
  created: function () {
    var el = this;
    el.getSalesProductApiResp()
    el.getDispatchProductApiResp()
  },
  methods: {
    getSalesProductApiResp:function(){
      axios.get(`${this.$apiUrl}/ssr/dashboard/salesproduct`)
        .then(response => {
          if(response.data === ''){
            this.showSalesProduct = false
          }
        })
        .catch(error => {
          this.error = error.message;
        });
    },
    getDispatchProductApiResp:function(){
      axios.get(`${this.$apiUrl}/ssr/dashboard/dispatchproduct`)
        .then(response => {
          if(response.data === ''){
            this.showDispatchProduct = false
          }
        })
        .catch(error => {
          this.error = error.message;
        });
    },
    showFinishedStockModal: function () {
      this.templateModalVisible = true
    },
    showDispatchModal: function () {
      this.dispatchModalVisible = true
      this.triggerGridReloadCounterD += 1
      this.dispatchFilters = document.getElementById("dispatch-card-select")
        .options[document.getElementById("dispatch-card-select")
          .selectedIndex].value
  
    },
    showDispatchproductModal: function () {
      this.dispatchproductModalVisible = true
      this.triggerGridReloadCounterD += 1
      this.dispatchproductFilters = document
        .getElementById("dispatchproduct-card-select")
        .options[document.getElementById("dispatchproduct-card-select")
          .selectedIndex].value
  
    },
    showOrdersModal: function () {
      this.salesModalVisible = true
      this.triggerGridReloadCounterO += 1
      this.orderFilters = document.getElementById("sales-card-select")
        .options[document.getElementById("sales-card-select")
          .selectedIndex].value
  
    },
    showSalesproductModal: function () {
      this.salesproductModalVisible = true
      this.triggerGridReloadCounterO += 1
      this.salesproductFilters = document
        .getElementById("salesproduct-card-select")
        .options[document.getElementById("salesproduct-card-select")
          .selectedIndex].value
    },
    showEnquiryTab: function () {
      if (this.$store.getters.flagIsEnabled('module_enquiry') === true) {
        this.$router.push({ name: 'enquiry' })
      }
      else[
        this.$root.$emit('alert', {
          msg: 'You do not have access for enquiry module',
          kind: 'warning'
        })
      ]
    }
  }
}
</script>