<template>
  <b-modal
    id="actionConfirmModal"
    static
    centered
    :title="title"
    no-close-on-backdrop
    testid-action-modal
  >
    <div>{{ text }}</div>
    <template #modal-footer="{ ok, cancel}">
      <b-button
        class="ml-auto"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        class="ml-2 text-capitalize"
        :variant="variant"
        @click.stop="emitEvent('confirm', ok)"
      >
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    title: {type: String, required: true},
    text: {type: String, required: true},
    variant: {type: String, required: true}
  },
  methods: {
    emitEvent: function(name, payload){
      this.$emit(name, payload)
    }
  }
}
</script>
