<script>
import axios from '@/libs/axiosSetup'
import axiosLib from 'axios'
import { LicenseManager } from 'ag-grid-enterprise';

export default {
  data() { return { apiCallId: null } },
  computed: {
    name: function () { return this.$router.currentRoute.name }
  },
  watch: {
    $route() {
      this.updateVInfo();
    }
  },
  created: function () {
    const el = this;
    el.getSpecialOps()
    el.updateVInfo()
    setTimeout(function () { el.updateVInfo('y') }, 120000)
  },
  methods: {
    getSpecialOps: function() {
      const el = this;
      const agsk = el.$store.state.agsk;
      if (agsk) {
        LicenseManager.setLicenseKey(atob(agsk));
      } else {
        axios.get('/main/agsk')
          .then(function(response) {
            const agsk = response.data.agsk;
            el.$store.commit('setUserInfo', { key: 'agsk', val: agsk });
            LicenseManager.setLicenseKey(atob(agsk));
          });
      }
    },
    setFor: function (name) {
      const el = this
      return function (k, v) {
        el.$store.commit(name, { key: k, val: v });
      }
    },
    updateVInfo: function (heartbeat = 'n') {
      const el = this;
      if (el.apiCallId !== null) {
        return
      }
      el.apiCallId = axiosLib.CancelToken.source()
      let sEnv = el.setFor('setEnv')
      let sUI = el.setFor('setUserInfo')
      axios.get(
        '/main/v', {
          params: { 'heartbeat': heartbeat },
          cancelToken: el.apiCallId.token,
          timeout: 120000
        }
      ).then(function (response) {
        sEnv('apiversion', response.data.v);
        if ('admin' in response.data) {
          sUI('admin', response.data.admin);
        }
        sUI('isTestDb', response.data.isTestDb);
        if (response.data.flags) {
          el.$store.commit('setFlags', response.data.flags)
        }
        if (response.data.tok) {
          sUI('apitoken', response.data.tok);
        }
        if (
          response.data.userName !== undefined
          && response.data.userName !== null
        ) {
          sUI('userName', response.data.userName)
        }
        if (typeof response.data.email === 'string') {
          sUI('id', response.data.id)
          sUI('email', response.data.email)
          sUI('company', response.data.company)
          sUI('erpType', response.data.erpType)
        }
        // require redirect
        let erpSecret = (new URL(window.location)).searchParams.get('erpsecret')
        let apitoken = (new URL(window.location)).searchParams.get('apitoken')
        // set cookie only for erp
        if (erpSecret !== undefined && erpSecret !== null) {
          document.cookie = `apitoken=${apitoken};`
        }
        if (((erpSecret !== undefined && erpSecret !== null))
          && erpSecret.length === 0) {
          el.$root.$emit('alert', {
            msg: "Credentials expired. Please login again.",
            kind: 'danger', duration: 20
          });
          try {
            throw Error('401 unauthorized error (ERP token exipred or unvalid)')
          } catch (err) {
            console.error(err)
          }
        }
        if (((apitoken !== undefined && apitoken !== null))
          && apitoken.length === 0) {
          el.$root.$emit('alert', {
            msg: "Credentials expired. Please login again.",
            kind: 'danger', duration: 20
          });
          try {
            throw Error('401 unauthorized error (API token exipred or unvalid)')
          } catch (err) {
            console.error(err)
          }
        }
        if (
          (erpSecret === undefined || erpSecret === null)
          && response.data.loginRequired === true
          && response.data.userName === null
        ) {
          sUI('loginRequired', 'true')
          sUI('userName', '')
          sUI('email', '')
          sUI('erpType', '')
          sUI('id', '')
          console.log("Not logged in")
          if (el.$router.currentRoute.name !== 'login') {
            el.$root.$emit('alert', {
              msg: (
                "This session has expired."
                + " Please login again."
              ),
              kind: 'danger', duration: 60
            });
            el.$router.push({ name: 'login' })
          }
        }
        if ('forcedLogout' in response.data) {
          el.$root.$emit('alert', {
            msg: (
              "This session has been logged out forcefully"
              + " from another location. Please login again."
            ),
            kind: 'danger', duration: 60
          });
        }
      }).catch((err) => {
        if (!axiosLib.isCancel(err)) {
          if (err.message.indexOf('timeout of') === -1) {
            console.error(err)
          }
        }
      }).finally(function () {
        el.apiCallId = null
        if (heartbeat === 'y') {
          // If this was a heartbeat, call it again
          setTimeout(function () { el.updateVInfo(heartbeat) }, 120000)
        }
      })
    }
  }
}
</script>
