import * as Sentry from "@sentry/vue"
import axios from 'axios'

function handle(app) {
  function errorHandler(err) {
    if (axios.isCancel(err)) { return Promise.reject(err) }
    let notify = true, log = true
    const apiid = err.config.params.apiid
    let msg = `Something went wrong while processing
    your request. Our team has been notified
    and are working on a fix ( EventId: ${apiid} ). 😟`
    if (err.message === 'Network Error') {
      msg = `A network error has occured.
      Please make sure you are connected to the internet
      and no firewalls are blocking connections.
      If this is happening repeatedly please contact us. 😟`
    }
    else if (err.message === 'Request aborted') {
      notify = false
      log = false
    }
    else if (err.message.indexOf('timeout of ') !== -1) {
      msg = 'The network request timed out. Please try again. 😟'
    }
    if (err.config.url === '/main/a') {
      notify = false
      log = false
    }
    if (err.response.status === 424) {
      log = false
    }
    // -----------
    if (log) { Sentry.captureException(err) }
    if (notify && err.config.url !== '/main/a') {
      app.$root.$emit('alert', { msg: msg, kind: 'danger' })
    }
    return Promise.reject(err)
  }
  return errorHandler
}
export default handle
