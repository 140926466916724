<template>
  <div class="mw50">
    <b-button-toolbar
      class="w-100 mb-1"
    >
      <b-dropdown
        size="sm"
        :text="op"
        class="mr-auto mt-n2 mb-n1"
        variant="outline"
        :disabled="disabled"
      >
        <b-dropdown-item @click="op = '='">
          = Equals
        </b-dropdown-item>
      </b-dropdown>
    </b-button-toolbar>
    <b-input-group
      size="sm"
      :disabled="disabled"
      class="mt-n1 mb-n1"
    >
      <b-form-input 
        v-model="text" 
        :disabled="disabled"
        type="number" 
        size="sm"
      />
    </b-input-group>
  </div>
</template>

<script>
import Filter from './Filter'
export default {
  mixins: [Filter],
  getSelected: function (idx, op, val){
    if(op === '='){
      return idx.eq[val] || (new Set())
    }
    return new Set()
  }
}
</script>
