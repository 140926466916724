<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Electrician"
        active
      >
        <Grid
          ref="grid"
          :fields="gridFields"
          :filters="filters"
          :page-params="{Accountlabel:'Electrician'}"
          :enable-totals="false"
          title="Electrician report"
        />
      </b-tab>
      <b-tab title="Retailer">
        <Grid
          :fields="gridFields"
          :filters="filters"
          :page-params="{Accountlabel:'Retailer'}"
          :enable-totals="false"
          title="Retailer report"
        />
      </b-tab>
      <b-tab title="Distributor">
        <Grid
          :fields="gridFields"
          :filters="filters"
          :enable-totals="false"
          :page-params="{Accountlabel:'Distributor'}"
          title="Distributor report"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Grid from '@/components/Grid'

export default {
  components: {Grid},
  props: {
    filters: { type: Object, default: () => {} },
    name: { type: String, default: '' }
  },
  computed: {
    gridFields: function (){
      let fields = [
        { label: '#', key: 'id',sortable: true},
        { label: 'User Name', key: 'Username',sortable: true},
        { label: 'State', key: 'Label',sortable: true},
        { label: 'City', key: 'City',sortable: true},
        { label: 'Phone No', key: 'PhoneNo',sortable: true},
        { label: 'Created At', key: 'CreatedAt',sortable: true},
        { label: 'Edited By', key: 'EditBy_UserProfile_id',sortable: true},
        { label: 'Edited At', key: 'EditedAt',sortable: true},
        { label: 'Company Name', key: 'CompanyName',sortable: true},
        { label: 'GST No', key: 'GSTNo',sortable: true},
        { label: 'Status', badge: true, 
          key: 'Approved', variantKey: 'ApprovedClass'}
      ]
      for(let field of fields){
        if(field.filter === undefined){
          field.filter = 'StringFilter'
        }
      }
      return fields
    }
  }
}
</script>