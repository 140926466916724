<template>
  <div>
    <FilterModal
      :enable-date="true"
      :enable-search="true"
      :loc-name="$router.currentRoute.name"
      @click-save="saveFilters"
      @click-clearFilter="clearFilter"
    />
    <ListView
      v-if="renderComponent"
      send-key="id"
      title-key="name"
      iframe-link-key="payoutlink"
      iframe-icon="cash-stack"
      iframe-variant="success"
      iframe-button-text="Collect"
      :page-params="filters"
      :page-url="pageUrl"
      :left-col="leftCol"
      :right-col="rightCol"
      :enable-attachments="false"
      :enable-approval-process="false"
      :enable-row-click="false"
      :enable-comments="false"
      :enable-iframe-link="true"
      @show-iframe="showRzpPage"
    />
    <b-button
      class="footer fixed-bottom text-center"
      block
      variant="primary"
      @click="enableRedeemButton"
    >
      {{ redeemPointsText }} 🎉
    </b-button>
    <b-modal
      v-model="redeemModal"
      title="Redeem Section"
      centered
    >
      <p>
        Select the amount you want to redeem.
      </p>
      <div class="range-container">
        <b-row>
          <b-col>
            <b-form-input
              v-model="redeemRange"
              type="range"
              :min="minPoints"
              :max="maxPoints"
            />
          </b-col><b-col cols="2">
            <b>{{ maxPoints }}</b>
          </b-col>
        </b-row>
      </div>
      <div v-if="enableRedeem">
        <p>
          Redeem link has also been sent to your
          registered email and phone number.
        </p>
        <p>
          Redirecting to payment gateway...
        </p><div
          class="text-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
        </div>
      </div>
      <template #modal-footer>
        <b-button
          variant="dark"
          :disabled="enableRedeem"
          @click="requestPayoutAll"
        >
          Redeem Full 💵
        </b-button>
        <b-button
          variant="primary"
          :disabled="enableRedeem"
          @click="requestPayout"
        >
          Redeem
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ListView from '@/components/ListView'
import axios from '@/libs/axiosSetup'
import FilterModal from '@/components/FilterModal/index.vue'

export default {
  components: { ListView, FilterModal },
  props: {
    name: { type: String, default: '' }
  },
  data() {
    return {
      filterModalData: {},
      redeemModal: false,
      redeemRange: 0,
      maxPoints: 0,
      minPoints: 0,
      redeemId: null,
      payoutlink: null,
      enableRedeem: false,
      renderComponent: true,
      stopInterval: false
    }
  },
  computed: {
    filters: function () {
      if (this.filterModalData === undefined) { return {} }
      var gridFiltersEncoded = {}
      let initKeys = Object.keys(this.filterModalData)
      for (var i = 0; i < initKeys.length; ++i) {
        gridFiltersEncoded[initKeys[i]] = this.filterModalData[initKeys[i]]
      }
      return gridFiltersEncoded
    },
    redeemPointsText: function () {
      let msg = "";
      return `Redeem Points${msg}`
    },
    pageUrl: function () {
      return '/main/s/' + this.slug + '/redeem_list'
    },
    slug: function () {
      return 'rewardsdashboard'
    },
    leftCol: function () {
      return [
        { label: 'Date:', key: 'ScannedAt', tag: "small" }
      ]
    },
    rightCol: function () {
      return [
        { label: 'Points: ₹', key: 'pointDisplay' },
        { key: 'status', variantKey: 'statusClass', badge: true }
      ]
    }
  },
  created() {
    this.getTotalPoints()
    this.getMinimumRedeem()
    this.$root.$emit(
      'nav-filters-enabled',
      { search: true, dates: true }
    )
  },
  methods: {
    clearFilter: function () {
      this.filterModalData = {}
      this.$root.$emit('close-filter-modal')
      this.$root.$emit('clear-filters')

    },
    saveFilters: function (data) {
      this.filterModalData = data
      this.$root.$emit('close-filter-modal')
    },
    hideFilterModal: function () {
      console.log('getListcalledneeded')
    },
    enableRedeemButton: function () {
      this.redeemModal = true
      this.enableRedeem = false
    },
    showRzpPage: function (src) {
      if (this.payoutlink !== null && this.payoutlink !== undefined) {
        window.location = src
        console.log("Redirect to razorpay link")
      }
      else { window.open(src, '_blank').focus() }

    },
    requestPayout: function () {
      this.postRedeem()
    },
    requestPayoutAll: function () {
      this.postRedeemAll()
    },
    getTotalPoints: function () {
      // We are calling listing API for total points only.
      // Pagination plays no role here.
      const el = this;
      el.$root.$emit('nav-spinner-on');
      axios.get(
        '/main/s/' + this.slug + '/redeem_list')
        .then(function (response) {
          let resp = response.data.get_totals.result.points
          el.maxPoints = resp
        }).finally(function () { el.$root.$emit('nav-spinner-off') })
    },
    getPayoutLink: function () {
      // We are calling listing API for Payout only.
      // Pagination plays no role here.
      // Todo: need to check why this is not working
      const el = this;
      if (el.stopInterval === true) {
        return
      }
      el.$root.$emit('nav-spinner-on');
      axios.get(
        '/main/s/' + this.slug + '/redeem_list',
        {
          params: {
            "page": 0,
            "page_by": 100,
            "o.redeem_id": encodeURI('='),
            "v.redeem_id": el.redeemId
          }
        })
        .then(function (response) {
          let resp = response.data.get_list.result[0].payoutlink
          if (resp !== null) {
            el.payoutlink = resp
            el.showRzpPage(resp)
            el.stopInterval = true
            el.redeemModal = false
          }
        }).finally(function () { el.$root.$emit('nav-spinner-off') })
    },
    getMinimumRedeem: function () {
      const el = this;
      axios.get(
        '/main/s/' + this.slug + '/redeem_props')
        .then(function (response) {
          let resp = response.data.get_props.consts.minimumRedemptionAmt
          el.minPoints = resp
          el.redeemRange = resp
        })
    },
    postRedeem: function () {
      const el = this;
      el.$root.$emit('nav-spinner-on');
      el.enableRedeem = true
      el.renderComponent = false;
      axios.post(
        '/main/s/' + this.slug + '/redeem_item', { "Points": el.redeemRange })
        .then(function (response) {
          let resp = response.data.add.id
          el.redeemId = resp
        }).finally(function () {
          el.$root.$emit('nav-spinner-off');
          el.getTotalPoints()
          el.renderComponent = true;
          var interval = setInterval(() => {
            if (el.stopInterval === true) {
              clearInterval(interval);
            }
            el.getPayoutLink()
          }, 2000)
        })
    },
    postRedeemAll: function () {
      const el = this;
      el.$root.$emit('nav-spinner-on');
      el.enableRedeem = true
      el.renderComponent = false;
      console.log(el.maxPoints)
      axios.post(
        '/main/s/' + this.slug + '/redeem_item', { "Points": el.maxPoints })
        .then(function (response) {
          let resp = response.data.add.id
          el.redeemId = resp
        }).finally(function () {
          el.$root.$emit('nav-spinner-off');
          el.getTotalPoints()
          el.renderComponent = true;
          var interval = setInterval(() => {
            if (el.stopInterval === true) {
              clearInterval(interval);
            }
            el.getPayoutLink()
          }, 2000)
        })
    }
  }
}
</script>