<template>
  <div>
    <div v-if="!heading">
      <b-row>
        <b-col class="text-left">
          {{ left }}
        </b-col>
      </b-row>
      <small><b-row>
        <b-col>
          {{ value }}
        </b-col>
        <b-col class="text-right">
          {{ right }}
        </b-col>
      </b-row></small>
      <b-row>
        <b-col>
          <b-progress
            class="mt-2"
            :max="max"
            show-value
          >
            <b-progress-bar 
              :value="value"
              :variant="variant"
            />
          </b-progress>
        </b-col>
      </b-row><hr>
    </div>
    <h4 v-if="heading">
      {{ heading }}
    </h4>
  </div>
</template>
<script>
export default {
  props: {
    heading: {type: String, default: ''},
    left: {type: String, default: ''},
    right: {type: [Number, String], default: 0},
    value: {type: Number, default: 0},
    max: {type: [Number, String], default: 100},
    variant: {type: String, default:'info'}
  }
}
</script>
