<script>
import axios from '@/libs/axiosSetup'
import formMixin from "@/components/forms/mixin"
export default {
  mixins: [formMixin],
  data(){
    return {
      fields: {
        Name: {label: 'Item Name', tag: 'b-form-input', props: {}},
        'itemType_id': {label: 'Item Type', tag: 'dropdown', props: {}},
        'unit_id': {label: 'Unit', tag: 'dropdown', props: {}},
        Rate: {label: 'Rate', tag: 'b-form-input', props: {}},
        Points: {label:'Points', tag: 'b-form-input', props: {}},
        Weight: {label: 'Weight', tag: 'b-form-input', props: {}},
        Active: {label: 'Active', tag: 'b-form-checkbox', props: {}}
      },
      leftCol: [
        { label: 'Type', key: 'type_listmaster_id'},
        { label: 'Unit', key: 'unit_listmaster_id'}
      ],
      rightCol: [
        { label: 'Rate', key: 'Rate'},
        { label: 'Wt', key: 'Weight'},
        { label: 'Points', key: 'Points'}

      ]
    }
  },
  computed: {
    titleKey: function (){
      return this.keyField
    }
  },
  created(){
    this.$root.$emit(
      'nav-filters-enabled',
      { search:true, dates: true }
    )
    this.$root.$emit( 'nav-filters-on')
  },
  methods : {
    showAttachment: function(item){
      const el = this
      el.$root.$emit(
        'awsfile-modal-on',
        item.id,
        el.name,
        item.awsfile_count,
        function(count){ item['awsfile_count'] = count }
      )
    },
    formButtonClicked: function (){
      this.formButtonClickedPrep()
    },
    createPrepCommon: function (){
      this.createPrep()
    },
    viewDetailsPrepCommon: function (details){
      this.viewDetailsPrep(details)
    },

    clearForm: function (){
      this.clearFormPrep()
    },
    deleteItem: function (confirmed=false){
      const el = this;
      if(confirmed === false){
        el.$root.$emit('app-action-confirm',
          function () { el.deleteItem(true) },
          'Confirm delete',
          'Deleting is not a reversible action. Do you want to continue?',
          'danger'
        )
        return
      }
      el.formButtonDisabled = true
      const postUrl = el.getOpUrl('deleteitem')
      const params = {params: {}}
      el.$root.$emit('nav-spinner-on');
      let postData = {}
      postData[el.keyField] = el.itemData[el.keyField]
      axios.post(postUrl, postData, params).then(function (response){
        if(response.data.post_list !== undefined){
          el.setNewGridList( response.data.post_list.result)
          if(response.data.id === 'missing'){
            el.$root.$emit('alert',
              {msg: 'Delete successful.', kind: 'success'}
            );
            el.back()
          }else{
            el.$root.$emit('alert',
              {
                msg: 'QR stickers has already been printed for this item.',
                kind: 'warning'
              }
            );
          }
        }
      }).catch(() => {}).finally(function (){
        el.formButtonDisabled = false
        el.$root.$emit('nav-spinner-off');
      })
    }
  }
}
</script>
