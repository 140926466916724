<template>
  <div>
    <b-container
      fluid
      class="mt-5"
    >
      <div class="table-responsive">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th
                v-for="field in fields"
                :key="field.key"
              >
                <span class="w-100 text-left text-nowrap">
                  <b-icon
                    v-show="field.sortable !== undefined?field.sortable : true"
                    :icon="gridFilters[field.key].sortIcon"
                    size="sm"
                  />
                  {{ field.label }}
                </span>
              </th>
            </tr>
            <tr>
              <th
                v-for="field in fields"
                :key="field.key"
              >
                <span
                  v-show="
                    txtLst[gridFilters[field.key].index] !== null &&
                      txtLst[gridFilters[field.key].index] !== undefined"
                >
                  {{ getFilterDisplay(
                    opLst[gridFilters[field.key].index] ,
                    txtLst[gridFilters[field.key].index])
                  }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td
                v-for="field in fields"
                :key="field.key"
              >
                {{ item[field.key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-container>
    <br>
  </div>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
    itemOrder: { type: Array, default: () => [] },
    gridFilters: { type: Object, default: () => {return {}} },
    opLst: { type: Array, default: () => [] },
    txtLst: { type: Array, default: () => [] },
    fields: { type: Array, default: () => [] },
    visibleItems: { type: Object, default: () => {return {}} }
  },
  methods: {
    getFilterDisplay: function (op, val){
      if(val === undefined){ return ''}
      if(val === null){ return ''}
      if(op === 'Range'){
        return '< ' + val[1] + ' and  > ' + val[0]
      }
      if(op === 'Has'){
        return 'Has "' + val + '"'
      }
      return op + ' "' + val + '"'
    }
  }
}
</script>
