import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
const EMPTY = ''
const TRUE = '"true"'
const FALSE = '"false"'

function getState(ignoreLocal=false){
  function get(key, defaultValue){
    if(ignoreLocal === true){ return defaultValue }
    let val = localStorage[key]
    if(val  === 'undefined'){ return defaultValue }
    try {
      val = JSON.parse(val || defaultValue)
    } catch {
      val = defaultValue
    }
    return val
  }
  return {
    cascadeValuesOn : FALSE,
    // user login information
    loginRequired :get('loginRequired',  TRUE),
    id :get('id',  EMPTY),
    userName :get('userName',  EMPTY),
    erpType :get('erpType',  EMPTY),
    email :get('email',  EMPTY),
    company: get('company', EMPTY),
    address: get('address', EMPTY),
    apitoken :get('apitoken',  EMPTY),
    agsk :get('agsk',  EMPTY),
    singleModule :get('singleModule',  FALSE),
    isTestDb :get('isTestDb',  FALSE),
    admin: get('admin', FALSE),
    flags: get('flags', {}),
    // environment
    gitversion: get('gitversion', '"local"'),
    apiversion: get('apiversion', '"local"')
  }
}

const store = new Vuex.Store({
  state: getState(),
  getters: {
    flagIsEnabled: (state) => (flag) => {
      return state.flags[flag]
    }
  },
  mutations: {
    setFlags(state, flags){ state.flags = flags },
    cascadeValuesOnSet(state, payload){
      state.cascadeValuesOn = payload
    },
    setUserInfo(state, payload){
      if(payload.key === undefined){
        throw new Error('Required attribute absent')
      }
      let val = JSON.stringify(payload.val)
      localStorage[payload.key] = val
      state[payload.key] = payload.val
    },
    setEnv(state, payload){
      let val = JSON.stringify(payload.val)
      localStorage[payload.key] = val
      state[payload.key] = payload.val
    },
    clearState(state){
      localStorage.clear()
      let newState = getState(true)
      let keys = Object.keys(newState)
      for(let i = 0; i < keys.length; ++i){
        state[keys[i]] = newState[keys[i]]
      }
    }
  }
})

export default store
