<template>
  <div>
    <b-modal
      :id="'fileViewer.' + viewId"
      v-model="showModal"
      centered
      scrollable
      dialog-class="mw-100"
      content-class="mw-100"
      no-close-on-backdrop
      footer-class="m-0 p-0"
      header-class="m-1 p-1"
    >
      <template
        #modal-header
      >
        <b-button-group
          v-if="filetype === 'pdf'"
          size="sm"
        >
          <b-button
            siz
            @click="zoomOut"
          >
            -
          </b-button>
          <b-button variant="light">
            🔍️ Zoom
          </b-button>
          <b-button @click="zoomIn">
            +
          </b-button>
        </b-button-group>
        <b-button
          size="sm"
          variant="outline-light"
          @click="showModal = false"
        >
          <b-icon-x variant="danger" />
        </b-button>
      </template>
      <b-container
        v-if="filetype === 'image'"
        class="text-center"
      >
        <b-img
          v-if="showModal"
          :src="href"
          fluid
        />
      </b-container>
      <b-container v-if="filetype === 'pdf'">
        <pdfvuer
          v-if="href"
          id="pdfContainerId"
          ref="pdfvuer"
          :page="pdfPage"
          :src="href"
          style="width:100%;margin:20px auto;"
          :resize="true"
          @num-pages="pdfNumPages"
          @page-loaded="pdfLoaded"
        >
          <template slot="loading">
            loading content here...
          </template>
        </pdfvuer>
      </b-container>
      <template #modal-footer>
        <b-container fluid>
          <b-row
            class="mb-2"
          >
            <b-col
              v-if="filetype === 'pdf'"
              class="w-100"
            >
              <b-button
                size="sm"
                @click="pdfPage > 1 ? pdfPage-- : 1"
              >
                &lt;
              </b-button>
              <span class="ml-auto mr-auto">
                {{ pdfPage }} of {{ pdfPageCount }}
              </span>
              <b-button
                class="ml-2 mr-2"
                size="sm"
                @click="pdfPage < pdfPageCount ? pdfPage++ : 1"
              >
                &gt;
              </b-button>
            </b-col>
            <b-col
              v-if="filetype === 'pdf'"
              cols="2"
              class="mr-n2 text-right"
            >
              <span v-if="enableDownload">
                <b-link
                  :href="href"
                  rel="noopener"
                  target="_blank"
                  download
                >
                  <b-button
                    size="sm"
                  >
                    <b-icon-download />
                  </b-button>
                </b-link>
              </span>
              <span v-if="!enableDownload">
                <b-button
                  id="download-button"
                  size="sm"
                >
                  <b-icon-download />
                </b-button>
                <b-tooltip
                  target="download-button"
                  placement="top"
                  show
                >
                  You need to approve the order to download PDF
                </b-tooltip>
              </span>
            </b-col>
            <b-col
              class="text-right"
              cols="2"
            >
              <b-button
                size="sm"
                variant="outline-light"  
                @click="shareFile"
              >
                <b-icon-share-fill
                  variant="primary"            
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
    <a
      :href="href"
      target="_blank"
      rel="noopener"
      download
      @click="viewFile"
    ><slot /></a>
  </div>
</template>
<script>
// @ts-ignore
import pdfvuer from 'pdfvuer'
import 'pdfjs-dist/build/pdf.worker.entry'
import axios from '@/libs/axiosSetup'

export default {
  components: { pdfvuer },
  props: {
    href: { type: String, required: true },
    enableDownload: { type: Boolean, default: true }
  },
  data() {
    return {
      viewId: Math.random(),
      showModal: false,
      pdfPage: 1,
      pdfPageCount: 0,
      pdfContainerId: '',
      step: 70
    }
  },
  computed: {
    filetype: function () {
      let link = this.href
      if (link.indexOf('?') !== -1) {
        link = link.split('?')[0]
      }
      if (
        link.endsWith('.png')
        || link.endsWith('.jpeg')
        || link.endsWith('.jpg')
        || link.endsWith('.gif')
      ) { return 'image' }
      if (link.endsWith('.pdf')) {
        return 'pdf'
      }
      return 'unknown'
    }
  },
  watch: {
    "showModal": function (val) {
      if (val === true && this.filetype === "pdf") {
        setTimeout(() => {
          this.pdfContainerId = document.getElementById('pdfContainerId')
        }, 1000);
      }
    }
  },
  methods: {
    shareFile:function() {
      let fileBlob;
      let fileName;
      if (this.filetype === 'image') {
        fileName = 'image.jpg';
        axios
          .get(this.href, { responseType: 'blob' })
          .then((response) => {
            fileBlob = new Blob(
              [response.data], 
              { type: `image/jpg}` });
            if (navigator.share) {
              navigator
                .share({
                  files: [new File(
                    [fileBlob], 
                    fileName, 
                    { type: `image/jpg` })]
                })
                .then(() => console.log('File shared successfully!'))
                .catch((error) => console.error('Error sharing file:'
                  , error));
            } else {
              this.$root.$emit('alert', 
                { msg: 'Sharing is not supported in this browser.', 
                  kind: 'warning' });
            }
          })
          .catch((error) => {
            console.error('Error loading image:', error);
            this.$root.$emit('alert', 
              { msg: 'Error loading image. Please try again.',
                kind: 'warning' });
          });
      } else if (this.filetype === 'pdf') {
        axios
          .get(this.href, { responseType: 'blob' })
          .then((response) => {
            fileName = 'document.pdf';
            fileBlob = new Blob([response.data], { type: 'application/pdf' });
            if (navigator.share) {
              navigator
                .share({
                  files: [new File(
                    [fileBlob], 
                    fileName, 
                    { type: 'application/pdf' })]
                })
                .then(() => console.log('File shared successfully!'))
                .catch((error) => console.error('Error sharing file:',
                  error));
            } else {
              this.$root.$emit('alert', 
                { msg: 'Sharing is not supported in this browser.',
                  kind: 'warning' });
            }
          })
          .catch((error) => {
            console.error('Error loading PDF:', error);
            this.$root.$emit('alert',
              { msg: 'Error loading PDF. Please try again.', 
                kind: 'warning' });
          });
      } else {
        this.$root.$emit('alert',
          { msg: 'Unsupported file type.', 
            kind: 'warning' });
      }
    },
    zoomIn: function () {
      var step = Number(this.step)
      var w = Number(this.pdfContainerId.style.width.replace('%', ''))
      if ((w <= ((20 * step) + 100))) {
        this.pdfContainerId.style.width = (w + step) + '%'
      }
    },
    zoomOut: function () {
      var step = Number(this.step)
      var w = Number(this.pdfContainerId.style.width.replace('%', ''))
      if (w > step) {
        this.pdfContainerId.style.width = (w - step) + '%'
      }
    },
    viewFile: function (event) {
      if (this.filetype !== 'unknown') {
        event.preventDefault()
        var data = pdfvuer.createLoadingTask(this.href);
        data.then(pdf => {
          this.pdfPageCount = pdf.numPages
        })
        this.showModal = true

      }
    },
    // pdf functions
    pdfNumPages: function (pc) {
      this.pdfPageCount = pc;
      this.pdfPage = 1
      if (pc < 1) { this.pdfPage = 0 }
    },
    pdfLoaded: function (pno) {
      this.pdfPage = pno;
    }
  }
}
</script>