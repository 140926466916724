<script>
export default {
  data() { return { isMobile: window.innerWidth < 770 } },
  created(){
    const el = this;
    window.addEventListener('resize', function (){
      el.isMobile = window.innerWidth < 770;
    });
  }
}
</script>
