<template>
  <div>
    <actionBar
      :title="formTitle"
      :slug="name"
      :show-view="showView"
      :form-button-kind="formButtonKind"
      :form-button-disabled="formButtonDisabled"
      @click-delete="deleteItem"
      @click-new="create"
      @click-form-button="formButtonClicked"
      @click-back="back"
    >
      <template #dropdownButtonContent>
        <b-badge
          variant="light"
          class="border clickable"
          size="sm"
          pill
        >
          <b-icon
            icon="paperclip"
            variant="info"
            font-scale="0.9"
          /> {{ itemData.awsfile_count }}
        </b-badge>
      </template>
      <template #dropdown>
        <b-dropdown-item
          @click="showAttachmentForCurrentItem"
        >
          <b-icon-paperclip
            variant="info"
          />
          Attachments
        </b-dropdown-item>
      </template>
    </actionBar>
    <b-container
      v-show="showView == 'list'"
      fluid
    >
      <Grid
        ref="grid"
        :fields="gridFields"
        :filters="filters"
        @row-clicked="viewDetails"
        @rowBadgeClicked="showAttachment"
      />
    </b-container>
    <b-container v-show="showView == 'dataform'">
      <b-row>
        <b-col>
          <b-card>
            <b-card-title />
            <b-container fluid="sm">
              <b-row>
                <b-col
                  v-for="section in sections"
                  :key="section.title"
                >
                  <h2>{{ section.title }}</h2>

                  <b-row
                    v-for="row in section.rows"
                    :key="row.index"
                  >
                    <b-col
                      v-for="fieldName in row"
                      :key="fieldName"
                    >
                      <Field
                        :key="fieldName + '-' + (fields[fieldName].key || 0)"
                        :field-key="fieldName"
                        :tag="fields[fieldName].tag"
                        :label="fields[fieldName].label"
                        :props="fields[fieldName].props || {}"
                        :disabled="fields[fieldName].disabled"
                        :group-props="fields[fieldName].groupProps || {}"
                        :value="itemData[fieldName]"
                        @change="valueChange($event, fieldName)"
                        @cascade="fieldsCascade"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Common from './common'
import actionBar from '@/components/actionBar'
import Field from '@/components/forms/fields'
import Grid from '@/components/Grid'
export default {
  components: {Grid, Field, actionBar},
  mixins: [Common],
  props: {
    filters: { type: Object, default: () => {} },
    name: { type: String, default: '' }
  },
  data(){
    return {
      itemFields: [
        [ 'Name'],
        [ 'itemType_id','Active'],
        [ 'Points','unit_id'],
        [ 'Weight', 'Rate']
      ]
    }
  },
  computed: {
    sections: function (){
      return [
        {rows: this.itemFields, title: 'Item Master'}
      ]
    },
    gridFields: function (){
      let fields = [
        { label: '#', key: 'id',sortable: true},
        { label: 'Item Name', key: 'Name',sortable: true},
        { label: 'Type', key: 'ItemType',sortable: true},
        { label: 'Points', key: 'Points',sortable: true},
        { label: 'Weight', key: 'Weight',sortable: true},
        { label: 'Rate', key: 'Rate',sortable: true},
        { label: 'Unit', key: 'Unit',sortable: true},
        { label: 'Active', key: 'Active',sortable: true},
        {
          key: 'awsfile_count',
          type: 'actionMenu', icon: 'paperclip',
          variant: 'info',
          showBadge: true,
          showDropdownBtnAndListBtn: true,
          text: 'Attachments'
        }
      ]
      for(let field of fields){
        if(field.filter === undefined){
          field.filter = 'StringFilter'
        }
      }
      return fields
    }
  },
  methods : {
    showAttachmentForCurrentItem: function(){
      this.showAttachment(this.itemData)
    },
    viewDetails: function (details){
      this.viewDetailsPrepCommon(details)
      this.showView = 'dataform'
    },
    back: function (){
      this.backPrep()
      this.showView = 'list'
    },
    create: function (){
      this.createPrepCommon()
      this.showView = 'dataform'
    }
  }
}
</script>
