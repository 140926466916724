<template>
  <div
    v-if="show"
    class="text-right bottom-right"
  >
    <b-modal
      id="circlebuttonmodal"
      v-model="modalVisible"
      title="Actions"
      size="md"
      no-fade
      static
      centered
      scrollable
      hide-footer
    >
      <b-container>
        <b-row
          v-for="button in buttonArray.slice(1)"
          :key="button.index"
        >
          <b-button
            class="w-100 mb-2"
            :variant="button.variant"
            @click="button.fn()"
          >
            {{ button.text }}
          </b-button>
        </b-row>
      </b-container>
    </b-modal>
    <b-container class="mr-0 pr-2">
      <b-row v-show="buttonArray.length > 1">
        <b-button
          class="btn-circle mb-2 ml-auto"
          size="sm"
          variant="secondary"
          @click="showModal"
        >
          <b-icon-three-dots-vertical />
        </b-button>
      </b-row>
      <b-row>
        <b-button
          class="btn-circle mb-2 ml-auto"
          :size="buttonArray[0].primary?'':'sm'"
          :variant="buttonArray[0].variant"
          :testid="'circlebutton-'+buttonArray[0].icon"
          @click="buttonArray[0].fn()"
        >
          <b-icon :icon="buttonArray[0].icon" />
        </b-button>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data: function (){
    return {
      buttonArray: [],
      show: false,
      modalVisible: false
    }
  },
  created: function () {
    var el = this;
    this.$root.$on('circle-button-on',  function (buttonArray){
      let arr = []
      let arrSize = buttonArray.length
      let button
      if(arrSize > 3){ arrSize = 3}
      for(var i = 0; i < arrSize; ++i){
        if(i !== 0 && buttonArray[i].text === undefined){
          throw Error('Button text has to be defined')
        }
        button = {
          fn: buttonArray[i].fn,
          variant: buttonArray[i].variant || (i===0?'primary':'secondary'),
          icon: buttonArray[i].icon || (i===0?'plus': 'circle'),
          text: buttonArray[i].text,
          primary: i === 0
        }
        arr.push(button)
      }
      el.buttonArray = arr
      el.show = true;
    })
    this.$root.$on('circle-button-off',  function (){
      el.buttonArray = []
      el.show = false
      el.modalVisible = false
    })
  },
  methods: {
    showModal: function (){ this.modalVisible = true }
  }
}
</script>
<style scoped>
.btn-circle{  border-radius: 50px;
  padding: 7px 11px 7px 11px;
  font-size: 2pc;
  box-shadow: 0px 0px 5px grey;}
.btn-sm.btn-circle{
  font-size:1pc;
}
.bottom-right{
  position:fixed;
  bottom:10px;
  z-index:10;
  right: 0;
  padding: 10px;
}
</style>
