<template>
  <div>
    <a
      ref="downloadLink"
      class="d-none"
    />
    <b-row v-show="!hideTitleHeader">
      <b-col
        class="mt-0"
      >
        <span>
          <strong v-if="!enableTitleLink">{{ gridTitle }}</strong>
          <b-link
            v-else
            :to="{ name: $router.currentRoute.name }"
            @click="$router.go($router.currentRoute)"
          >
            {{ gridTitle }}
          </b-link>
        </span>
      </b-col>
      <b-col
        v-if="$slots.headerFilters"
        class="mt-0"
      >
        <slot name="headerFilters" />
      </b-col>
      <b-col
        class="text-right mr-auto mt-0"
      >
        <b-button-group class="border rounded">
          <b-button
            variant="light"
            size="sm"
            data-toggle="tooltip"
            title="Refresh grid"
            @click="reloadBasedOnGrid"
          >
            <b-icon icon="bootstrap-reboot" />
          </b-button>
          <template v-if="hasExpandCollapse">
            <b-button
              variant="light"
              size="sm"
              @click="gridOpts.api.expandAll()"
            >
              <b-icon icon="arrows-expand" />
            </b-button>
            <b-button
              variant="light"
              size="sm"
              @click="gridOpts.api.collapseAll()"
            >
              <b-icon icon="arrows-collapse" />
            </b-button>
          </template>
          <template
            v-if="enableExportDownloadButton"
          >
            <b-dropdown
              id="dropdown-1"
              variant="light"
              size="sm"
            >
              <template #button-content>
                <b-icon-download />
              </template>
              <b-dropdown-item 
                @click="agGridIsNotAllowed === false ?
                  csvExport() : downloadFile('csv')"
              >
                Csv
              </b-dropdown-item>

              <b-dropdown-item 
                @click="agGridIsNotAllowed === false ?
                  excelExport() : downloadFile('xlsx')"
              >
                Xlsx
              </b-dropdown-item>
              <b-dropdown-item
                @click="downloadFile('pdf')"
              >
                Pdf
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-button
            v-if="enablePrintButton"
            size="sm"
            variant="light"
            class="border"
            data-toggle="tooltip"
            title="Ctrl + P"
            @click="printPage"
          >
            🖨️
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <div
      v-if="agGridIsNotAllowed"
      ref="gridcontainer"
      v-scroll="onScroll"
      class="table-responsive border"
      :style="tableContainerStyle"
    >
      <table class="table table-striped table-hover table-sm table-bordered">
        <thead :class="headFootClasses">
          <slot name="headers" />
          <tr>
            <th v-if="!hasActions" />
            <th
              v-show="hasActions"
              colspan="2"
              :rowspan="atLeastOneFilterIsEnabled ? 2 : 1"
              class="align-middle"
            >
              <b-dropdown
                size="sm"
                variant="outline"
                no-caret
              >
                <template #button-content>
                  <b-icon-gear />
                  <b-spinner
                    v-show="loading"
                    class="ml-2"
                    small
                    label="Spinning"
                  />
                </template>
                <b-dropdown-item-button>
                  <b-form-checkbox
                    v-model="hoverExpandOn"
                    switch
                    size="sm"
                  >
                    Hover expand
                  </b-form-checkbox>
                </b-dropdown-item-button>
              </b-dropdown>
            </th>
            <th
              v-for="field in columnFields"
              :key="field.key"
            >
              <span class="w-100 text-left text-nowrap">
                <!-- eslint-disable -->
                <span>
                  <b-icon
                    v-show="field.sortable !== undefined ? field.sortable : true"
                    :icon="gridFilters[field.key].sortIcon"
                    size="sm"
                    class="clickable mr-0 ml-n1"
                    @click="changeSort(field.key)"
                  /></span>
                <span v-html="field.label" />
                <!-- eslint-enable -->
                <span v-show="field.tooltip !== undefined"><b-button
                  type="button"
                  size="sm"
                  variant="outline-light"
                  class="ml-1 mb-1 p-0 bg-white border-0"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="field.tooltip"
                >
                  <b-icon
                    icon="exclamation-circle"
                    font-scale="0.9"
                    variant="dark"
                  />
                </b-button></span>
              </span>
            </th>
          </tr>
          <tr v-show="atLeastOneFilterIsEnabled">
            <th
              v-for="field in columnFields"
              :key="field.key"
              :class="field.computedCss"
            >
              <component
                :is="field.filter || 'StringFilter'"
                v-show="field.filterEnable !== undefined ?
                  field.filterEnable : true"
                :field-key="field.key"
                :options="filterOptions[field.key] || []"
                @change="filterUpdate"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="rowindex in 30"
            v-show="rowindex < skeletonRows"
            :key="'skeleton.' + rowindex"
          >
            <td colspan="2">
              <b-skeleton />
            </td>
            <td
              v-for="field in columnFields"
              :key="field.key"
            >
              <b-skeleton />
            </td>
          </tr>
          <tr
            v-show="pageUrl === null || (items.length == 0)"
          >
            <td
              :colspan="columnFields.length + 2"
              class="text-center"
            >
              {{ noResultsForFilter }}
            </td>
          </tr>
          <Row
            v-for="(item, index) in items"
            :key="item.id"
            :has-actions="hasActions"
            :has-history="hasHistory"
            :row="item"
            :index="index"
            :fields="fields"
            :column-fields="columnFields"
            :hover-expand-on="hoverExpandOn"
            :fully-expanded-cells="fullyExpandedCells"
            :disabled-all-badges="disabledAllBadges"
            class="clickable"
            @click="rowClicked"
            @change="rowChanged"
            @badge-clicked="badgeClicked"
            @button-clicked="buttonClicked"
            @comment="showCommentModal"
          />
        </tbody>
        <tfoot :class="headFootClasses">
          <tr v-show="enableTotals">
            <th
              :colspan="hasActions === true ? 2 : 1"
            >
              <span v-show="hasActions">Totals</span>
            </th><th
              v-for="field in columnFields"
              :key="field.key"
              :class="field.computedCss"
            >
              <span
                v-show="field.totals === true"
                class="w-100 text-left text-nowrap"
              >
                {{ footerValues[field.key] || '' }}
              </span>
            </th>
          </tr>
          <slot name="footers" />
        </tfoot>
      </table>
    </div>
    <div v-if="!agGridIsNotAllowed">
      <div
        v-if="checkIfAgGridLicenseIsSet"
        :id="agGridId"
        ref="gridcontainer"
        :style="tableContainerStyle"
        :class="tableClasses"
      />
    </div>
    <div
      v-if="enablePrint"
      id="sectionToPrint"
    >
      <printable
        :items="items"
        :fields="columnFields"
        :grid-filters="gridFilters"
      />
    </div>
    <b-modal
      id="commentModal"
      v-model="commentModalVisible"
      title="Comments"
      ok-title="Add comment"
      no-fade
      centered
      scrollable
      no-close-on-backdrop
      @ok="commentModalOk"
    >
      <b-form-group
        invalid-feedback="Cannot add an empty comment."
        :state="commentValid"
      >
        <b-form-input
          v-model="commentConfig.content"
          :state="commentValid"
          placeholder="Enter comment"
        />
      </b-form-group>
      <hr>
      <strong>History</strong>
      <b-table-lite
        small
        striped
        :items="commentConfig.history"
      />
      {{ commentConfig.historyFetchMessage }}
    </b-modal>
  </div>
</template>

<script>
import Row from './row'
import printable from './printable'
import StringFilter from './StringFilter'
import BooleanFilter from './BooleanFilter'
import NumberFilter from './NumberFilter'
import DateFilter from './DateFilter'
import axios from '@/libs/axiosSetup'
import axiosLib from 'axios'
import { debounce } from "lodash";
import vuexstore from '@/libs/vuexstore'
import { Grid } from 'ag-grid-community';
import 'ag-grid-enterprise';

export default {
  components: {
    printable,BooleanFilter,
    StringFilter,
    NumberFilter,
    DateFilter,
    Row

  },
  props: {
    initialFilters: { type: Object, default: () => { return {} } },
    enablePrint: { type: Boolean, default: true },
    enablePrintButton: { type: Boolean, default: true },
    hasActions: { type: Boolean, default: true },
    hasExpandCollapse: { type: Boolean, default: false },
    enableExportDownloadButton: { type: Boolean, default: true },
    hasHistory: { type: Boolean, default: true },
    enabledDarkMode: { type: Boolean, default: false },
    enabledApiDefinedFieldVisibile: { type: Boolean, default: false },
    enableTotals: { type: Boolean, default: true },
    enableTitleLink: { type: Boolean, default: false },
    hideTitleHeader: { type: Boolean, default: false },
    fullyExpandedCells: { type: Boolean, default: false },
    disabledAllBadges: { type: Boolean, default: false },
    emptyRowEntry: { type: Boolean, default: false },
    groupIncludeTotalFooter: { type: Boolean, default: true },
    enableFloatingFilter: { type: Boolean, default: true },
    expandGroupingByDefault: { type: Boolean, default: false },
    stopColumnMovable: { type: Boolean, default: false },
    legacyGrid: { type: Boolean, default: false },
    fields: { type: Array, default: () => [] },
    filters: { type: Object, default: () => { return {} } },
    pageUrl: { type: String, default: undefined },
    agRowHeight: { type: Number, default: 25 },
    title: { type: String, default: '' },
    overlayMessageNoRows: { type: String, default: 'No Rows To Show' },
    rowGroupPanelShow: { type: String, default: 'always' },
    pageParams: { type: Object, default: () => { return {} } },
    emptyGridData: { type: Array, default: () => [] },
    maxRows: {
      validator: function (n) {
        if (typeof (n) === 'number' && n > 1) {
          return true
        }
        if (n === null) { return true }
        return false
      },
      default: null
    }
  },
  data() {
    var filters = {}
    for (var i = 0; i < this.fields.length; ++i) {
      let obj
      switch (this.fields[i].filter) {
      case 'NumberFilter':
        obj = NumberFilter; break;
      case 'DateFilter':
        obj = DateFilter; break;
      case 'BooleanFilter':
        obj = BooleanFilter; break;
      default:
        obj = StringFilter;
      }
      filters[this.fields[i].key] = {
        colIndex: i,
        text: null,
        type: this.fields[i].filter,
        op: '=',
        obj: obj,
        sortIcon: 'filter',
        options: this.fields[i].options || []
      }
    }
    return {
      gridFilters: filters,
      commentModalVisible: false,
      commentValid: null,
      commentConfig: {},
      footerValues: {},
      pages: {},
      pageBy: 5000,
      pageFetchRequstedFor: {},
      apiCallId: null,
      loading: true,
      windowHeight: 1080,
      tableContainerStyle: '',
      paginationListComplete: false,
      paginationNextPage: 0,
      fetchInProgress: false,
      hoverExpandOn: false,
      filterOptions: {},
      sortKey: null,
      sortDir: 'justify',
      apiFieldVisible: { 'visible': null },
      completeGetList: [],
      agGridId: `agGrid-${Math.floor(Math.random() * 1000000)}`,
      gridOpts: [],
      gridOptions: null
    }
  },
  computed: {
    checkIfAgGridLicenseIsSet:function(){
      
      if (this.$store.state 
          && this.$store.state.agsk !== undefined 
          && this.$store.state.agsk !== "") {
        return true;
      }
      return false;
    },
    agGridIsNotAllowed() {
      if(this.legacyGrid){
        return true
      }
      const allowedRoutes = ['comparisonsheet',
        'sectionwiseerectionstatus' 
      ];
      return allowedRoutes.includes(this.$router.currentRoute.name);
    },
    items: function () {
      let items = []
      let pageNos = Object.keys(this.pages).map(
        function (n) { return Number(n) }
      );
      pageNos.sort()
      for (let i = 0; i < pageNos.length; ++i) {
        items = items.concat(this.pages[pageNos[i]])
      }
      return items
    },
    largestPageFetched: function () {
      let nos = Object.keys(this.pages);
      let n = -1;
      for (let i = 0; i < nos.length; ++i) {
        let k = Number(nos[i])
        if (k > n) { n = k }
      }
      return n
    },
    gridFilterKeys: function () { return Object.keys(this.gridFilters) },
    tableClasses: function () {
      if (this.enabledDarkMode !== true) {
        var lightVr = 'ag-theme-alpine border smaacAg-grid table-responsive'
        return lightVr
      } else { 
        return 'ag-theme-alpine-dark border smaacAg-grid table-responsive' 
      }
    },
    headFootClasses: function () {
      if (this.enabledDarkMode !== true) {
        var lightVr = 'bg-white'
        return lightVr
      } else { return 'bg-dark' }
    },
    skeletonRows: function () {
      if (this.loading === true) {
        if (this.items.length > 0) { return 3 }
        return 30
      }
      return 0
    },
    noResultsForFilter: function () {
      let msg = 'No results for applied filter.'
      if (this.pageUrl === null) {
        return 'No results available.'
      }
      if (this.loading === true) {
        msg += ' Grid loading is in progress causing some items to'
        msg += ' appear only when loading finishes.'
      }
      return msg
    },
    atLeastOneFilterIsEnabled: function () {
      let enabled = true
      for (let field of this.fields) {
        if (field.filterEnable === false) { enabled = false }
      }
      return enabled
    },
    columnFields: function () {
      let fields = []
      let lastFieldGroup = null
      let borderColors = ['border-info', 'border-secondary']
      let colorIndex = 0
      let computedCss = 'border-left-0 border-right-0 border-top-2 border-thick'
      for (let field of this.fields) {
        if (field.type === 'actionMenu') { continue }
        if (this.fieldHiddenByApi(field)) { continue }
        if (field.fieldGroup !== undefined) {
          if (field.fieldGroup !== lastFieldGroup) {
            colorIndex = (colorIndex + 1) % borderColors.length
          }
          field.computedCss = `
            ${computedCss}
            ${borderColors[colorIndex]}
          `
          lastFieldGroup = field.fieldGroup
        } else {
          lastFieldGroup = null
          colorIndex = 0
          field.computedCss = ''
        }
        fields.push(field)
      }
      return fields
    },
    visibleColumns: function () {
      const visible = [];
      for (let i = 0; i < this.columnFields.length; i++) {
        visible.push(this.columnFields[i].key)
      }
      return visible
    },
    visibleColumnTitles: function () {
      const titles = [];
      for (let i = 0; i < this.columnFields.length; i++) {
        titles.push([this.columnFields[i].key, this.columnFields[i].label])
      }
      return titles
    },
    gridTitle: function () {
      const name = this.$router.currentRoute.name;
      return this.title || this.$router.app.titleMap[name]
    },
    locName: function () {
      return this.$router.currentRoute.name
    }
  },
  watch: {
    'items': function (newItems) {
      const gridFilterKeys = Object.keys(this.gridFilters)
      for (let i = 0; i < gridFilterKeys.length; ++i) {
        let key = gridFilterKeys[i]
        let type = this.gridFilters[key].type || 'StringFilter'
        if (type === 'StringFilter') {
          let opts = new Set()
          for (let item of newItems) {
            opts.add(item[key])
          }
          let options = [].concat(this.gridFilters[key].options)
          let knownOptions = {}
          for (let i = 0; i < options.length; ++i) {
            knownOptions[options[i].value] = null
          }
          for (let i = 0; i < opts.length; ++i) {
            if (
              opts[i] === null
              || opts[i] === undefined
              || opts[i] in knownOptions
            ) { continue }
            options.push({ value: opts[i], text: opts[i] })
          }
          this.filterOptions[key] = options
        }
      }
      this.$emit('items-changed', { 'items': newItems });
    },
    pageUrl: function () { this.reloadGrid() },
    pageParams: function (current, old) {
      if (typeof (current) !== typeof (old)) {
        this.reloadBasedOnGrid()
        return
      }
      if (JSON.stringify(current) !== JSON.stringify(old)) {
        this.reloadBasedOnGrid()
        return
      }
    }
  },
  created() {
    const el = this
    el.reloadGrid = debounce(el.reloadGrid_, 200)
    el.fetchPage = debounce(el.fetchPage_, 200)
    el.fetchPage(0)
    window.addEventListener('resize', el.resizeGrid)
    el.$nextTick(function () { el.resizeGrid() })
  },
  mounted() {
    if(this.agGridIsNotAllowed === false 
      && this.checkIfAgGridLicenseIsSet){
      var el = this;
      el.$nextTick(() => {
        const eGridDiv = document.getElementById(el.agGridId);
        let emptyRowData = [];
        let inputRow = {};
        const onCellEditingStopped = (params)=> {
          var rowIndex = params.rowIndex;
          var rowData = params.data;
          if(el.emptyRowEntry){
            if(params.node.isRowPinned()){
              return;
            }
            else{el.$emit('agRowChanged',rowIndex, rowData)}
          }
          else{
            el.$emit('agRowChanged',rowIndex, rowData)
          }
          if (isPinnedRowDataCompleted(params)) {
            setRowData([...emptyRowData, inputRow]);
            setInputRow({});
          }
        }
        const columnDefs = [];
        for (let i = 0; i < el.fields.length; i++) {
          const columnDef = {
            ...el.fields[i],
            headerName: el.fields[i].label,
            field: el.fields[i].key
          };
          if (el.fields[i].filter === 'agDateColumnFilter') {
            columnDef.filterParams = { comparator };
          }
          if (el.fields[i].dataType === 'attachment') {
            columnDef.width = 80;
            columnDef.minWidth = 80;
          }
          if(el.stopColumnMovable){
            columnDef.lockVisible = true,
            columnDef.cellClass = 'locked-visible'
          }
          if(!el.enableFloatingFilter){
            columnDef.floatingFilter = false
          }
          columnDefs.push(columnDef);
        }
        function setRowData(newData) {
          var existingRows = [];
          gridOptions.api.forEachNode(function (node) {
            existingRows.push(node.data);
          });

          if (Array.isArray(newData)) {
            var uniqueNewData = newData.filter(function (newRow) {
              return !existingRows.some(function (existingRow) {
                return JSON.stringify(existingRow) === JSON.stringify(newRow);
              });
            });
            if (uniqueNewData.length > 0) {
              var updatedRows = existingRows.concat(uniqueNewData);
              emptyRowData = updatedRows;
              el.$emit('agRowChangedForEmptyGrid', updatedRows);
            }
          }
          gridOptions.api.setRowData(emptyRowData);
        }
        document.addEventListener('click', function(event) {
          if(Object.keys(inputRow).length === 0){
            return;
          }
          if (event.target.id === 'addRow') {
            setRowData([...emptyRowData, inputRow]);
            setInputRow({});
          }
        });
        function setInputRow(newData) {
          inputRow = newData;
          gridOptions.api.setPinnedTopRowData([inputRow]);
        }
        const gridOptions = {
          rowData: null,
          rowClass: 'showCursor',
          headerHeight: 25,
          floatingFiltersHeight: 27,
          rowHeight: el.agRowHeight,
          columnDefs: columnDefs,
          onCellEditingStopped:onCellEditingStopped,
          onFirstDataRendered:onFirstDataRendered,
          getRowStyle: ({ node }) =>
            node.rowPinned ? { 'font-weight': 'bold',
              'font-style': 'italic' } : 0,
          defaultColDef: {
            filter: 'agTextColumnFilter',
            sortable: true,
            resizable: true,
            flex: 1,
            minWidth: 150,
            floatingFilter: true,
            enableValue: true,
            suppressAggFuncInHeader: true,
            enableRowGroup:true,
            singleClickEdit : true,
            cellClassRules: {
              'editable-cell': params => params.colDef.editable
            },
            cellRenderer: (params) => {
              var value = params.value
              var colDef = params.colDef
              if(el.emptyRowEntry){
                if(params.node.isRowPinned()){
                  if(colDef.tag === 'AddRowButton'){
                    return `<button type="button" 
                    class="btn btn-secondary btn-sm" 
                    id="addRow"><i class="bi bi-plus" 
                    id="addRow"></i></button>`;
                  }
                }
                if(colDef.tag === 'AddRowButton'){
                  return ''
                }
                if(colDef.cellEditor === 'agSelectCellEditor'){
                  return value
                }
                if(value === undefined){
                  return params.valueFormatted
                }
                else { return value}
              }
              if (colDef.dataType === "attachment") {
                if (value === undefined){
                  return ''
                }
                return `
                  <span class="badge badge-pill badge-light">
                    ${value}<i class="bi bi-paperclip text-info" 
                    style="font-size:15px"></i></span>
                `;
              }
              else if(colDef.tag === "checkbox"){
                var input = document.createElement("input");
                input.type = "checkbox";
                input.checked = value;
                input.addEventListener("change", function(event) {
                  var rowIndex = params.node.rowIndex;
                  var rowData = params.node.data;
                  let fieldName = colDef.key
                  rowData[fieldName] = event.target.checked;
                  el.$emit('agRowChanged',rowIndex, rowData)
                });
                return input;
              }
              else if(colDef.aggFunc === "sum"){
                let val = Number(value);
                if (isNaN(val)) {
                  return 0;
                } else { 
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');}}
              else {
                return value;
              }
            }
          },
          groupIncludeFooter: el.groupIncludeTotalFooter,
          groupIncludeTotalFooter: el.groupIncludeTotalFooter,
          autoGroupColumnDef: {
            minWidth: 300,
            cellRendererParams: {
              footerValueGetter: (params) => {
                const isRootLevel = params.node.level === -1;
                if (isRootLevel) {
                  return 'Grand Total';
                }
                return `Sub Total (${params.value})`;
              }
            }
          },
          overlayNoRowsTemplate:el.overlayMessageNoRows,
          rowGroupPanelShow:el.rowGroupPanelShow,
          enableRangeSelection: true,
          rowSelection: 'multiple',
          animateRows: true,
          enableCharts: true,
          chartToolPanelsDef: {
            defaultToolPanel: 'settings',
            settingsPanel: {
              chartGroupsDef: {
                pieGroup: ['doughnut', 'pie'],
                columnGroup: ['stackedColumn', 'column', 'normalizedColumn'],
                barGroup: ['bar']
              }
            }
          },
          statusBar: {
            statusPanels: [
              { statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left' },
              { statusPanel: 'agTotalRowCountComponent', align: 'center' },
              { statusPanel: 'agFilteredRowCountComponent' },
              { statusPanel: 'agSelectedRowCountComponent' },
              { statusPanel: 'agAggregationComponent' }
            ],
            statusPanelParams: {
              aggFuncs: ['min', 'max', 'avg']
            }
          },
          excelExport: true,
          onCellClicked: params => {
            if(params.data===undefined || params.colDef.noActionOnClicked){
              return;
            }
            if (params.colDef.dataType === "attachment") {
              el.$emit('rowBadgeClicked', params.data)
              return;
            }
            if (params.column.isCellEditable(params)) {
              return;
            }
            el.$emit('rowClicked', params.data)
          }
        };
        function comparator  (filterLocalDateAtMidnight, cellValue) {
          var dateParts = cellValue.split('/');
          var cellDate = new Date(Number(dateParts[2]),
            Number(dateParts[1]) - 1, Number(dateParts[0]));
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        }
        function onFirstDataRendered(params) {
          params.columnApi.autoSizeColumns();
        }
        function createPinnedCellPlaceholder({ colDef }) {
          return 'Add '+colDef.field + '...'
        }

        function isPinnedRowDataCompleted(params) {
          if (params.rowPinned !== 'top') return;
          const columnDefsToCheck = 
          columnDefs.filter(def => def.tag !== 'AddRowButton');
          return columnDefsToCheck.every((def) => inputRow[def.field]);
        }
        if (el.emptyRowEntry) {
          gridOptions.defaultColDef.valueFormatter = (params) =>
            params.value ? params.value : createPinnedCellPlaceholder(params);
          gridOptions.defaultColDef.valueParser = (params) =>
            params.newValue === createPinnedCellPlaceholder(params) 
              ? null : params.newValue;
          gridOptions['pinnedTopRowData'] = [inputRow];
        }
        el.gridOpts = gridOptions
        new Grid(eGridDiv, gridOptions);
        el.fetchApi(gridOptions)
      })
    }
  },
  methods: {
    reloadBasedOnGrid:function(){
      if(this.agGridIsNotAllowed===true){
        this.reloadGrid()
      }else{
        if (this.gridOpts.api) {
          this.gridOpts.api.setFilterModel(null);
          this.gridOpts.api.onFilterChanged();
        }
        this.fetchApi(this.gridOpts)
      }
    },
    csvExport: function () {
      this.gridOpts.api.exportDataAsCsv();
    },
    excelExport: function () {
      this.gridOpts.api.exportDataAsExcel();
    },
    fetchApi: function (gridOptions) {
      const el = this
      gridOptions.api.showLoadingOverlay();
      if (this.pageUrl === null){gridOptions.api.setRowData([]); return}
      let url = el.pageUrl
      if (el.pageUrl === undefined) { url = '/main/s/' + el.locName + '/list' }
      var params = {
        params: {
          'page_by': el.pageBy,
          ...(el.pageParams || {})
        }
      };
      axios.get(url, params)
        .then(response => {
          gridOptions.api.setRowData(response.data.get_list.result);
          if(el.expandGroupingByDefault){
            gridOptions.api.expandAll()
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    fieldHiddenByApi: function (field) {
      const visible = this.apiFieldVisible.visible
      if (visible === null || visible === undefined) { return false }
      return visible.indexOf(field.key) === -1
    },
    showCommentModal: function (row) {
      const el = this;
      let key = row.id
      el.commentConfig = {
        key: key,
        historyFetchMessage: 'Fetching comment history...',
        history: []
      }
      el.$root.$emit('nav-spinner-on');
      const url = '/main/s/' + el.locName + '/comment'
      axios
        .get(
          url,
          { params: { key: key } }
        ).then(function (response) {
          el.commentConfig.history = response.data.get_comment
          el.commentConfig.historyFetchMessage = 'End of comments.'
        }).catch(() => { }
        ).finally(function () { el.$root.$emit('nav-spinner-off') })
      this.commentConfig.content = ''
      this.commentValid = null
      el.commentModalVisible = true
    },
    commentModalOk: function (bvModalEvt) {
      const el = this;
      if (
        el.commentConfig.content === null
        || el.commentConfig.content === undefined
        || el.commentConfig.content === ''
      ) {
        bvModalEvt.preventDefault()
        el.commentValid = false
        return
      }
      const data = { comment: el.commentConfig.content }
      el.$root.$emit('nav-spinner-off');
      const url = '/main/s/' + el.locName + '/comment'
      axios.post(
        url,
        data,
        { params: { key: el.commentConfig.key } }
      ).then(
        function (response) {
          console.log(response)
          el.$root.$emit('nav-spinner-off');
        }).catch(() => { }
      ).finally(function () { el.$root.$emit('nav-spinner-off') })
      // Reset comment info
      el.commentConfig = {};
    },
    onScroll: function (e, pos) {
      if (this.$refs.gridcontainer === undefined) { return }
      if (this.paginationListComplete === true) { return }
      if (pos.scrollTop > this.$refs.gridcontainer.scrollHeight * 0.5) {
        if (this.pageFetchRequstedFor[this.largestPageFetched + 1] !== true) {
          this.pageFetchRequstedFor[this.largestPageFetched + 1] = true
          this.fetchPage(this.largestPageFetched + 1)
        }
      }
    },
    fetchPage: function () {
      // We initialize this function in `created`
      // since we require a per-instance debounce instead of
      // a per component debounce.
      // See https://gitlab.smaac.in/smaac/webapp
      // /-/merge_requests/503#note_649590055
      throw Error("This function should be overridden")
    },
    fetchPage_: function (pageno, uuid = undefined) {
      if (pageno === undefined) {
        throw Error('Fetching undefined page number')
      }
      if (this.pageUrl === null) { this.loading = false; return }
      const el = this;
      if (el.apiCallId !== null) {
        el.apiCallId.cancel();
      }
      el.apiCallId = axiosLib.CancelToken.source()
      el.loading = true;
      // Generate page query params
      let params = el.getListApiCallParams(pageno, uuid)
      params['cancelToken'] = el.apiCallId.token
      let url = el.pageUrl
      if (el.pageUrl === undefined) { url = '/main/s/' + el.locName + '/list' }
      // Fetch the data
      axios
        .get(url, params)
        .then(function (response) {
          if (
            response.data.ok === false
            && response.data.reason === "unauthorized"
          ) {
            el.$root.$emit('nav-trigger-logout');
            el.$router.push({ name: 'login' });
            return
          }


          if (response.data.retry === true) {
            setTimeout(function () {
              el.fetchPage(pageno, response.data.uuid);
            }, 1000);
          } else {
            el.$set(el.pages, pageno, response.data.get_list.result)
            el.$set(el.apiFieldVisible, 'visible', response.data.col_visible)
            el.pageFetchRequstedFor[pageno] = false
            el.$emit('complete-get-list', response.data.get_list);
            el.paginationListComplete = response.data.get_list.isComplete
            if (response.data.get_totals !== undefined) {
              el.footerValues = response.data.get_totals.result
              el.$emit('footer-values-changed', el.footerValues);
            }
            el.loading = false
          }
        }).catch((err) => { if (!axiosLib.isCancel(err)) { 
          console.error(err) 
        } }
        ).finally(function () { el.apiCallId = null })


    },
    reloadGrid: function () {
      // We initialize this function in `created`
      // since we require a per-instance debounce instead of
      // a per component debounce.
      // See https://gitlab.smaac.in/smaac/webapp
      // /-/merge_requests/503#note_649590055
      throw Error("This function should be overridden")
    },
    reloadGrid_: function () {
      const el = this
      el.loading = false
      el.pages = {}
      el.fetchPage(0)
    },
    printPage: function () { window.print(); },
    getListApiCallParams: function (pageno, uuid) {
      const el = this
      var gridFiltersEncoded = {}
      // copy initial into encoded
      let initKeys = Object.keys(el.initialFilters)
      for (var i = 0; i < initKeys.length; ++i) {
        gridFiltersEncoded[initKeys[i]] = el.initialFilters[initKeys[i]]
      }
      // Override filters based on what the user has set
      for (i = 0; i < el.gridFilterKeys.length; ++i) {
        let f = el.gridFilterKeys[i]
        if (
          el.gridFilters[f].val !== undefined
          && el.gridFilters[f].val !== null
          && el.gridFilters[f].val !== ''
        ) {
          gridFiltersEncoded['o.' + f] = el.gridFilters[f].op
          gridFiltersEncoded['v.' + f] = el.gridFilters[f].val
        }
        if (el.gridFilters[f].sortIcon !== 'filter') {
          let s = ''
          switch (el.gridFilters[f].sortIcon) {
          case 'sort-up': s = 'u'; break;
          case 'sort-down-alt': s = 'd'; break;
          default: s = '';
          }
          gridFiltersEncoded['s.' + f] = s
        }
      }
      var params = {
        params: {
          page: pageno,
          uuid: uuid,
          'page_by': el.pageBy,
          ...gridFiltersEncoded,
          ...(el.pageParams || {})
        }
      };
      for (var k in el.filters) params.params[k] = el.filters[k];
      return params
    },
    viewCsvDownload: function (downloadId, fmt) {
      const el = this
      axios
        .get(
          '/main/download_view',
          { params: { "download_id": downloadId, 'check': 'y', 'fmt': fmt } }
        ).then(function (response) {
          if (response.data.retry === true) {
            setTimeout(function () {
              el.viewCsvDownload(downloadId, fmt);
            }, 1000);
          } else {
            el.$root.$emit('nav-spinner-off')
            let encoded = encodeURIComponent(downloadId)
            let href = `${axios.defaults.baseURL}/main/download_view?`
            href += `download_id=${encoded}`
            href += `&fmt=${fmt}`
            href += `&apitoken=${vuexstore.state.apitoken}`
            el.$refs.downloadLink.href = href
            el.$refs.downloadLink.click()
          }
        }).catch((err) => { console.error(err) }
        ).finally(function () { })
    },
    downloadFile: function (fmt) {
      const el = this
      el.$root.$emit('nav-spinner-on')
      el.$refs.downloadLink.href = ''
      let url = el.pageUrl
      if (el.pageUrl === undefined) { url = '/main/s/' + el.locName + '/list' }
      let params = el.getListApiCallParams(0, undefined)
      params['params']['visible'] = el.visibleColumns
      params['params']['titles'] = el.visibleColumnTitles
      delete params['cancelToken']
      params['params']['download'] = 'y'
      params['params']['fmt'] = fmt
      axios
        .get(url, params)
        .then(function (response) {
          el.viewCsvDownload(response.data.download_id, fmt)
        }).catch((err) => { console.error(err) }
        ).finally(function () { })
    },
    resizeGrid: function () {
      this.windowHeight = window.innerHeight;
      if (this.$refs.gridcontainer === undefined) {
        this.tableContainerStyle = ''
        return
      }
      let h = this.windowHeight
      h = h - this.$refs.gridcontainer.offsetTop - 20
      const rowHeight = 40  // px
      let headerHeight = 1
      if (this.atLeastOneFilterIsEnabled) { headerHeight = 3 }
      if (
        this.maxRows !== null
        && this.maxRows >= 1
        && ((headerHeight + this.maxRows) * rowHeight) < h
      ) { h = (this.maxRows + headerHeight) * rowHeight }
      this.tableContainerStyle = 'height: ' + h + 'px;'
    },
    badgeClicked: function (row, fieldKey) {
      this.$emit('badge-clicked', row, fieldKey)
    },
    buttonClicked: function (row, fieldKey) {
      this.$emit('button-clicked', row, fieldKey)
    },
    showActionEllipsis: function (row) {
      for (let field of this.getBadgeFields(row)) {
        if (row[field.key]) { return false }
      }
      return true
    },
    setNewList: function (items) {
      this.pages = { '0': items }
    },
    rowClicked: function (row) {
      this.$emit('row-clicked', row)
    },
    rowChanged: function (id, key, value, idx) {
      this.$set(this.items[idx], key, value)
      this.$emit('change', this.items[idx], key, idx)
    },
    changeSort: function (key) {
      let filter = this.gridFilters[key]
      let icon
      switch (filter.sortIcon) {
      case 'filter': icon = 'sort-up'; break;
      case 'sort-up': icon = 'sort-down-alt'; break;
      case 'sort-down-alt': icon = 'filter'; break;
      default: icon = 'filter';
      }
      for (let i = 0; i < this.gridFilterKeys.length; ++i) {
        this.gridFilters[this.gridFilterKeys[i]].sortIcon = 'filter'
      }
      this.gridFilters[key].sortIcon = icon
      if (icon === 'filter') { key = null }
      this.sortKey = key
      this.sortDir = icon
      this.reloadGrid()
    },
    filterUpdate: function (key, op, val) {
      let filter = this.gridFilters[key]
      filter.op = op
      filter.val = val
      this.reloadGrid()
    }
  }
}
</script>
<style >
.showCursor {
  cursor: pointer;
}

.smaacAg-grid {
  height: 700px;
}

.ag-theme-alpine {
  --ag-background-color: white;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
}

.ag-status-bar>div {
  height: 20px;
  line-height: 0;
  font-size: 10px;
}

.ag-column-drop-wrapper .ag-column-drop.ag-column-drop-horizontal {
  height: 30px;
}

.ag-cell.editable-cell {
  border: 1px solid rgb(255, 196, 224);
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

table tfoot {
  position: sticky;
  bottom: 0;
}

.border-thick {
  border-width: thick;
}
</style>
