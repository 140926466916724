import axios from 'axios'
import vuexstore from '@/libs/vuexstore'
import * as Sentry from "@sentry/vue"

function beforeRequest(x) {
  // to avoid overwriting if another interceptor
  // already defined the same object (meta)
  x.meta = x.meta || {}
  x.meta.requestStartedAt = new Date().getTime();
  if (x.params === undefined) { x.params = {} }
  x.params['apiid'] = Math.random().toString(36)
  if (!('apitoken' in x.params)) {
    x.params['apitoken'] = vuexstore.state.apitoken
  }
  Sentry.setTag("api-uuid", x.params['apiid']);
  Sentry.setTag("username", vuexstore.state.userName);
  Sentry.setTag("userid", vuexstore.state.id);
  Sentry.setTag("api-url", x.url);
  return x;
}
axios.interceptors.request.use(beforeRequest, null, { synchronous: true })
let baseURL = 'http://localhost:5000'

if (process.env.VUE_APP_ENV === 'prod') {
  baseURL = 'https://api-prod.smaac.in'
}
if (process.env.VUE_APP_ENV === 'test') {
  baseURL = 'https://api-test.smaac.in'
}


const instance = axios.create({
  baseURL: baseURL,
  timeout: 120000,
  withCredentials: true,
  params: {} // do not remove this, its added to add params later in the config
});

instance.interceptors.request.use(beforeRequest, null, { synchronous: true })

export default instance
