<template>
<!-- eslint-disable -->
  <div>
    <b-container
      v-for="(item,index) in loadedItems"
      :key="item.id"
      class="qrcodePrintSize"
    >
      <b-row class="qrStyle">
        <b-col
          class="mt-2"
          cols="4"
        >
          SCAN QR CODE
          <div v-html="generateQR(item.GUid)" ></div>
        </b-col>
        <b-col>
          <b-row class="mt-3 mb-n2">
            <b-col>
            <!-- we are using this img in Print PDF -->
            <img
              src="https://smaacbucketpublic.s3.ap-south-1.amazonaws.com/JPPLLogo.png"
              width="120px"
              alt="jppl"
            >
            </b-col>
            <b-col class="mb-n5" v-show="item.scannedby_userprofile_id !== null">
              <span class="close-btn">&times;</span>
            </b-col>
            <b-col class="text-right">
              <h5>{{ index + 1 }}</h5>
            </b-col>
          </b-row>
          <hr>
          <b-row class="item">
            <b-col>
            <h5>
              <b>
                {{ item.Name }}
              </b>
            </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
            <h5 class="type">
              <small><b>For {{ item.Accounttype }}</b></small>
            </h5>
            </b-col>
          </b-row>
        </b-col>
        <b-row class="ml-2 mr-2 mt-1">
          <b-col>
            <p>
             <small><span>T & C Apply*</span><br><div>(Powered by Smaac)</div></small>
            </p>
          </b-col>
          <b-col cols="6">
            Download
            "JPPL App" from
            <img
              src="https://smaacbucketpublic.s3.ap-south-1.amazonaws.com/playstoreapp.png"
              style="width: 100%;max-width: 200px;"
              alt="store"
            >
          </b-col>
          <b-col class="m-0">
            <img
              src="https://smaacbucketpublic.s3.ap-south-1.amazonaws.com/CashSticker.jpeg"
              alt="store"
               style="width: 100%;max-width: 95px;"
            >
            <div class="pointsDisplay ml-2 mt-n1"><strong>{{item.Points}}</strong></div>
           </b-col>
        </b-row>
      </b-row>
    </b-container>
  </div>
<!-- eslint-enable -->
</template>
<script>
import qrcode from '@/libs/qrcode'

export default {
  props: {
    fields: { type: Array, default: () => {return []} },
    loadedItems: { type: Array, default: () => {return []} }
  },
  methods:{
    generateQR:function(guid){
      const QR = qrcode(4,'L')
      QR.addData(this.$siteUrl +"?guid="+ guid)
      QR.make();
      return QR.createImgTag(4,1);
    }
  }
}
</script>
<style>

@media print {
  html, body {
      height:100%;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }

@page {
    size: 4in 2in ;
}
  .close-btn {
      font-size: 50px;
      font-weight: bold;
      color: red;
      -webkit-print-color-adjust: exact;
  }
  .qrcodePrintSize{
      width: 5.55in;
      max-width: 5.55in;
      height: 3in;
      top:0px;
      bottom:0px;
      margin: 0;
      page-break-after: always;
    }
  .qrStyle{
      border: black solid;
      height: 3in;
      width:5.55in;
      max-width:5.55in;
      margin:0;
      padding: 1px;
    }
  .type{
      background-color:black !important;
      padding: 3px;
      color: white;
      -webkit-print-color-adjust: exact;
      max-width: 50%;
    }
  .item{
      max-width: 300px;
      max-height: 50px;
    }
  .pointsDisplay{
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 25px;
      transform: translate(-50%, -50%);
      -webkit-print-color-adjust: exact;
    }
}
</style>