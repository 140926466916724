<template>
  <div class="minWidthForCounter">
    <div
      class="input-group input-group-sm"
      :disabled="disabled"
    >
      <div class="input-group-prepend z-zero">
        <b-button
          :disabled="disabled"
          @click="change(-1)"
        >
          -
        </b-button>
      </div>
      <b-form-input
        v-model="val"
        :number="true"
        class="text-center"
        :disabled="disabled"
        @change="change()"
      />
      <div class="input-group-append z-zero">
        <b-button
          :disabled="disabled"
          @click="change(1)"
        >
          +
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {type: [Number,String], default: null},
    disabled: {type: Boolean, default: false}
  },
  data(){
    return {val: this.value}
  },
  methods: {
    change: function(diff=0){
      this.val= this.val + diff
      this.$emit('change', this.val)
    }
  }
}
</script>
<style scoped>
.minWidthForCounter{
  min-width: 90px;
}
.z-zero{z-index: 0;}
</style>
