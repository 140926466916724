<template>
  <div>
    <b-card
      bg-variant="dark"
      text-variant="white"
      :title="details[config.titleKey]"
      class="text-center"
    > 
      <b-card-text class="text-center">
        <b-row v-if="config.subtitleKey">
          <b-col>
            {{ config.subtitleLabel }} {{ details[config.subtitleKey] }}
          </b-col>
        </b-row>
        <b-row class="text-secondary">
          <b-col>{{ config.leftLabel }}</b-col>
          <b-col>{{ config.centerLabel }}</b-col>
          <b-col>{{ config.rightLabel }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col>{{ details[config.leftKey] }}</b-col>
          <b-col>{{ details[config.centerKey] }}</b-col>
          <b-col>{{ details[config.rightKey] }}</b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <hr>
    <b-container>
      <DetailRow
        v-for="row in config.rows"
        :key="row.key"
        :left="row.left"
        :right="details[row.right] || row.right"
        :max="details[row.max]"
        :value="details[row.value]"
        :variant="row.variant"
        :heading="row.heading"
      />
    </b-container>
  </div>
</template>

<script>
import DetailRow from './DetailRow.vue'

export default {
  components: { DetailRow },
  props: {
    config: {
      type: Object,
      default: function (){
        return {
          baseUrl: '',
          titleKey: '',
          subtitleLabel: '',
          subtitleKey: '',
          leftLabel: '',
          leftKey: '',
          centerLabel: '',
          centerKey: '',
          rightLabel: '',
          rightKey: '',
          rows: []
        }
      }
    },
    detailsInit: { type: Object, default: () => {}}
  },
  data() {
    const details = this.detailsInit || {};
    return {
      inProgress: false,
      details: details
    }
  },
  watch: {
    detailsInit: function (val){
      this.details = val;
    }
  }
}
</script>
