<template>
  <b-row>
    <component
      :is="row.tag || 'span'"
      v-if="!row.badge"
    >
      <span>{{ row.label }}</span>
      <span :class="item[row.key + '.class']">
        {{ item[row.key] }}
      </span>
      <span>{{ row.suffix }}</span>
    </component>
    <b-badge
      v-if="row.badge && !row.cols && item[row.key]"
      pill
      :style="item[row.styleVariantKey]"
      :variant="item[row.variantKey]"
    >
      {{ item[row.key] }}
    </b-badge>
    <div v-if="row.badge && row.cols">
      <b-badge
        v-for="badge in visibleBadges(row.cols, item)"
        :key="badge.key"
        pill
        :style="item[badge.styleVariantKey]"
        :variant="item[badge.variantKey]"
      >
        {{ item[badge.key] }}
      </b-badge>
    </div>
  </b-row>
</template>
<script>
export default {
  props: {
    row: {type: Object, default: () => {}},
    item: {type: Object, default: () => {}}
  },
  methods: {
    visibleBadges: function (cols, item){
      let badges = []
      for(let col of cols){
        if(item[col.key]){ badges.push(col) }
      }
      return badges
    }
  }
}
</script>
