<template>
  <div>
    <b-modal
      v-model="blockallaccessmodal"
      centered
      title="Block all access"
    >
      <b-form-input
        v-model="blocktext"
        placeholder="Please type 'STOP' to block all access."
      />
      <template #modal-footer>
        <b-button
          variant="danger"
          @click="blockallaccess"
        >
          Block
        </b-button>
        <b-button
          variant="dark"
          @click="blockallaccessmodal = false"
        >
          Close
        </b-button>
      </template>
    </b-modal>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      fixed="top"
      :style="youAreInTestDB"
    >
      <b-navbar-nav
        v-if="!loginRequired"
        class="mr-2"
        :class="singleModule"
      >
        <b-button
          v-b-toggle.sidebar-backdrop-footer
          size="sm"
          variant="dark"
          class="ml-n2 p-0"
        >
          <span class="navbar-toggler-icon" />
        </b-button>
        <b-sidebar
          id="sidebar-backdrop-footer"
          backdrop-variant="dark"
          backdrop
          shadow
        >
          <template
            v-if="!loginRequired"
            #footer=""
          >
            <div class="m-2 ml-4">
              Contact us on
              <b-icon-envelope-fill /> -
              <b-link
                :href="supportMail"
              >
                support@smaac.in
              </b-link>
            </div>
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
              <small
                class="mr-auto text-light"
              >{{ version }} </small>
              <b-button
                v-show="$store.state.admin &&
                  $store.state.erpType !== 'rewards'"
                size="sm"
                variant="danger"
                class="mr-2"
                @click="blockallaccessmodal = true"
              >
                Block access
              </b-button>
              <b-button
                size="sm"
                :href="logoutUrl"
                @click="logout"
              >
                Logout
                <b-icon-box-arrow-in-right />
              </b-button>
            </div>
          </template>
          <b-card
            :title="cname"
          >
            <b-card-text>
              <b-badge v-show="$store.state.admin">
                Admin
              </b-badge>
              {{ $store.state.email || '' }}
            </b-card-text>
          </b-card>
          <div
            v-for="section in sections"
            :key="section.val"
          >
            <b-button
              v-b-toggle="section.val"
              class="text-left"
              variant="light"
              block
            >
              <span class="when-opened">
                <b-icon
                  icon="triangle-fill"
                  scale=".5"
                  rotate="180"
                  aria-hidden="true"
                /></span>
              <span class="when-closed">
                <b-icon
                  icon="triangle-fill"
                  scale=".5"
                  rotate="90"
                  aria-hidden="true"
                /></span>
              <span class="ml-2">{{ section.text }}</span>
            </b-button>
            <b-collapse
              :id="section.val"
              :visible="section.visible"
            >
              <b-button
                v-for="module in section.items"
                :key="module.name"
                size="md"
                block
                variant="light"
                :to="{ name: module.name, params: {} }"
              >
                <p class="text-left ml-5 mb-0">
                  {{ module.text }}
                </p>
              </b-button>
            </b-collapse>
          </div>
        </b-sidebar>
      </b-navbar-nav>
      <b-navbar-brand
        to="/"
        class="text-left mr-auto"
      >
        <img
          :class="singleModule"
          src="../assets/images/smaac.png"
          width="25px"
          alt="smaac"
        >
        <b-spinner
          v-if="spinner"
          class="ml-2"
          small
          label="Spinning"
        />
        <b-button
          v-if="updateExists"
          variant="warning"
          size="sm"
          class="ml-2"
          href="#"
          @click="$emit('refresh')"
        >
          Click to update
        </b-button>
      </b-navbar-brand>
      <span
        v-if="showDropdown"
        class="mr-auto"
      >
        <b-button
          variant="dark"
          size="sm"
          style="font-size:0.7rem"
          @click="showDropdownFilterModal"
        > {{ selectedDropdownText || 'Select filters' }}
          <b-icon-caret-down-fill scale=".7" />
        </b-button>
      </span>
      <div
        v-if="showFilters"
        class="ml-auto pr-1"
      >
        <b-button
          variant="dark"
          size="sm"
          class="m-0 p-0"
          @click="showFilterModal"
        >
          <b-icon-search
            class="text-info"
            scale=".8"
          />
        </b-button>
      </div>
      <div
        v-if="!spinner"
        v-show="showNotifications"
        :class="showFilters ? 'ml-1' : 'mr-n2'"
        style="font-size:50%"
      >
        <b-link :to="notificationLink">
          <b-button
            variant="dark"
            class="m-0 p-0"
          >
            <b-icon-bell
              :class="notifClass"
              scale=".8"
            />
            <b-badge
              v-show="unreadNotificationsCount > 0"
              pill
              class="m-0"
            >
              {{ humanUnreadNotificationsCount }}
              <span class="sr-only">Unread notifications</span>
            </b-badge>
          </b-button>
        </b-link>
      </div>
    </b-navbar>
  </div>
</template>
<script>
import axios from '@/libs/axiosSetup'
import { supportMail } from './constants.js'
export default {
  props: {
    enableNotifications: { type: Boolean, default: false },
    moduleList: { type: Array, default: () => [] },
    locName: { type: String, default: '' },
    updateExists: { type: Boolean, default: false },
    filters: { type: Object, default: () => { } }
  },
  data: function () {
    return {
      blocktext: '',
      blockallaccessmodal: false,
      renderId: Math.random().toString(36),
      selectedDropdownText: null,
      selectedOption: null,
      loginRequired: true,
      filtersEnabled: {},
      spinner: false,
      cname: this.$store.state.company || '',
      showFilters: false,
      showDropdown: false,
      spinStart: null,
      unreadNotificationsCount: 0,
      notifClass: 'text-info',
      logoutUrl: axios.defaults.baseURL + '/main/logout',
      supportMail
    }
  },
  computed: {
    youAreInTestDB: function () {
      if (this.$store.state.isTestDb === true) {
        return 'border-bottom: 10px solid #28a745;'
      }
      return ''
    },
    showNotifications: function () {
      if (this.enableNotifications === false) { return false }
      if (this.loginRequired === true) { return false }
      return true
    },
    humanUnreadNotificationsCount: function () {
      if (this.unreadNotificationsCount > 99) { return '99+' }
      return this.unreadNotificationsCount
    },
    version: function () {
      let ver = this.$store.state.gitversion
      if (ver.indexOf('.') === -1) {
        ver = ver.slice(0, 8)
      }
      return ver
    },
    notificationLink: function () {
      return { name: 'notification' }
    },
    filterLink: function () {
      return {
        name: 'Filters',
        params: {
          filters: this.filters,
          locName: this.locName,
          enabled: this.filtersEnabled
        }
      }
    },
    singleModule: function () {
      if (this.$store.state.singleModule === 'true') {
        return 'd-none'
      }
      return '';
    },
    sections: function () {
      const sections = [
        { text: 'Admin', val: 'admin' },
        { text: 'CRM & Sales', val: 'crm' },
        { text: 'Masters', val: 'masters' },
        { text: 'Purchase', val: 'purchase' },
        { text: 'Store', val: 'store' },
        { text: 'Dispatch', val: 'dispatch' },
        { text: 'Project', val: 'project' },
        { text: 'Production', val: 'production' },
        { text: 'Miscellaneous', val: 'miscellaneous' },
        { text: 'Rewards', val: 'rewards', visible: true }
      ]
      let lst = []
      for (var i = 0; i < sections.length; ++i) {
        sections[i].items = this.getSectionItems(sections[i].val)
        if (sections[i].items.length > 0) {
          lst.push(sections[i])
        }
      }
      return lst
    }
  },
  created: function () {
    var el = this;
    if (el.$store.state.loginRequired === 'false') {
      this.loginRequired = false;
    } else {
      this.loginRequired = true;
    }
    this.$root.$on('nav-dropdown-filters-text', function (text) {
      el.selectedDropdownText = text;
    })
    this.$root.$on('nav-filters-enabled', function (enabled) {
      el.filtersEnabled = enabled;
    })
    this.$root.$on('nav-dropdown-filter-on', function () {
      el.showDropdown = true;
    })
    this.$root.$on('nav-dropdown-filter-off', function () {
      el.showDropdown = false;
      el.selectedDropdownText = null
    })
    this.$root.$on('nav-filters-off', function () {
      el.showFilters = false;
    })
    this.$root.$on('nav-filters-on', function () {
      el.showFilters = true;
    })
    this.$root.$on('nav-spinner-off', function () {
      el.spinner = false;
    })
    this.$root.$on('nav-spinner-on', function () {
      el.spinner = true;
    })
    this.$root.$on('nav-trigger-logout', function () {
      el.logout()
    })
    setTimeout(function () {
      el.pollNotifications(el.renderId)
    }, 60000);
  },
  methods: {
    pollNotifications: function (renderId) {
      if (this.enableNotifications === false 
      || this.$store.getters.flagIsEnabled("module_notification")!== true) 
      { return }
      const el = this
      if (el.renderId !== renderId) { return }
      axios
        .get('/main/s/' + this.notificationLink.name + '/unreadcount')
        .then(function (response) {
          if (response.data.get_unreadcount !== undefined) {
            el.unreadNotificationsCount = response.data.get_unreadcount.count
            el.notifClass = response.data.get_unreadcount.variant || 'text-info'
          }
        }).catch((err) => { console.error(err) }
        ).finally(function () {
          setTimeout(function () {
            el.pollNotifications(renderId)
          }, 60000);
        })
    },
    toggleSection: function (section) {
      section.visible = !section.visible
    },
    getSectionItems: function (section) {
      let lst = []
      for (var i = 0; i < this.moduleList.length; ++i) {
        if (this.moduleList[i].section === section) {
          lst.push(this.moduleList[i])
        }
      }
      return lst
    },
    logout: function () {
      this.logoutUrl = (
        this.logoutUrl
        + '?apitoken='
        + this.$store.state.apitoken
      );
      this.$store.commit('clearState')
    },
    showFilterModal: function () {
      this.$root.$emit('emit-filter-modal', true)
    },
    showDropdownFilterModal: function () {
      this.$root.$emit('show-dropdown-filter', true)
    },
    blockallaccess: function () {
      const el = this
      if (el.blocktext !== 'STOP') {
        el.$root.$emit('alert',
          {
            msg: "Please type 'STOP' to block access",
            kind: 'warning'
          });
        return
      }
      el.$root.$emit('nav-spinner-on')
      axios.post('/main/blockallaccess'
      ).then(function (response) {
        if (response.data.ok === true) {
          el.$root.$emit('alert',
            {
              msg: "Access blocked successfully",
              kind: 'success'
            });
        }
      }).catch((err) => { console.error(err) }).finally(function () {
        el.$root.$emit('nav-spinner-off');
        el.blockallaccessmodal = false
      })
    }
  }
}
</script>
<style>
.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}
</style>
