<template>
  <div class="mw100">
    <b-button-toolbar
      class="w-100 mb-1"
    >
      <b-dropdown
        size="sm"
        :text="op"
        class="mr-auto mt-n2 mb-n1"
        variant="outline"
        disabled
      >
        <b-dropdown-item disabled>
          =
        </b-dropdown-item>
      </b-dropdown>
    </b-button-toolbar>
    <b-input-group 
      size="sm" 
      class="mt-n1 mb-n1"
    >
      <dropdown
        v-model="text"
        :options="options"
        size="sm"
        :disabled="disabled"
      />
    </b-input-group>
  </div>
</template>

<script>
import Filter from './Filter'
import dropdown from '@/components/forms/fields/dropdown'
export default {
  components: {dropdown},
  mixins: [Filter],
  data(){
    return {
      options: [
        {text: '', value: null},
        {text: 'true', value: true},
        {text: 'false', value: false}
      ]
    }
  },
  getSelected: function (idx, op, val){
    if(val){ return idx.yes }
    return idx.no
  }
}
</script>
