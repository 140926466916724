<template>
  <div>
    <b-modal
      v-model="salestexModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="salestexModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Client-wise Orders
          </h5>
        </div>
      </template>
      <Grid
        :fields="salestexFields"
        :page-url="salestexUrl"
        :page-params="salestexParams"
      />
    </b-modal>
    <b-modal
      v-model="dispatchtexModalVisible"
      size="xl"
      static
      scrollable
      centered
      no-close-on-backdrop
      content-class="w-100"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="dispatchtexModalVisible = false"
        >
          Close
        </b-button>
      </template>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <h5 class="mr-4">
            Client-wise Dispatch
          </h5>
        </div>
      </template>
      <Grid
        :fields="dispatchtexFields"
        :page-url="dispatchtexUrl"
        :page-params="dispatchtexParams"
      />
    </b-modal>
    <b-container fluid>
      <b-row>
        <b-col sm>
          <b-button
            id="salestex-modal"
            class="d-none"
            @click="showSalestexModal"
          />
          <div
            id="salestex_panel"
            :hx-get="salestex"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col sm>
          <b-button
            id="dispatchtex-modal"
            class="d-none"
            @click="showDispatchtexModal"
          />
          <div
            id="dispatchtex_panel"
            :hx-get="dispatchtex"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm>
          <div
            id="dispatchbar_panel"
            :hx-get="dispatchBar"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
        <b-col sm>
          <div
            id="receivepiechart_panel"
            :hx-get="receivePieChart"
            hx-trigger="load"
            hx-swap-oob="true"
          >
            <b-skeleton-img />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Grid from '@/components/Grid'
export default{
  components: {  Grid },
  data:function(){
    return{
      salestexModalVisible: false,
      dispatchtexModalVisible: false,
      salestex: `${this.$apiUrl}/ssr/dashboardtex/salestex`,
      dispatchtex: `${this.$apiUrl}/ssr/dashboardtex/dispatchtex`,
      dispatchBar: `${this.$apiUrl}/ssr/dashboardtex/dispatchyearlytex`,
      receivePieChart: `${this.$apiUrl}/ssr/dashboardtex/receipttex`,
      salestexFilters: "thismonth",
      dispatchtexFilters: "thismonth",
      triggerGridReloadCounterO: 0,
      triggerGridReloadCounter1: 0
    }
  },
  computed: {
    salestexFields: function () {
      let fields = [
        { label: 'Work Contract No', key: 'WorkContractNo'},
        { label: 'Work Contract Date', key: 'WorkContractDate'},
        { label: 'Party Name', key: 'PartyName'},
        {
          label: 'Qty (Kgs)', key: 'Qty',
          aggFunc: 'sum'
        },
        {
          label: 'Amount', key: 'Amount',
          aggFunc: 'sum'
        }
      ]
      return fields
    },
    dispatchtexFields: function () {
      let fields = [
        { label: 'Invoice No', key: 'InvoiceNo'},
        { label: 'Bill Date', key: 'Billdate'},
        { label: 'Party Name', key: 'PartyName'},
        {
          label: 'Qty', key: 'Qty',aggFunc: 'sum'
        },
        {
          label: 'Amount', key: 'Amount',aggFunc: 'sum'
        }
      ]
      return fields
    },
    dispatchtexParams: function () {
      return {
        "filter": this.dispatchtexFilters,
        "trigger": this.triggerGridReloadCounter1
      }
    },
    salestexParams: function () {
      return {
        "filter": this.salestexFilters,
        "trigger": this.triggerGridReloadCounterO
      }
    },
    salestexUrl: function () {
      if (this.salestexFields === undefined) {
        return null
      }
      return '/main/s/dashboardtex/salestex_list'
    },
    dispatchtexUrl: function () {
      if (this.dispatchtexFields === undefined) {
        return null
      }
      return '/main/s/dashboardtex/dispatchtex_list'
    }
  },
  methods: {
    showDispatchtexModal: function () {
      this.dispatchtexModalVisible = true
      this.triggerGridReloadCounter1 += 1
      this.dispatchtexFilters = document
        .getElementById("dispatchtex-card-select")
        .options[document.getElementById("dispatchtex-card-select")
          .selectedIndex].value
    },
    showSalestexModal: function () {
      this.salestexModalVisible = true
      this.triggerGridReloadCounter0 += 1
      this.salestexFilters = document.getElementById("salestex-card-select")
        .options[document.getElementById("salestex-card-select")
          .selectedIndex].value
    }
  }
}
</script>