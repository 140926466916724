<script>
import { debounce } from "lodash";
export default {
  props: {
    fieldKey: { type: String, default: null },
    disabled: { type: Boolean, default: false }
  },
  data(){
    return {
      op: '=',
      text: null
    }
  },
  watch: {
    'text': function (val){
      this.emitChange( this.fieldKey, this.op, val)
    },
    'op': function (val){
      this.emitChange(  this.fieldKey,val, this.text)
    }
  },
  methods: {
    emitChange: debounce(function (key, val, text){
      this.$emit('change',  key, val, text)
    }, 500)
  }
}
</script>
<style>
.mw150{
  min-width: 150px;
}
.mw110{
  min-width: 110px;
}
.mw100{
  min-width: 100px;
}
.mw50{
  min-width: 50px;
}
</style>
