<template>
  <div
    class="px-0 mx-0 w-100"
    @focusin="focusin"
    @focusout="focusout"
  >
    <b-form-input
      :id="inputId"
      v-model="searchText"
      :disabled="disabled"
      :state="state"
      :placeholder="placeholderText"
      :size="size"
      @keydown="keydown"
    />
    <b-icon-caret-down
      class="inp-caret"
      font-scale="0.9"
      :style="caretStyle"
      @click="toggleDropdown"
    />
    <div
      v-show="isDropdownOpen"
      class="
        border-left border-right
        w-100
        stacked
        border-bottom
        bg-light
        shadow
      "
      :style="dropdownTopShift"
    >
      <div
        :id="'dropdown.' + inputId"
        class="mh200 scroll mw-150"
      >
        <ul class="list-group">
          <li
            v-for="option in filteredOptions"
            :key="option.key"
            :ref="inputId + '.option.' + option.key"
            :class="getOptionClasses(option)"
            @mousedown="optionClicked(option)"
          >
            <span
              v-if="option.value != null"
              class="normal-text"
            >
              {{ option.text }}
            </span>
            <hr
              v-if="option.value == null"
              class="mt-3 mb-2 border-dark"
            >
          </li>
        </ul>
      </div>
      <b-button-group
        v-show="isDropdownOpen"
        v-if="actionButtons.length > 0"
        class="btn w-100 pt-0 pb-0 pl-0 pr-0 mt-0 mb-0"
      >
        <b-button
          v-for="btn in actionButtons"
          :key="btn.key"
          :variant="btn.variant"
          :disabled="btn.disabled"
          data-toggle="tooltip"
          data-placement="bottom"
          :title="btn.tooltip"
          size="sm"
          @mousedown="btn.click"
        >
          {{ btn.text }}
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: null,
      validator: function (prop) {
        return (
          typeof (
            prop === "string"
            || typeof prop === "number"
            || prop === null
            || prop === undefined
          )
        );
      }
    },
    placeholder: { type: String, default: "" },
    size: { type: String, default: "md" },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    state: {
      default: null,
      validator: (prop) => typeof prop === "boolean" || prop === null
    },
    actionButtons: {
      type: Array,
      default: () => {
        // This is here as a reference for an action button
        let x = {
          key: "add",
          text: "Add an option",
          tooltip: "Adds a new entry in some table. This is help text.",
          variant: "outline-primary",
          click: function () {
            console.log("add option clicked");
          }
        };
        delete x.key;
        return [];
      }
    }
  },
  data() {
    return {
      inputId: "input." + Math.random(),
      dropdownTopShift: "top:50px;",
      isDropdownOpen: false,
      searchText: "",
      selectedText: this.getSelectedText(this.value, this.options),
      markedIndex: -1
    };
  },
  computed: {
    markedValue : function (){
      if(this.markedIndex >= 0){
        return this.filteredOptions[this.markedIndex].value
      }
      return null
    },
    caretStyle: function () {
      if (this.state !== null) {
        return "top: 1px;";
      }
      let inpField = document.getElementById(this.inputId);
      let shift = this.size === "sm" ? "10" : "15";
      if (inpField !== null) {
        shift = (inpField.offsetHeight / 2).toFixed(0);
      }
      shift = "top:" + shift + "px;";
      return shift;
    },
    exactMatchOption: function () {
      let fsize = this.filteredOptions.length
      for (var i=0; i < fsize; ++i){
        if (this.filteredOptions[i].exactMatch === true) {
          return this.filteredOptions[i];
        }
      }
      return null;
    },
    placeholderText: function () {
      if (this.isDropdownOpen) {
        return "";
      }
      return String(this.selectedText || this.placeholder);
    },
    filteredOptions: function () {
      let opts = [];
      for (let opt of this.options) {
        let o = opt;
        if (typeof opt !== "object") {
          o = { value: opt, text: opt };
        }
        if (o.value === null) {
          continue;
        }
        o.key = o.value;
        o.exactMatch = false;
        if (this.searchText !== null && this.searchText !== "") {
          if (
            String(o.text)
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) !== -1
          ) {
            opts.push(o);
          }
        } else {
          opts.push(o);
        }
      }
      if (opts.length === 1) {
        opts[0].exactMatch = true;
        opts[0].key = "" + opts[0].key + ":matched";
      }
      opts = [{ text: "—".repeat(100), value: null }].concat(opts);
      return opts;
    }
  },
  watch: {
    options: function (opts){
      this.selectedText = this.getSelectedText(this.value, opts)
    },
    value: function (value) {
      if (value === null) {
        this.optionClicked({ value: null, text: "" });
        return;
      }
      for (let opt of this.options) {
        if (opt.value === value) {
          this.optionClicked(opt);
          break;
        }
      }
    }
  },
  methods: {
    getSelectedText: function(value, options){
      let selectedText = ''
      let val = String(value)
      for(var i=0; i < options.length; ++i){
        if(String(options[i].value) === val){
          selectedText = options[i].text
          break
        }
      }
      return selectedText
    },
    transmitChange: function (value) {
      this.$emit("change", value);
    },
    transmitInput: function (value) {
      this.$emit("input", value);
    },
    getOptionClasses: function (opt) {
      let c = `list-group-item pt-0 pb-1
      clickable no-user-select hover-highlight`;
      if (
        opt.exactMatch === true
        || opt.value === this.markedValue
      ) {
        c += " list-group-item-primary";
      }
      return c;
    },
    keydown: function (event) {
      if (event.code === 'ArrowDown'){
        this.markedIndex += 1
        if(this.markedIndex >= this.filteredOptions.length){
          this.markedIndex =this.filteredOptions.length - 1
        }
      }
      if (event.code === 'ArrowUp'){
        this.markedIndex -= 1
        if(this.markedIndex < -1){ this.markedIndex = 0}
      }
      if(event.code === 'ArrowUp' || event.code === 'ArrowDown'){
        let item = this.filteredOptions[this.markedIndex]

        let ref = this.inputId + '.option.' + item.key
        if(item !== undefined){ item = this.$refs[ref]}
        if(item !== undefined){ item[0].scrollIntoView() }
        if (this.isDropdownOpen === false) {
          this.toggleDropdown();
        }
      }
      if(event.code === "Enter" || event.code === "NumpadEnter"){
        if ( this.exactMatchOption !== null) {
          this.optionClicked(this.exactMatchOption);
        }
        else if ( this.markedIndex >= 0) {
          this.optionClicked(this.filteredOptions[this.markedIndex]);
        }
      }
    },
    focusin: function () {
      if (this.isDropdownOpen === false) {
        this.toggleDropdown();
      }
    },
    focusout: function () {
      if (this.isDropdownOpen === true) {
        if (this.exactMatchOption !== null) {
          this.optionClicked(this.exactMatchOption);
        } else {
          this.searchText = "";
          this.toggleDropdown();
        }
      }
    },
    toggleDropdown: function () {
      let dropdown = document.getElementById("dropdown." + this.inputId);
      dropdown.scrollTo(0, 0);
      let inpField = document.getElementById(this.inputId);
      let shift = "50";
      if (inpField !== null) {
        shift = inpField.offsetHeight;
      }
      this.dropdownTopShift = "top:" + shift + "px;";
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen === true && inpField !== null) {
        this.markedIndex = -1
        inpField.focus();
      }
    },
    optionClicked: function (opt) {
      this.selectedText = opt.text;
      this.searchText = "";
      this.isDropdownOpen = false;
      this.$emit("input", opt.value);
      this.$emit("change", opt.value);
    }
  }
};
</script>
<style>
.stacked {
  position: absolute;
  z-index: 100;
}
.mh200 {
  max-height: 150px;
}
.scroll {
  overflow-y: scroll;
}
.hover-highlight:hover {
  background-color: var(--light);
}
input::placeholder {
  color: var(--gray-dark);
  opacity: 1; /* Firefox */
}
.inp-caret {
  position: absolute;
  right: 1px;
  pointer-events: none;
}
.normal-text {
  font-weight: normal;
}
.mw-150 {
  min-width: 150px;
}
</style>
