<template>
  <div>
    <b-navbar
      class="action-bar mt-0 pt-2 pb-0 mb-0"
      fixed="top"
    >
      <!--spacer-->
      <b-button class="invisible pl-0 pr-0 mr-0 mr-0">
        x
      </b-button>
      <strong class="text-left">
        {{ title }}
      </strong>
      <b-button
        v-show="showView == 'list'"
        v-if="newEnabled == true"
        class="ml-auto"
        size="sm"
        @click="emit('new')"
      >
        📝 New
      </b-button>
      <b-button-group
        v-show="showView == 'dataform'"
        class="ml-auto"
      >
        <b-button
          v-if="backEnabled == true"
          v-show="showView == 'dataform'"
          size="sm"
          @click="emit('back')"
        >
          ⬅️ Back
        </b-button>
        <b-button
          v-if="printEnabled == true"
          v-show="showView == 'dataform'"
          size="sm"
          @click="emit('print-page')"
        >
          🖨️ Print
        </b-button>
        <slot name="btnGroup" />
        <b-button
          v-if="newEnabled == true"
          v-show="showView == 'dataform' && formButtonKind != 'add'"
          size="sm"
          @click="emit('new')"
        >
          📝 New
        </b-button>
        <b-button
          v-show="showView == 'dataform'"
          v-shortkey.once="['ctrl', 's']"
          class="text-capitalize"
          variant="primary"
          size="sm"
          :disabled="formButtonDisabled"
          data-toggle="tooltip"
          title="Ctrl + S"
          @click.stop="emit('form-button')"
          @shortkey="emit('form-button')"
        >
          ✏️ {{ formButtonKind }}
        </b-button>
        <b-dropdown
          v-if="formButtonKind !== 'add'"
          right
        >
          <template #button-content>
            <b-badge
              v-show="awsfileEnabled"
              variant="light"
              class="border clickable"
              size="sm"
              pill
            >
              <b-icon
                icon="paperclip"
                variant="info"
                font-scale="0.9"
              /> {{ awsfileCount }}
            </b-badge>
            <slot name="dropdownButtonContent" />
          </template>
          <b-dropdown-item
            v-show="awsfileEnabled"
            @click="emit('awsfile')"
          >
            <b-icon-paperclip variant="info" />
            Attachments
          </b-dropdown-item>
          <slot name="dropdown" />
          <b-dropdown-item
            v-if="deleteEnabled == true"
            variant="danger"
            :disabled="formButtonDisabled"
            @click="emit('delete')"
          >
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
      <b-button
        v-show="showView == 'dataform'"
        id="help-links"
        size="sm"
        variant="outline-dark"
        class="ml-3"
      >
        <b-icon
          icon="info-circle-fill"
          scale="1"
          variant="primary"
        />
      </b-button>
      <b-popover
        target="help-links"
        triggers="hover"
        placement="bottom"
        class="w-50"
      >
        <template #title>
          Help links for you 🙂
        </template>
        <b-list-group>
          <b-list-group-item
            v-for="(link, index) in videoHelpData"
            :key="index"
          >
            <strong>{{ link.type }}</strong>:
            <b-link
              :href="link.link"
              target="_blank"
              class="text-info"
            >
              {{ link.name }}
            </b-link>
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </b-navbar>
    <div class="action-bar-spacer" />
  </div>
</template>
<script>
import axios from '@/libs/axiosSetup'
export default {
  props: {
    title: { type: String, default: '' },
    slug: { type: String, default: '' },
    showView: { type: String, default: 'list' },
    backEnabled: { type: Boolean, default: true },
    awsfileEnabled: { type: Boolean, default: false },
    awsfileCount: { type: Number, default: 0 },
    printEnabled: { type: Boolean, default: true },
    deleteEnabled: { type: Boolean, default: true },
    newEnabled: { type: Boolean, default: true },
    formButtonKind: { type: String, default: 'add' },
    formButtonDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      videoHelpData: {}
    }
  },
  created() {
    this.videohelplink()
  },
  methods: {
    emit: function (kind) {
      if (this.showView !== 'dataform' && kind === 'form-button') {
        return
      }
      this.$emit('click-' + kind)
    },
    videohelplink: function () {
      const el = this
      axios.get('/main/s/' + this.slug + '/videohelplink')
        .then(function (response) {
          if (response !== undefined) {
            el.videoHelpData = response.data.result
          }
        }).catch((err) => { console.error(err) }
        ).finally(function () {
        })
    }
  }
}
</script>
<style scoped>
.action-bar {
  top: 60px;
  max-width: 960px;
  margin: auto;
  border-bottom: 1px solid lightgray;
  z-index: 10;
  background-color: white;
}

.action-bar-spacer {
  height: 55px;
}
</style>
