<template>
  <div>
    <b-input-group size="sm">
      <b-form-select
        v-model="dateRangeSelected"
        :options="dateRangeOptions"
        class="mt-2"
        size="sm"
        @change="setDateRange"
      />
    </b-input-group>
    <b-input-group size="sm">
      <b-form-datepicker
        id="startDate"
        v-model="startDateSelected"
        class="mt-1"
        :disabled="dateRangeSelected != 'custom'"
        placeholder="Start date"
        today-button
        reset-button
        weekday-header-format="short"
      />
    </b-input-group>
    <b-input-group size="sm">
      <b-form-datepicker
        id="endDate"
        v-model="endDateSelected"
        class="mt-1"
        :disabled="dateRangeSelected != 'custom'"
        placeholder="End date"
        :min="startDateSelected"
        today-button
        reset-button
        weekday-header-format="short"
      />
    </b-input-group>
  </div>
</template>
<script>
export default {
  props: {
    startDate: {type: [String, null], default: null},
    endDate: {type: [String, null], default: null},
    dateRange: {type: [String, null], default: null}
  },
  data: function (){
    return {
      startDateSelected: this.startDate,
      endDateSelected: this.endDate,
      dateRangeSelected: this.dateRange,
      dateRangeOptions: [
        { value: null, text: 'No date selected' },
        { value: 'today', text: 'Today' },
        { value: 'week', text: 'This week' },
        { value: 'month', text: 'This month' },
        { value: 'quarter', text: 'This quarter'},
        { value: 'fyear', text: 'This financial year'},
        { value: 'yesterday', text: 'Yesterday'},
        { value: 'pweek', text: 'Previous week'},
        { value: 'pmonth', text: 'Previous month' },
        { value: 'pquarter', text: 'Previous quarter'},
        { value: 'pfyear', text: 'Previous financial year'},
        { value: 'custom', text: 'Custom date range'}
      ]
    }
  },
  watch: {
    dateRangeSelected: function (value){
      this.$root.$emit('update:dateRange', value);
    },
    startDateSelected: function (value){
      this.$root.$emit('update:startDate', value);
    },
    endDateSelected: function (value){
      this.$root.$emit('update:endDate', value);
    },
    startDate: function (value){
      this.startDateSelected = value
    },
    endDate: function (value){
      this.endDateSelected = value
    },
    dateRange: function (value){
      this.dateRangeSelected = value
    }
  },
  methods: {
    setDateRange: function (){
      var val = this.dateRangeSelected
      if(val === null){
        this.startDateSelected = '';
        this.endDateSelected = '';
        return;
      }
      if(val === 'custom'){ return; }
      var today = new Date();
      if(val === 'today'){
        this.startDateSelected =  today.toISOString().slice(0, 10);
        this.endDateSelected =  today.toISOString().slice(0, 10);
        return
      }
      var previous = false;
      if(val[0] === 'p'){
        val = val.slice(1)
        previous = true;
      }
      if(val === 'week'){
        var weekStart = new Date(today.getTime());
        // week starts on monday but JS counts sunday as 0
        if(previous){
          weekStart.setDate(today.getDate() - today.getDay() - 6)
          today.setDate(today.getDate() - today.getDay())
        } else {
          weekStart.setDate(today.getDate() - today.getDay() + 1)
        }
        this.endDateSelected =  today.toISOString().slice(0, 10);
        this.startDateSelected =  weekStart.toISOString().slice(0, 10);
      }
      if(val === 'month'){
        var monthStart = new Date(today.getTime());
        if(previous){
          today.setDate(0)
          monthStart.setDate(0)
          monthStart.setDate(monthStart.getDate() - monthStart.getDate() + 1)
        } else {
          monthStart.setDate(today.getDate() - today.getDate() + 1)
        }
        this.endDateSelected =  today.toISOString().slice(0, 10);
        this.startDateSelected =  monthStart.toISOString().slice(0, 10);
      }
      if(val === 'quarter'){
        var quarterStart = new Date(today.getTime());
        var mth = today.getMonth();
        var qmonth = null;
        if(mth < 3 && qmonth === null){ qmonth = 0}
        if(mth < 6 && qmonth === null){ qmonth = 3}
        if(mth < 9 && qmonth === null){ qmonth = 6}
        if(mth < 12 && qmonth === null){ qmonth = 9}
        quarterStart.setMonth(qmonth)
        quarterStart.setDate(1)
        if(previous){
          today = new Date(quarterStart.getTime())
          today.setDate(today.getDate() - 1)
          quarterStart.setMonth(quarterStart.getMonth() - 3)
        }
        this.startDateSelected =  quarterStart.toISOString().slice(0, 10);
        this.endDateSelected =  today.toISOString().slice(0, 10);
      }
      if(val === 'fyear'){
        var yearStart = new Date(today.getTime());
        if(yearStart.getMonth() < 3){
          yearStart.setMonth(yearStart.getMonth() - 12)
        }
        yearStart.setMonth(3)
        yearStart.setDate(1)
        if(previous){
          today = new Date(yearStart.getTime());
          today.setDate(today.getDate() - 1);
          yearStart.setMonth(yearStart.getMonth() - 12);
        }
        this.endDateSelected =  today.toISOString().slice(0, 10);
        this.startDateSelected =  yearStart.toISOString().slice(0, 10);
      }
      if(val === 'yesterday'){
        today.setDate(today.getDate() - 1)
        this.endDateSelected =  today.toISOString().slice(0, 10);
        this.startDateSelected =  today.toISOString().slice(0, 10);
      }
    }
  }
}
</script>
