<template>
  <div>
    <Desktop
      v-if="!isMobile"
      :name="name"
      :filters="filters"
    />
    <Mobile
      v-if="isMobile"
      :name="name"
      :filters="filters"
    />
  </div>
</template>
<script>
import Desktop from './desktop.vue'
import Mobile from './mobile.vue'
import layoutSwitcher from '@/components/layoutSwitcher'
export default {
  components: {Desktop, Mobile},
  mixins: [layoutSwitcher],
  props: {
    filters: { type: Object, default: () => {} },
    name: { type: String, default: 'rewardsqr' }
  }
}
</script>
