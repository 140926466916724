<template>
  <b-modal
    id="attachmentmodal"
    v-model="visible"
    title="Attachments"
    size="md"
    no-fade
    static
    centered
    scrollable
    @hide="onHide"
  >
    <template #modal-footer>
      <b-container>
        <b-row v-if="!noUploadDelete">
          <b-col>
            <strong>Upload files</strong>
          </b-col>
        </b-row>
        <b-row v-if="!noUploadDelete">
          <b-col
            v-show="maxFileCount !== null"
            class="text-muted"
          >
            You can upload a maximum of {{ maxFileCount }} files, 
            {{ maxFileSize }}MB each
          </b-col>
          <b-col
            v-show="maxFileCount == null"
            class="text-muted"
          >
            You can upload files with maximum size of {{ maxFileSize }}MB each
          </b-col>
        </b-row>
        <b-row
          v-if="!noUploadDelete"
          class="mt-2"
        >
          <b-col>
            <b-form-group>
              <b-form-file
                ref="fileinput"
                v-model="newFiles"
                :state="fileValid"
                :disabled="newUploadLimit === 0 && maxFileCount === 5"
                class="clickable"
                multiple
                placeholder="Choose files to upload"
                drop-placeholder="Drop files here"
              />
              <b-form-invalid-feedback
                :state="fileValid"
                class="custom-invalid-feedback"
              >
                Please select a file
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="!noUploadDelete"
          class="mt-2"
        >
          <b-col class="text-right">
            <b-button @click="clearFiles">
              Clear
            </b-button>
            <b-button
              variant="primary"
              class="ml-2"
              :disabled="selectedFiles.length === 0"
              @click="uploadFiles"
            >
              Upload
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-container>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton
            v-for="skeleton in skeletonList"
            :key="skeleton.index"
            :width="skeleton"
          />
        </template>
        <table
          v-show="!loading"
          class="table table-sm table-striped"
        >
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>
                <b-container>
                  <b-row class="border-bottom">
                    <b-col>
                      <fileViewer :href="item.href">
                        {{ item.name }}
                      </fileViewer>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <span class="mr-3 text-muted">
                        File size: {{ item.size }}
                      </span>
                    </b-col>
                    <b-col>
                      <a :href="item.href">
                        <b-button
                          size="sm"
                          data-toggle="tooltip"
                          title="Download"
                        >
                          <b-icon-download font-scale="0.9" />
                        </b-button>
                      </a>
                      <b-button
                        v-if="!noUploadDelete"
                        variant="danger"
                        size="sm"
                        class="ml-2"
                        data-toggle="tooltip"
                        title="Delete"
                        @click="deleteItem(item)"
                      >
                        <b-icon-trash font-scale="0.9" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </td>
            </tr>
          </tbody>
        </table>
        <span v-show="items.length == 0">No attachments available.</span>
      </b-skeleton-wrapper>
      <b-row v-show="selectedFiles.length > 0">
        <b-col class="table-responsive">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="2">
                  Files selected for upload
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="file in selectedFiles"
                :key="file.name"
              >
                <td :class="file.variant">
                  {{ file.message }}
                </td>
                <td>{{ file.name }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import axios from '@/libs/axiosSetup'
import layoutSwitcher from '@/components/layoutSwitcher'
import isRequired from '@/libs/codeSafety'
import fileViewer from '@/components/fileViewer'
export default {
  components: { fileViewer },
  mixins: [layoutSwitcher],
  data() {
    return {
      loading: false,
      visible: false,
      newFiles: [],
      items: [],
      pk: null,
      slug: null,
      params: null,
      inputFiles: [],
      countUpdate: null,
      maxFileCount: 5,
      onHideFn: null,
      noUploadDelete:false
    }
  },
  computed: {
    maxFileSize: function () {
      if (
        this.params !== null &&
        typeof this.params !== "undefined" &&
        typeof this.params["max_file_size"] !== "undefined" &&
        this.params["max_file_size"] !== null
      ) {
        return this.params["max_file_size"];
      } else {
        return "10";
      }
    },
    urlPrefix: function () {
      if (this.slug.endsWith('_') === true) {
        return this.slug
      }
      return this.slug + '/'
    },
    skeletonList: function () {
      let sections = []
      const limit = this.items.length || this.expectedCount
      for (let i = 0; i < limit; ++i) {
        sections.push('100%')
        sections.push('50%')
        sections.push('80%')
      }
      return sections
    },
    newUploadLimit: function () {
      return this.maxFileCount - this.items.length
    },
    selectedFiles: function () {
      const el = this
      let selected = []
      let okCount = 0
      let filesize = 10
      if (el.params !== undefined && el.params!== null) {
        if (el.params["max_file_size"] === 50) {
          filesize = 50
        }
      }
      for (let file of el.newFiles) {
        file.message = 'Will not be uploaded because size > 10M'
        file.variant = 'text-danger'
        if (this.maxFileCount !== null && okCount >= this.newUploadLimit) {
          file.message = `Will not be uploaded because max
          ${this.maxFileCount} attachments are allowed.`
          file.variant = 'text-danger'
        } else {
          if (file.size <= filesize * 1024 * 1024) {
            file.okForUpload = true
            file.message = 'Ok for upload'
            file.variant = 'text-success'
            okCount += 1
          }
        }
        selected.push(file)
      }
      return selected
    },
    fileValid: function () {
      if (
        this.newUploadLimit !== null
        && this.newUploadLimit === 0
      ) { return null }
      if (this.loading === true) { return null }
      if (this.newFiles.length === 0) { return false }
      if (this.selectedFiles.length >= 1) { return null }
      return null
    }
  },
  watch: {
    'items': function () {
      if (this.countUpdate !== null) {
        this.countUpdate(this.items.length)
      }
    }
  },
  created() {
    const el = this
    el.$root.$on('awsfile-modal-on',
      function (
        pk = isRequired(),
        slug = isRequired(),
        expectedCount = isRequired(),
        countUpdateFn = isRequired(),
        params,
        uploadLimit,
        onHideFn,
        AfterUploadFn,
        noUploadDelete
      ) {
        el.items = []
        el.pk = pk
        el.slug = slug
        el.expectedCount = expectedCount || 1
        el.countUpdate = countUpdateFn
        el.params = params
        el.getFileDetails()
        el.visible = true
        el.maxFileCount = uploadLimit === undefined ? 5 : uploadLimit
        el.onHideFn = onHideFn
        el.AfterUploadFn = AfterUploadFn
        el.noUploadDelete = noUploadDelete
      })
  },
  methods: {
    onHide: function () {
      if (this.onHideFn !== null && this.onHideFn !== undefined) {
        this.onHideFn()
      }
    },
    deleteItem: function (item, confirmed = false) {
      const el = this
      if (confirmed === false) {
        el.$root.$emit('app-action-confirm',
          function () { el.deleteItem(item, true) },
          'Confirm delete',
          'Deleting is not a reversible action. Do you want to continue?',
          'danger'
        )
        return
      }
      el.expectedCount = el.expectedCount - 1
      el.expectedCount = el.expectedCount < 1 ? 1 : el.expectedCount
      el.loading = true
      el.$root.$emit('nav-spinner-on')
      axios.post(
        '/main/s/' + el.urlPrefix + 'deleteattachment',
        {
          key: item.id,
          'awsfile_master_pk': el.pk,
          ...(el.params || {})
        }).then(function (response) {
        el.items = response.data.get_attachments
        el.expectedCount = el.items.length
      }).catch(() => { }).finally(function () {
        el.$root.$emit('nav-spinner-off')
        el.loading = false
      })
    },
    getFileDetails: function () {
      const el = this
      el.$root.$emit('nav-spinner-on')
      el.loading = true
      axios.get(
        '/main/s/' + el.urlPrefix + 'attachments',
        { params: { 'awsfile_master_pk': el.pk, ...(el.params || {}) } }
      ).then(function (response) {
        el.items = response.data.get_attachments
      }).catch(() => { }).finally(function () {
        el.$root.$emit('nav-spinner-off')
        el.loading = false
      })
    },
    uploadFiles: function () {
      const el = this
      let formData = new FormData()
      if (el.selectedFiles.length === 0) {
        let msg = 'Please select any file to upload.'
        el.$root.$emit('alert', { msg: msg, kind: 'warning' });
        el.$root.$emit('nav-spinner-off');
        return
      }
      for (var i = 0; i < el.selectedFiles.length; i++) {
        if (el.selectedFiles[i].okForUpload !== true) { continue }
        formData.append('attached[' + i + ']', el.selectedFiles[i])
      }
      el.newFiles = []
      el.expectedCount = el.expectedCount + 1
      el.loading = true
      el.$root.$emit('nav-spinner-on')
      axios.post(
        '/main/s/' + el.urlPrefix + 'attachments',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { 'awsfile_master_pk': el.pk, ...(el.params || {}) }
        }
      ).then(function (response) {
        const awsIds = []
        for (let item of response.data.post_attachments) {
          el.items.push(item)
          awsIds.push(item.id)
        }
        el.AfterUploadFn(awsIds)
        el.expectedCount = el.items.length
      }).catch(() => { }).finally(function () {
        el.$root.$emit('nav-spinner-off')
        el.loading = false
        el.visible = false
        el.$root.$emit('alert', {
          msg: "File uploaded successfully 👍️",
          kind: 'success'
        });

      })
    },
    clearFiles: function () {
      this.newFiles = []
    }
  }
}
</script>
<style>
.custom-invalid-feedback {
  color: #6c757d;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #6c757d;
}
</style>