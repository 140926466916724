<template>
  <b-modal
    id="detailmodal"
    v-model="showModalData"
    content-class="h-100"
    static
    centered
    scrollable
    no-close-on-backdrop
  >
    <template #modal-title>
      <slot name="title">
        <b-spinner
          v-if="spin"
          class="align-baseline"
          border
          small
          label="Spinning"
        /> {{ title }}
      </slot>
    </template>
    <template #modal-footer="{ ok, cancel}">
      <slot name="footer">
        <b-button
          v-if="formButtonKind !== 'add'"
          v-show="deleteEnabled === true"
          size="sm"
          variant="danger"
          :disabled="formButtonDisabled"
          @click="click('delete')"
        >
          Delete
        </b-button>
        <slot name="btnGroup" />
        <b-button
          v-if="cancelEnabled"
          size="sm"
          class="ml-auto"
          @click="click('cancel', cancel)"
        >
          ⬅️ Back
        </b-button>
        <b-button
          class="ml-2 text-capitalize"
          size="sm"
          variant="primary"
          :disabled="formButtonDisabled"
          @click.stop="click('formButton')"
        >
          <span class="d-none">{{ ok }}</span>
          ✏️ {{ formButtonKind }}
        </b-button>
      </slot>
    </template>
    <slot />
  </b-modal>
</template>
<script>
export default {
  props: {
    showModal: { type: Boolean, default: false },
    deleteEnabled: {type: Boolean, default: true},
    cancelEnabled: {type: Boolean, default: true},
    title: { type: String, default: '' },
    formButtonKind: { type: String, default: '' },
    formButtonDisabled: { type: Boolean, default: false }
  },
  data(){
    return {showModalData: this.showModal, spin: false}
  },
  watch: {
    'showModal': function (val){ this.showModalData = val },
    'showModalData': function(val){
      if(val === false){ this.$emit('click-cancel') }
    }
  },
  created() {
    const el = this
    el.$root.$on('nav-spinner-on', function (){ el.spin = true });
    el.$root.$on('nav-spinner-off', function (){ el.spin = false });
  },
  methods: {
    click: function (kind, fn=null){
      this.$emit('click-'+kind)
      if(fn !== null){ fn() }
    }
  }
}
</script>
